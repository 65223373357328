import * as API from "../../api/serviceReminders";
import * as ActionTypes from "../action-types/serviceReminders";

import { message } from 'antd';
// import { goBackToPrev } from "../../../navigation/navigationService";
// import {
//     setLoadingSubmit, 
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'

// Exported to user actions
export const get_service_reminders = (vids) => (dispatch) => {
    dispatch(set_service_reminder_loading_status(LOADING_STATUS.LOADING))
    API.getServiceReminders(vids)
    .then((data) => {
        // console.log("get_service_reminders data:", data);

        if (data.status !== 200) {
            message.error('Failed To Get ServiceReminders: ' + data.message);
            dispatch(set_service_reminder_loading_status(LOADING_STATUS.FAILED))
        }
        else {
            dispatch(get_service_reminders_success(data.serviceReminders));
            dispatch(set_service_reminder_loading_status(LOADING_STATUS.SUCCESS))
        }
    })
}

const get_service_reminders_success = (serviceReminders) => {
    return {
        type: ActionTypes.GET_SERVICE_REMINDERS,
        serviceReminders: serviceReminders,
    };
}

export const edit_service_reminder = (editedServiceReminder) => (dispatch) => {
    dispatch(set_service_reminder_loading_status(LOADING_STATUS.LOADING))

    API.editServiceReminder(editedServiceReminder)
    .then((data) => {
        // console.log("edit_service_reminder data:", data);

        if (data.status !== 200) {
            message.error('Failed To Edit Service Reminders: ' + data.message);
            dispatch(set_service_reminder_loading_status(LOADING_STATUS.FAILED))
        }
        else {
            message.info('Successfully Edited Service Reminder.');

            dispatch(edit_service_reminders_success(editedServiceReminder));
            dispatch(set_service_reminder_loading_status(LOADING_STATUS.SUCCESS))
        }
    })
}

const edit_service_reminders_success = (serviceReminder) => {
    return {
        type: ActionTypes.EDIT_SERVICE_REMINDER,
        serviceReminder: serviceReminder,
    };
}
export const set_service_reminder_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_SERVICE_REMINDER_LOADING_STATUS,
        loadingStatus
    }
}

