import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getServiceReminders = (vids) => (
    fetch(`${api}/servicereminder/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vids
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const editServiceReminder = (serviceReminder) => (
    fetch(`${api}/servicereminder/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            serviceReminder
        })
    })
    .then(res => res.json())
    .then(data => data)
)