import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useDispatch,
    useSelector
} from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import parseTime from '../../utils/parseTime';
import parseDate from '../../utils/parseDate';
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";
import { tableColumnSearchProps } from '../../components/TableColumnSearchProps';

import {
    STATUS_TYPES,
    LOADING_STATUS,
    SERVICE_REMINDER_STATUSES,
} from "../../constants";
import {
    Form,
    Table,
    Button,
    Skeleton,
    DatePicker,
} from "antd";

// Redux Actions
import { get_service_reminder_log } from '../../services/redux/actions/serviceReminderLogs';

const ServiceReminderRecord = (props) => {

    const excelname = `Service Reminder Records`;

    const dispatch = useDispatch();

    const staff = useSelector(state => state.staff)
    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)
    const serviceReminders = useSelector(state => state.serviceReminders)
    const serviceReminderLogs = useSelector(state => state.serviceReminderLogs)

    const [dataSource, setDataSource] = useState([])
    const [column, setColumn] = useState([])

    const  [startTime, setStartTime] = useState(moment().startOf("month"))
    const  [endTime, setEndTime] = useState(moment().endOf("month"))

    const [searchText, setSearchText] = useState('');
    const [ searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const setupDatasource = () => {
        const dataSources = Object.values(vehicles.byVID)
            .map(vehicle => {
                const customer = vehicle.cid && customers.byCID[vehicle.cid];
                const serviceAdvisor = vehicle.serviceAdvisor && staff.byUID[vehicle.serviceAdvisor]

                const {
                    serviceReminderDataSource,
                    serviceReminderColumns,
                } = formatServiceReminders(vehicle.vid)

                const latestServiceReminder = serviceReminderDataSource[0]

                const reminderType = latestServiceReminder && latestServiceReminder.reminderType
                const reminderStatus = latestServiceReminder && latestServiceReminder.reminderStatus
                const reason = latestServiceReminder && latestServiceReminder.reason
                const serviceType = latestServiceReminder && latestServiceReminder.serviceType

                return {
                    key: vehicle.vid,
                    vehiclePlate: (vehicle && vehicle.vehiclePlate) || '-',
                    vehicleModel: (vehicle && vehicle.vehicleModel) || '-',
                    serviceAdvisor: (serviceAdvisor && serviceAdvisor.userName) || '-',
                    customerName: (customer && customer.name) || '-',
                    contactNumber: (customer && customer.contactNumber) || '-',
                    reminderType: reminderType,
                    reminderStatus: reminderStatus,
                    reason: reason,
                    serviceType: serviceType,

                    /**For expanded table */
                    serviceReminderDataSource,
                    serviceReminderColumns
                }
            })
            .sort((a, b) => a.vehiclePlate.localeCompare(b.vehiclePlate))

        const columns = [

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset)
            },

            {
                title: "Vehicle Modal",
                dataIndex: "vehicleModel",
            },

            {
                title: "Service Advisor",
                dataIndex: "serviceAdvisor",
                filters: Object.values(staff.byUID).map(staff => staff.userName).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.serviceAdvisor.indexOf(value) === 0,
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                filters: Object.values(customers.byCID).map(customers => customers.name).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.customerName.indexOf(value) === 0,
            },
            {
                title: "Reminder Type",
                dataIndex: "reminderType",
            },
            {
                title: "Status",
                dataIndex: "reminderStatus",
                render: reminderStatus => {
                    let color = 'black'
                    switch (reminderStatus) {
                        case SERVICE_REMINDER_STATUSES.COMPLETED:
                            color = 'green'
                            break
                        case SERVICE_REMINDER_STATUSES.INCOMPLETE:
                            color = 'red'
                            break
                        default:
                            break
                    }
                    return <p style={{ color }}>{reminderStatus}</p>
                },
                filters: Object.values(SERVICE_REMINDER_STATUSES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.reminderStatus && record.reminderStatus.indexOf(value) === 0,
            },
            {
                title: "Reason",
                dataIndex: "reason",
            },

            {
                title: "Service Type",
                dataIndex: "serviceType",
            }
        ]

        setDataSource(dataSources)
        setColumn(columns)
    }

    const getExportDatasource = () => {
        const dataSources = Object.values(serviceReminderLogs.bySRID)
            .reduce((a, b) => [...a, ...b,], [])
            .sort((a, b) => a.created - b.createdAt)
            .map((serviceReminderLog, i) => {


                const vehicle = serviceReminderLog && vehicles.byVID[serviceReminderLog.vid];
                const customer = vehicle && vehicle.cid && props.customers.byCID[vehicle.cid];
                const serviceAdvisor = vehicle && vehicle.serviceAdvisor && staff.byUID[vehicle.serviceAdvisor]

                return {
                    key: i,
                    vehiclePlate: (vehicle && vehicle.vehiclePlate )|| '-',
                    vehicleModel: (vehicle && vehicle.vehicleModel )|| '-',
                    serviceAdvisor: (serviceAdvisor && serviceAdvisor.userName) || '-',
                    customerName: (customer && customer.name) || '-',
                    contactNumber: (customer && customer.contactNumber) || '-',
                    reminderType: serviceReminderLog.reminderType,
                    reminderStatus: serviceReminderLog.reminderStatus,
                    serviceType: serviceReminderLog.serviceType,
                    triggerType: serviceReminderLog.triggerType,
                    reason: serviceReminderLog.reason,
                    createdAt: serviceReminderLog.createdAt,
                }
            })
            .sort((a, b) => a.vehiclePlate.localeCompare(b.vehiclePlate))

        return dataSources

    }

    const formatServiceReminders = (vid) => {
        if (!vid) return {}

        const serviceReminderDataSource = Object.values(serviceReminderLogs.bySRID)
            .map((logs, i) => {
                logs = logs
                    .filter(log => log.vid === vid)
                    .sort((a, b) => a.created - b.createdAt)

                if (logs.length === 0) return 0

                const firstLog = logs[0]
                const lastLog = logs[logs.length - 1]

                const vehicle = firstLog && vehicles.byVID[firstLog.vid];
                const customer = vehicle && vehicle.cid && customers.byCID[vehicle.cid];
                const serviceAdvisor = vehicle && vehicle.serviceAdvisor && staff.byUID[vehicle.serviceAdvisor]

                const {
                    logsDataSource,
                    logsColumns,
                } = formatServiceReminderLogs(firstLog.srid)


                /**Metadata */
                const completedAt = (lastLog && lastLog.reminderStatus === STATUS_TYPES.COMPLETED && lastLog.createdAt) || undefined
                const logsDuration = firstLog && lastLog && lastLog.createdAt - firstLog.createdAt

                return firstLog && lastLog && {
                    key: firstLog.srid,
                    serviceAdvisor: (serviceAdvisor && serviceAdvisor.userName) || '-',
                    customerName: (customer && customer.name) || '-',
                    contactNumber: (customer && customer.contactNumber) || '-',
                    reminderStatus: lastLog.reminderStatus,
                    vehiclePlate: (vehicle && vehicle.vehiclePlate) || '-',
                    vehicleModel: (vehicle && vehicle.vehicleModel) || '-',
                    createdAt: firstLog.createdAt,
                    reminderType: firstLog.reminderType,
                    serviceType: firstLog.serviceType,
                    reason: lastLog.reason,

                    updatedAt: lastLog.createdAt,
                    completedAt: completedAt,
                    leadDay: completedAt && msToDays(logsDuration),

                    /**For expanded table */
                    logsDataSource,
                    logsColumns,
                }

                function msToDays(duration) {
                    const MS_IN_DAYS = 24 * 60 * 60 * 1000;

                    if (isNaN(duration)) return `-`

                    const days = Math.ceil(duration / MS_IN_DAYS);

                    return days
                }
            })
            .filter(s => s)
            .sort((a, b) => b.updatedAt - a.updatedAt)


        const serviceReminderColumns = [
            {
                title: "Reminder Type",
                dataIndex: "reminderType",
            },
            {
                title: "Status",
                dataIndex: "reminderStatus",
                render: reminderStatus => {
                    let color = 'black'
                    switch (reminderStatus) {
                        case SERVICE_REMINDER_STATUSES.COMPLETED:
                            color = 'green'
                            break
                        case SERVICE_REMINDER_STATUSES.INCOMPLETE:
                            color = 'red'
                            break
                        default:
                            break
                    }
                    return <p style={{ color }}>{reminderStatus}</p>
                }
            },
            {
                title: "Reason",
                dataIndex: "reason",
            },

            {
                title: "Service Type",
                dataIndex: "serviceType",
            },

            {
                title: "Lead Day",
                dataIndex: "leadDay",
                sorter: ((a, b) => a.leadDay - b.leadDay)
            },

            {
                title: "Completed At",
                dataIndex: "completedAt",
                render: completedAt => parseDate(completedAt)
            },

            {
                title: 'Export',
                render: (dataSource) => {
                    const {
                        logsColumns,
                        logsDataSource,
                    } = dataSource
                    return logsDataSource && logsColumns && (
                        <div>
                            <ExportExcelButton
                                disabled={logsDataSource && logsDataSource.length === 0}
                                filename={`Service Reminder Record (SRID${dataSource.key}`}
                                sheetName={`Service Reminder Record`}
                                sheetData={logsDataSource}
                                sheetRow={
                                    logsColumns
                                        .filter(col => col.title !== `Actions`)
                                        .map(col => {
                                            switch (col.dataIndex) {
                                                case 'completedAt':
                                                case 'createdAt':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => col.render(value[col.dataIndex])
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                }
                            />
                        </div>
                    )
                }
            }
        ]

        return {
            serviceReminderDataSource,
            serviceReminderColumns,
        }
    }

    const formatServiceReminderLogs = srid => {
        if (!srid) return {}

        const logsDataSource = Object.values(serviceReminderLogs.bySRID[srid])
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((log, i) => {
                log = {
                    key: i,
                    reminderType: log.reminderType,
                    reason: log.reason,
                    afterSalesStatus: log.afterSalesStatus,
                    reminderStatus: log.reminderStatus,
                    createdAt: log.createdAt,

                }
                return log
            })

        const logsColumns = [
            {
                title: "Created Date",
                dataIndex: "createdAt",
                render: createdAt => parseTime(createdAt)
            },
            {
                title: "After Sales Status",
                dataIndex: "afterSalesStatus",
            },
            {
                title: "Reason",
                dataIndex: "reason",
            },
            {
                title: "Status",
                dataIndex: "reminderStatus",
                render: reminderStatus => {
                    let color = 'black'
                    switch (reminderStatus) {
                        case SERVICE_REMINDER_STATUSES.COMPLETED:
                            color = 'green'
                            break
                        case SERVICE_REMINDER_STATUSES.INCOMPLETE:
                            color = 'red'
                            break
                        default:
                            break
                    }
                    return <p style={{ color }}>{reminderStatus}</p>
                }
            },
        ]

        return {
            logsDataSource,
            logsColumns,
        }
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevVehicleProps = usePrevious(vehicles);
    const prevStaffProps = usePrevious(staff);
    const prevCustomerProps = usePrevious(customers);
    const prevServiceReminderProps = usePrevious(serviceReminders);
    const prevServiceReminderLogProps = usePrevious(serviceReminderLogs);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            const vids = Object.keys(vehicles.byVID)

            dispatch(get_service_reminder_log(vids))

            mounted.current = true;

            setupDatasource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevVehicleProps) !== JSON.stringify(vehicles)
                ||
                JSON.stringify(prevStaffProps) !== JSON.stringify(staff)
                ||
                JSON.stringify(prevCustomerProps) !== JSON.stringify(customers)
                ||
                JSON.stringify(prevServiceReminderProps) !== JSON.stringify(serviceReminders)
                ||
                JSON.stringify(prevServiceReminderLogProps) !== JSON.stringify(serviceReminderLogs)
            ) {
                setupDatasource();
            }
        }
    });

    const vehicleExpandedRowRender = (record) => {

        const {
            serviceReminderDataSource,
            serviceReminderColumns,
        } = record;

        return <div style={{ margin: 20 }}>
            <Table
                columns={serviceReminderColumns}
                dataSource={serviceReminderDataSource}
                expandedRowRender={serviceReminderExpandedRowRender}
                bordered
                size="small"
                pagination={false}
                style={{ width: serviceReminderColumns.length * 250 }}
            />
        </div>
    }

    const serviceReminderExpandedRowRender = (record) => {
        // console.table({ record });

        const {
            logsColumns,
            logsDataSource,
        } = record;

        return <div style={{ margin: 20 }}>
            <Table
                columns={logsColumns}
                dataSource={logsDataSource}
                bordered
                size="small"
                pagination={false}
                style={{ width: logsColumns.length * 250 }}
            />
        </div>
    }

    return (
        <Page title="Service Reminder Record">
            <Form layout='inline' style={{ marginBottom: 5 }}>
                <Form.Item>
                    <span>Start time: </span>

                    <DatePicker
                        showTime
                        defaultValue={startTime}
                        onChange={(value) => setStartTime(value)}
                        style={{ width: 200 }}
                    />
                </Form.Item>

                <Form.Item>
                    <span>End time: </span>

                    <DatePicker
                        showTime
                        defaultValue={endTime}
                        onChange={(value) => setEndTime(value)}
                        style={{ width: 200 }}
                    />
                </Form.Item>

                {/* <Form.Item>
                    <span >Reminder Type: </span>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder='Select a reminder type'
                        optionFilterProp="children"
                        onChange={selectedReminderType => {
                            this.setState({ selectedReminderType })
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(REMINDER_TYPES).map(value => {
                                return <Select.Option
                                    key={value}
                                    value={value}
                                >
                                    {value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>


                <Form.Item>
                    <span >Status: </span>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder='Select a status'
                        optionFilterProp="children"
                        onChange={selectedStatus => {
                            this.setState({ selectedStatus })
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(SERVICE_REMINDER_STATUSES).map(value => {
                                return <Select.Option
                                    key={value}
                                    value={value}
                                >
                                    {value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item> */}

                {/* <Form.Item>
                    <span >Vehicle model: </span>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder='Select a vehicle model'
                        optionFilterProp="children"
                        onChange={selectedVehicleModel => {
                            this.setState({ selectedVehicleModel })
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(VEHICLE_MODELS).map(value => {
                                return <Select.Option
                                    key={value}
                                    value={value}
                                >
                                    {value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item> */}

                <Form.Item>
                    <Button
                        onClick={() => {
                            const vids = Object.keys(vehicles.byVID)
                            const inStartTime = startTime && startTime.utc().valueOf()
                            const inEndTime = endTime && endTime.utc().valueOf()
                            // const reminderType = this.state.selectedReminderType
                            // const selectedStatus = this.state.selectedStatus
                            // const vehicleModel = this.state.selectedVehicleModel

                            dispatch(get_service_reminder_log(vids, inStartTime, inEndTime,))
                            // get_service_reminder_log(vids, startTime, endTime)
                        }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: 10,
                }}
            >
                <ExportExcelButton
                    disabled={!dataSource || dataSource.length === 0}
                    filename={excelname}
                    sheetData={getExportDatasource()}
                    sheetName={`Service Reminder Record`}
                    sheetRow={[
                        {
                            label: "Vehicle Plate",
                            dataIndex: "vehiclePlate",
                        },

                        {
                            label: "Vehicle Modal",
                            dataIndex: "vehicleModel",
                        },

                        {
                            label: "Service Advisor",
                            dataIndex: "serviceAdvisor",
                        },
                        {
                            label: "Customer Name",
                            dataIndex: "customerName",
                        },
                        {
                            label: "Contact Number",
                            dataIndex: "contactNumber",
                        },
                        {
                            label: "Reminder Type",
                            dataIndex: "reminderType",
                        },
                        {
                            label: "Status",
                            dataIndex: "reminderStatus",
                        },
                        {
                            label: "Service Type",
                            dataIndex: "serviceType",
                        },
                        {
                            label: "Trigger Type",
                            dataIndex: "triggerType",
                        },
                        {
                            label: "Reason",
                            dataIndex: "reason",
                        },
                        {
                            label: "Created At",
                            dataIndex: "createdAt",
                        },
                    ].map(col => {
                        switch (col.dataIndex) {
                            case 'createdAt':
                                return {
                                    label: col.label,
                                    // formatter: value => parseTime(value[col.dataIndex])
                                    formatter: value => moment(value[col.dataIndex]).format('DD/MM/YYYY')
                                }
                            default:
                                return {
                                    label: col.label,
                                    formatter: value => value[col.dataIndex]
                                }
                        }
                    })
                    }
                />
            </div>

            {
                (
                    serviceReminderLogs.loadingStatus === LOADING_STATUS.LOADING
                    || customers.loadingStatus === LOADING_STATUS.LOADING
                    || vehicles.loadingStatus === LOADING_STATUS.LOADING
                    || staff.loadingStatus === LOADING_STATUS.LOADING
                ) ?
                    <Skeleton active /> :
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        pagination={true}
                        expandedRowRender={vehicleExpandedRowRender}
                        scroll={{
                            x: column && column.length * 200,
                            y: window.innerHeight
                        }}
                    />
            }
        </Page>
    )
}

export default showSecondarySidebar(false)(ServiceReminderRecord);