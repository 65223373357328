import React, { Component } from 'react';
import { connect } from 'react-redux';

import Page from '../../../components/Page';
import PrimaryButton from '../../../components/PrimaryButton';
import showSecondarySidebar from '../../../components/Layout/helpers/showSecondarySidebar';

import { goBackToPrev } from '../../../navigation/navigationService';
import {
    Form,
    Input,
    Button,
    message,
} from 'antd';

// Redux Actions
import { add_device, edit_device } from '../../../services/redux/actions/devices';
import { LOADING_STATUS } from '../../../constants';

const FormItem = Form.Item;

const SIM_SERIAL_EXACT_LENGTH = 18

class DeviceForm extends Component {
    state = {}

    isPassPhoneRegex = phone => {
        const regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
        return regex.test(phone)
    }

    submitForm = () => {
        let {
            dvid,
            imei,
            simCard,
            simSerial,
            isEdit
        } = this.state;

        try {
            if (!imei) throw new Error(`Device Imei Cannot Be Blank`);
            if (isNaN(Number(imei))) throw new Error(`Imei is not a number`);
            if (this.isImeiExist(imei, dvid)) throw new Error(`Imei is already in use`);
            
            if (simCard && !this.isPassPhoneRegex(simCard)) throw new Error(`Invalid mobile number`);
            if (simSerial && isNaN(Number(simSerial))) throw new Error(`Sim Serial Number is not a number`);
            if (simSerial && simSerial.length !== SIM_SERIAL_EXACT_LENGTH) throw new Error(`Sim Serial Number must be exactly 18 characters long`);

            const uid = this.props.user.uid
            imei = imei.replace(/\s+/g, '')

            if (isEdit) {
                const newDevice = {
                    ...this.props.devices.byDVID[dvid],
                    imei,
                    simCard,
                    simSerial,
                }

                // console.log({
                //     newDevice,
                // })

                this.props.dispatch(edit_device(newDevice));
            }
            else {
                const newDevice = {
                    imei,
                    simCard,
                    simSerial,
                };

                // console.log({
                //     uid,
                //     newDevice,
                // })

                this.props.dispatch(add_device(uid, newDevice));
            }

        } catch (err) {
            message.error(err.message || `Error`)
        }
    }

    componentDidMount = () => {
        this.populateForm()
    }

    componentDidUpdate = prevProps => {
        if (JSON.stringify(this.props.devices) !== JSON.stringify(prevProps.devices)) {
            this.populateForm()
        }
    }

    /**
     * Checks if provided ime ialready exist
     */
    isImeiExist = (imei, dvid) => {
        return Object.keys(this.props.devices.byDVID)
            .filter(_dvid => _dvid !== dvid)
            .find(_dvid => _dvid.includes(imei))
    }

    populateForm = () => {
        // console.log(this.props.location)

        const mode = this.props.location.pathname.split('/').pop()

        switch (mode) {
            case 'EditDevice':
                this.setState({ isEdit: true })

                const dvid = `GOS100#${this.props.location.search.replace('?dvid=', '')}`;
                const device = this.props.devices.byDVID[dvid];

                // console.log({ dvid, device });

                device && this.setState({
                    dvid: device.dvid,
                    imei: device.dvid && device.dvid.replace('GOS100#', ''),
                    simCard: device.simCard,
                    simSerial: device.simSerial,
                })

                break
            case 'AddDevice':
            default:
                break
        }
    }

    getAssignedVehicle = (dvid) => {
        const assignedVehicle = Object.values(this.props.vehicles.byVID).find(vehicle => vehicle.dvid === dvid)

        if (!assignedVehicle) return 'No vehicle assigned'
        return assignedVehicle.chassisNumber
    }

    render() {
        const {
            dvid,
            imei,
            isEdit,
            simCard,
            simSerial,
        } = this.state

        return (
            <div className='page-container'>
                <Page title={isEdit ? 'Edit Device' : 'Add New Device'}>
                    <div style={{ padding: '30px 20px 0px 20px' }}>
                        <Form className='login-form'>
                            <FormItem
                                label='Device Imei'
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required={true}
                                help={`Imei cannot be changed once set`}
                            >
                                <Input
                                    disabled={isEdit}
                                    placeholder='IMEI Series No.'
                                    value={imei}
                                    onChange={e =>
                                        this.setState({ imei: e.target.value })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label='Mobile Number'
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder='Enter device sim card number'
                                    value={simCard}
                                    onChange={e =>
                                        this.setState({ simCard: e.target.value })
                                    }
                                />
                            </FormItem>
                            <FormItem
                                label={`Sim Serial Number (${simSerial ? (SIM_SERIAL_EXACT_LENGTH - simSerial.length) : SIM_SERIAL_EXACT_LENGTH})`}
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                validateStatus={(simSerial && simSerial.length !== SIM_SERIAL_EXACT_LENGTH) ? "error" : ""}
                                help={`Must be exactly ${SIM_SERIAL_EXACT_LENGTH} characters`}
                            >
                                <Input
                                    placeholder='Enter device sim card serial number (optional)'
                                    value={simSerial}
                                    onChange={e =>
                                        this.setState({ simSerial: e.target.value })
                                    }
                                />
                            </FormItem>

                            {
                                isEdit && (
                                    <FormItem
                                        label='Assigned Vehicle'
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 15 }}
                                        required={true}
                                    >
                                        {this.getAssignedVehicle(dvid)}
                                    </FormItem>
                                )
                            }

                            <div style={{ position: 'absolute', right: '100px' }}>
                                <Button
                                    className='login-form-button'
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>


                                <PrimaryButton
                                    loading={this.props.devices.loadingStatus === LOADING_STATUS.LOADING}
                                    onClick={this.submitForm}
                                >
                                    {isEdit ? 'Save' : 'Add'}
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    // style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(DeviceForm));