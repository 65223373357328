import React, { useState, useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

import VehicleMarkers from "./components/VehicleMarkers";
import NEWGoogleMapsLoadScript from "./components/NEWGoogleMapsLoadScript";

import { message } from "antd";
import { GoogleMap } from '@react-google-maps/api';
import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";

// Redux Actions
import { set_map_action } from '../../services/redux/actions/mapControl';

import "./gmaps.css";

const  MapDefault = () => {
    const dispatch = useDispatch();

    const geofences = useSelector(state => state.geofences);
    const mapControl = useSelector(state => state.mapControl);

    const [mapRef, setMapRef] = useState(null);

    const getMapCenterAndZoom = () => {
        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    // ComponentDidUpdate()
    useEffect(() => {
        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (geofences.selectedId
            && geofences.byGeoID[geofences.selectedId]
            && geofences.byGeoID[geofences.selectedId].coordinates.length > 0
            && mapControl.currAction === 1) 
        {
            const selectedGeofence = geofences.byGeoID[geofences.selectedId];
            
            // console.log("Selected Transit:", selectedTransit);

            if (selectedGeofence.coordinates[0] && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng) {
                zoom = 20;
    
                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }
    
                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    // Do not set map action to 0 here or panning won't finish
                }
            }
            else {
                message.error("No location found for this transit status");
                
                dispatch(set_map_action(0));
            }
        }
    })

    return (
        <GoogleMap
            id = 'example-map'
            center = {getMapCenterAndZoom().center}
            zoom = {getMapCenterAndZoom().zoom}
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                dispatch(set_map_action(0))
            }}
        >
            <VehicleMarkers mapRef = {mapRef}/>
        </GoogleMap>
    )
}

const MapComponent = (props) => NEWGoogleMapsLoadScript(MapDefault, props);
export default MapComponent;