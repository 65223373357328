import * as ActionTypes from "../action-types";

const defaultState = {
    byServiceCycle: {}
}

export const afterSalesWorkflows = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_AFTER_SALES_WORKFLOW: {
            const afterSalesWorkflows = action.afterSalesWorkflows

            if(afterSalesWorkflows.length) {
                afterSalesWorkflows.map(asw => {
                    if(newState.byServiceCycle.hasOwnProperty(asw.serviceCycle)) 
                        newState.byServiceCycle[asw.serviceCycle].push(asw)
                    else newState.byServiceCycle[asw.serviceCycle] = [asw]
                    return 0;
                })
            }

            return newState
        }

        default: {
            return state;
        }
    }
};
