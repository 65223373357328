
import * as API from "../../api/user";
import * as ActionTypes from "../action-types/user";

import { message } from 'antd';

// import {
//     STATUS_TYPES
// } from '../../../constants'

// Redux Actions
import { get_staff } from './staff';
import { get_customers } from './customers';
import { get_devices } from './devices';
// import { get_device_groups_success } from './deviceGroups';
import { get_vehicles } from './vehicles';
// import { get_vehicle_groups_success } from './vehicleGroups';
import { get_geofences } from './geofences';
// import { get_geofence_templates_success } from './geofenceTemplates';
import { get_service_configuration } from './serviceConfigurations';
import { get_after_sales_workflow } from './afterSalesWorkflows';
import { get_service_vehicle_records } from './serviceVehicleRecords'

import {
    LOADING_STATUS
} from '../../../constants'

export const user_sign_in_request = (uid) => (dispatch) => {
    dispatch(set_user_loading_status(LOADING_STATUS.LOADING))
    API.getUserInfoByUID(uid)
        .then(data => {
            // console.log("user_sign_in_request data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Retrieve User From Database: ' + data.message);
                    dispatch(set_user_loading_status(LOADING_STATUS.FAILED))
                }
            }
            else {
                const {
                    deviceGroup: deviceGroups,
                    vehicleGroup: vehicleGroups,
                    geofenceTemplate: geofenceTemplates,
                    user
                } = data

                dispatch(get_staff());
                dispatch(get_customers());

                dispatch(get_devices(deviceGroups));
                
                dispatch(get_vehicles(vehicleGroups));

                dispatch(get_geofences(geofenceTemplates))

                dispatch(get_service_configuration())
                dispatch(get_after_sales_workflow())
                dispatch(get_service_vehicle_records())

                dispatch(user_sign_in_success(user));
                dispatch(set_user_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const user_sign_in_success = (user) => {
    return {
        type: ActionTypes.USER_SIGN_IN,
        user
    };
}

export const user_sign_out = () => {
    return {
        type: ActionTypes.USER_SIGN_OUT
    };
}

export const set_user_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_USER_LOADING_STATUS,
        loadingStatus
    }
}
