import * as API from "../../api/vehicles";
import * as ActionTypes from "../action-types/vehicles";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
import { get_service_reminders } from './serviceReminders';
import { get_service_reminder_log } from './serviceReminderLogs';
import { get_after_sales_log } from './afterSalesLogs';
import { get_vehicle_as_log } from './vehicleAsLogs';
import {
    // setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";

// Redux Actions
import {
    set_device_assigned_status_to_true_by_dvid,
    set_device_assigned_status_to_false_by_dvid,
} from "../actions/devices";
import { LOADING_STATUS } from '../../../constants';
import { get_vehicle_groups_success } from './vehicleGroups';

export const get_vehicles = (vgids, model, status, startTime, endTime) => (dispatch) => {
    dispatch(set_vehicle_loading_status(LOADING_STATUS.LOADING))
    API.getVehicles(vgids)
        .then(data => {
            // console.log("get_user_vehicles_and_vehicle_groups_by_vgids data:", data);

            if (data.status !== 200) {
                message.error('Failed To Retrieve User Vehicles From Database:' + data.message);
                dispatch(set_vehicle_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                let {
                    vehicles,
                    vehicleGroups
                } = data

                vehicles = filterVehicles(vehicles, model,status, startTime, endTime)

                const vids = vehicles.map(v => v.vid).filter(v => v)

                dispatch(get_vehicle_groups_success(vehicleGroups))

                dispatch(get_service_reminders(vids));
                dispatch(get_after_sales_log(vids));
                dispatch(get_service_reminder_log(vids));
                dispatch(get_vehicle_as_log(vids));

                dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))
                dispatch(get_vehicles_success(vehicles));
            }
        })

    function filterVehicles(vehicles, model, status, startTime, endTime) {
        return vehicles.filter(vehicle=>{
            try {
                if(model && vehicle.vehicleModel !== model) return false
                if(status && vehicle.afterSalesStatus !== status) return false
                if(startTime && vehicle.createdAt < startTime) return false
                if(endTime && vehicle.endTime > endTime) return false

                return true
            }catch(err){
                return false
            }
        })
    }
}

// Exported to user action
export const get_vehicles_success = (vehicles) => {
    return {
        type: ActionTypes.GET_VEHICLES,
        vehicles: vehicles,
    };
}

export const add_vehicle = (uid, cid, serviceAdvisor, deliveryDate, newVehicle, dvid) => (dispatch) => {
    dispatch(set_vehicle_loading_status(LOADING_STATUS.LOADING))

    API.addVehicle(uid, cid, serviceAdvisor, deliveryDate, newVehicle, dvid)
        .then(data => {
            // console.log("addVehicle data:", data);

            if (data.status === 200) {
                message.info('Successfully Added Vehicle.');

                dispatch(add_vehicle_success(data.vehicle));
                dispatch(set_device_assigned_status_to_true_by_dvid(data.vehicle.dvid));
                dispatch(goBackToPrev());
                dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))
            }
            // else if (data.status === 201) {
            //     message.info('Successfully Added Vehicle.');

            //     dispatch(add_vehicle_success(data.vehicle));
            //     dispatch(set_device_assigned_status_to_true_by_dvid(data.vehicle.dvid));
            //     dispatch(goBackToPrev());
            //     dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))
            // }
            else {
                message.error('Failed To Add Vehicle: ' + data.message);
                dispatch(set_vehicle_loading_status(LOADING_STATUS.FAILED))
            }
        })
}

export const add_vehicle_success = (newVehicle) => {
    return {
        type: ActionTypes.ADD_VEHICLE,
        newVehicle: newVehicle,
    };
}

export const edit_vehicle = (uid, cid, serviceAdvisor, deliveryDate, newVehicle) => (dispatch) => {
    dispatch(set_vehicle_loading_status(LOADING_STATUS.LOADING))

    API.editVehicle(uid, cid, serviceAdvisor, deliveryDate, newVehicle)
        .then(data => {
            // console.log("editVehicle data:", data);

            if (data.status !== 200) {
                message.error('Failed To Edit Vehicle: ' + data.message);
                dispatch(set_vehicle_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Edited Vehicle.');

                dispatch(edit_vehicle_success(data.vehicle));
                dispatch(goBackToPrev());
                dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))
            }

            dispatch(unsetLoadingSubmit());
        })
}

const edit_vehicle_success = (editedVehicle) => {
    return {
        type: ActionTypes.EDIT_VEHICLE,
        editedVehicle: editedVehicle,
    };
}

export const assign_device_to_vehicle = (uid, vid, dvid) => (dispatch) => {
    dispatch(set_vehicle_loading_status(LOADING_STATUS.LOADING))

    API.assignDeviceToVehicle(uid, vid, dvid)
        .then(data => {
            // console.log("assignDeviceToVehicle data:", data);

            if (data.status !== 200) {
                message.error('Failed To Assign Device To Vehicle: ' + data.message);
                dispatch(set_vehicle_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                // message.info('Successfully Assigned Device To Vehicle.');

                data.devices.forEach((currDevice) => {
                    const currDVID = currDevice.device.dvid;

                    if (currDevice.status !== 200) {
                        dispatch(update_assign_progress(currDVID, currDevice.message, false));
                    }
                    else {
                        dispatch(set_device_assigned_status_to_true_by_dvid(currDVID));
                        dispatch(update_assign_progress(currDVID, currDevice.message, true));

                        dispatch(assign_device_to_vehicle_success(data.vehicle));
                        dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))
                    }
                });
            }
        })
}

const assign_device_to_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.ASSIGN_DEVICE_TO_VEHICLE,
        vehicle: vehicle,
    };
}

export const set_up_assign_progress = (vid, dvid) => {
    return {
        type: ActionTypes.SET_UP_PAIR_PROGRESS,
        vid: vid,
        dvid: dvid,
    };
}

const update_assign_progress = (vid, dvid, remarks, isSuccess = true) => {
    return {
        type: ActionTypes.UPDATE_PAIR_PROGRESS,
        vid: vid,
        dvid: dvid,
        remarks: remarks,
        isSuccess: isSuccess,
    };
}

export const clear_assign_progress = () => {
    return {
        type: ActionTypes.CLEAR_PAIR_PROGRESS,
    };
}

export const unassign_devices_from_vehicles = (dvids, uid) => (dispatch) => {
    dispatch(set_vehicle_loading_status(LOADING_STATUS.LOADING))

    API.unassignDevices(dvids, uid)
        .then(data => {
            // console.log("unassignDevices data:", data);

            if (data.status !== 200) {
                message.error('Failed To Unassign Devices: ' + data.message);
                dispatch(set_vehicle_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                // message.info('Successfully Unassigned Devices From Vehicles.');

                data.devices.forEach((d) => {
                    const {
                        device,
                        vehicle,
                    } = d

                    const dvid = device.dvid;

                    if (d.status !== 200) {
                        dispatch(update_unassign_progress(dvid, d.message, false));
                    }
                    else {
                        dispatch(edit_vehicle_success(vehicle))
                        dispatch(unassign_device_success(dvid));
                        dispatch(set_device_assigned_status_to_false_by_dvid(dvid));
                        dispatch(update_unassign_progress(dvid, d.message, true));
                    }
                });
            }
            dispatch(set_vehicle_loading_status(LOADING_STATUS.SUCCESS))

            dispatch(unsetLoadingSubmit());
        })
}

const unassign_device_success = (dvid) => {
    return {
        type: ActionTypes.UNASSIGN_DEVICE,
        dvid: dvid,
    };
}

export const set_up_unassign_progress = (dvid) => {
    return {
        type: ActionTypes.SET_UP_UNPAIR_PROGRESS,
        dvid: dvid,
    };
}

const update_unassign_progress = (dvid, remarks, isSuccess = true) => {
    return {
        type: ActionTypes.UPDATE_PAIR_PROGRESS,
        dvid: dvid,
        remarks: remarks,
        isSuccess: isSuccess,
    };
}

export const clear_unassign_progress = () => {
    return {
        type: ActionTypes.CLEAR_UNPAIR_PROGRESS,
    };
}

export const set_selected_vid = (newVID) => {
    return {
        type: ActionTypes.SET_SELECTED_VID,
        newVID: newVID,
    };
}

export const clear_selected_vid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_VID,
    };
}

export const set_vehicle_cid = (selectedVID, newCID) => {
    return {
        type: ActionTypes.SET_VEHICLE_CID,
        selectedVID: selectedVID,
        newCID: newCID,
    };
}

export const clear_vehicle_cid = (selectedVID) => {
    return {
        type: ActionTypes.CLEAR_VEHICLE_CID,
        selectedVID: selectedVID
    };
}

export const update_vehicle_mileages = (selectedVID, totalMileage, nextMileage) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE_MILEAGES,
        selectedVID: selectedVID,
        totalMileage: totalMileage,
        nextMileage: nextMileage,
    };
}

export const set_vehicle_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_VEHICLE_LOADING_STATUS,
        loadingStatus
    }
}
