import * as ActionTypes from "../action-types/devices";

const defaultState = {
    byDVID: {},
    selectedDVID: "",
    loadingStatus: undefined
}

export const devices = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICES: {
            newState.byDVID = {}; // Clear newState byDVID

            action.devices.forEach((currDevice) => newState.byDVID[currDevice.dvid] = currDevice);
            return newState;
        }

        case ActionTypes.ADD_DEVICE: {
            newState.byDVID[action.newDevice.dvid] = action.newDevice;

            return newState;
        }

        case ActionTypes.EDIT_DEVICE: {
            newState.byDVID[action.editedDevice.dvid] = action.editedDevice;

            return newState;
        }

        case ActionTypes.SET_SELECTED_DVID: {
            return {
                ...newState,
                selectedDVID: action.newDVID
            };
        }

        case ActionTypes.CLEAR_SELECTED_DVID: {
            return {
                ...newState,
                selectedDVID: defaultState.selectedDVID
            };
        }

        case ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID: {
            if (newState.byDVID[action.selectedDVID]) {
                newState.byDVID[action.selectedDVID].assignedStatus = 1;
            }

            return newState;
        }

        case ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID: {
            if (newState.byDVID[action.selectedDVID]) {
                newState.byDVID[action.selectedDVID].assignedStatus = 0
            }
            return newState;
        }

        case ActionTypes.SET_DEVICE_LOADING_STATUS:
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return newState;
        }
    }
}