import React from "react";
import PropTypes from "prop-types";

import { Divider } from "antd";

const PageTitle = props => {
    return (
        <div>
            <h1>{props.title}</h1>

            <Divider/>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
};

export default PageTitle;
