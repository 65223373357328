import 
    React,
    {

    }
from "react";
import 
    { 
        useSelector
    } 
from "react-redux";

// import moment from 'moment';
// import parseDays from '../../../utils/parseDays';
// import parseDate from '../../../utils/parseDate';
// import parseTime from "../../../utils/parseTime";
// import ExportExcelButton from "../../../components/ExportExcelButton";

// import { tableColumnSearchProps } from '../../../components/TableColumnSearchProps';
import {
    // Icon,
    Table,
    // Modal,
    Result,
    // Button,
    Skeleton,
} from "antd";

const ServiceCycleTable = (props) => {

    const {
        data
    } = props;

    const vehicles = useSelector(state => state.vehicles)
    const serviceReminders = useSelector(state => state.serviceReminders)

    const inCycleColumn = [
        {
            dataIndex: "key",
            width: 120
        },

        {
            title: "COMPLETED",
            dataIndex: "completeNumber",
        },

        {
            title: "Total Vehicles With Status",
            dataIndex: "totalStateVehicles",
        },

        {
            title: "Total Registered Vehicles",
            dataIndex: "totalVehicles",
        },

        {
            title: "Total Delivered Vehicles",
            dataIndex: "totalDeliveredVehicles",
        },

        {
            title: "Completed Percentage",
            dataIndex: "completedPercentage",
            render: (data) => (data && (data.toFixed(2) + "%")) || `0%`,
        },

        {
            title: "Overall Percentage",
            dataIndex: "overallPercentage",
            render: (data) => (data && (data.toFixed(2) + "%")) || `0%`,
        }
    ]

    const outCycleColumn = [
        {
            dataIndex: "key",
            width: 120
        },

        {
            title: "Total Vehicles With Status",
            dataIndex: "totalStateVehicles",
        },

        {
            title: "Total Registered Vehicles",
            dataIndex: "totalVehicles",
        },

        {
            title: "Total Delivered Vehicles",
            dataIndex: "totalDeliveredVehicles",
        },

        {
            title: "Overall Percentage",
            dataIndex: "overallPercentage",
            render: (data) => (data && (data.toFixed(2) + "%")) || `0%`,
        }
    ]

    return (
        <div className = "page-container">
            {
                !(Object.keys(vehicles.byVID).length && Object.keys(serviceReminders.bySRID).length)?
                    <Skeleton active /> :
                    data.length ?
                        <Result
                            status = "info"
                            title = "No Data Found!"
                        /> :
                        <div>
                            <Table
                                columns = {inCycleColumn}
                                dataSource = {data.inCycle}
                                pagination = {true}
                                // scroll = {{
                                //     x: columns.length * 150,
                                //     y: window.innerHeight,
                                // }}
                            />

                            <Table
                                columns = {outCycleColumn}
                                dataSource = {data.outCycle}
                                pagination = {true}
                                // scroll = {{
                                //     x: columns.length * 150,
                                //     y: window.innerHeight,
                                // }}
                            />
                        </div>
            }
        </div>
    )

}

export default ServiceCycleTable;