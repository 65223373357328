import * as ActionTypes from "../action-types/serviceReminderLogs";

const defaultState = {
    byVID: {},
    bySRID: {},
    loadingStatus: undefined,
}

export const serviceReminderLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_SERVICE_REMINDER_LOG: {

            newState.byVID = {}
            action.serviceReminderLogs.forEach((log) => {
                if (!newState.byVID[log.vid]) {
                    newState.byVID[log.vid] = [];
                }
                if (!logAlreadyExists(newState.byVID[log.vid], (log))) {
                    newState.byVID[log.vid].push(log);
                }

                if (!newState.bySRID[log.srid]) {
                    newState.bySRID[log.srid] = [];
                }

                if (!logAlreadyExists(newState.bySRID[log.srid], (log))) {
                    newState.bySRID[log.srid].push(log);
                }
            })
            return newState;
        }

        case ActionTypes.CLEAR_SERVICE_REMINDER_LOG: {
            // newState.byVID = defaultState.byVID;
            newState.byVID = {}
            return newState;
        }

        case ActionTypes.SET_SERVICE_REMINDER_LOG_LOADING_STATUS:
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return state;
        }
    }
};

/**
 * Temp fix for bad reducer design.
 * Should have used object with unique keys to prevent duplication, instead of this function to check
 * @param {} logs 
 * @param {*} log 
 */
function logAlreadyExists(logs, log) {
    if (!logs || !log) return false
    if (logs.length === 0) return false
    return logs.find(_log => {
        return _log.vid === log.vid && _log.createdAt === log.createdAt
    })
}