import React, { Component } from 'react'
import ReactExport from "react-data-export";

import { PrimaryButton } from './PrimaryButton';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportExcelButton extends Component {
    render() {
        const {
            filename,
            sheetRow,
            sheetData,
            sheetName,
            ...otherProps
        } = this.props

        // console.log("Sheet Data:", sheetData);

        return (
            <ExcelFile
                filename = {filename}
                element = {
                    <PrimaryButton 
                        style = {{ marginLeft: "15px" }}
                        {...otherProps} // You can declare button props on the component and it will be passed here. eg. disabled = {true}
                    >
                        Export
                    </PrimaryButton>
                }
            >
                <ExcelSheet 
                    name = {sheetName}
                    data = {sheetData}
                >
                    {
                        sheetRow.map((col, i) => 
                            <ExcelColumn 
                                key = {i} 
                                label = {col.label} 
                                value = {val => col.formatter ? col.formatter(val) : val}
                            />
                         )
                    }
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
