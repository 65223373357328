import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useSelector
} from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import parseTime from '../../utils/parseTime';
import PrimaryButton from "../../components/PrimaryButton";
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";
import ServiceVehicleRecordModal from './ServiceVehicleRecordModal'

import { tableColumnSearchProps } from '../../components/TableColumnSearchProps';
import {
    VEHICLE_MODELS,
    LOADING_STATUS,
} from "../../constants";
import {
    Form,
    Table,
    Skeleton,
    Tag
} from "antd";

const ServiceReminderDashboard = (props) => {

    const excelname = `Services (${moment().format('YYYY MM DD')})`

    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)
    const serviceVehicleRecords = useSelector(state => state.serviceVehicleRecords)
    const serviceConfigurations = useSelector(state => state.serviceConfigurations)

    const [serivceVehicleModalVisible, setSerivceVehicleModalVisible] = useState(false)

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])
    const [exportDataSource, setExportDataSource] = useState([])
    const [exportColumns, setExportColumns] = useState([])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const formatServiceVehicleRecord = (param_serviceVehicleRecords) => {

        const svr_dataSource = param_serviceVehicleRecords ?
                param_serviceVehicleRecords
                .map(svr => ({
                    key: svr.serviceCycle,
                    serviceCycle: svr.serviceCycle,
                    servicedAt: svr.servicedAt,
                    serviceEstimatedTotalMileage:  svr.estimatedTotalMileage.toFixed(2),
                    serviceActualTotalMileage:  svr.actualTotalMileage.toFixed(2),
                }))
                .sort((a,b) => b.serviceCycle - a.serviceCycle)
            :
                []

        const svr_column = [
            {
                title: "Service Cycle",
                dataIndex: "serviceCycle",
                align: 'center',
                sorter: (a, b) => a.serviceCycle - b.serviceCycle,
                render: serviceCycle => serviceCycle ? <Tag color={serviceConfigurations.byServiceCycle[serviceCycle].tagColor}>{`S${serviceCycle}`}</Tag> : '-'
            },

            {
                title: "Service Date",
                dataIndex: "servicedAt",
                sorter: (a, b) => a.servicedAt - b.servicedAt,
                render: date => date  ? parseTime(date) : '-'
            },

            {
                title: "Estimated Odometer (km)",
                dataIndex: "serviceEstimatedTotalMileage",
                sorter: (a, b) => a.serviceEstimatedTotalMileage - b.serviceEstimatedTotalMileage,
            },

            {
                title: "Serviced Odometer (km)",
                dataIndex: "serviceActualTotalMileage",
                sorter: (a, b) => a.serviceActualTotalMileage - b.serviceActualTotalMileage,
            }
        ]

        return ([
            svr_dataSource,
            svr_column
        ])
    }

    const setupDataSource = () => {
        const dataSources = Object.values(vehicles.byVID)
            .map(vehicle => {
                const customer = vehicle.cid && customers.byCID[vehicle.cid];

                const params_serviceVehicleRecords = 
                    serviceVehicleRecords.byVID.hasOwnProperty(vehicle.vid)
                    ?
                        serviceVehicleRecords.byVID[vehicle.vid]
                            .map(svrid => serviceVehicleRecords.bySVRID[svrid])
                    :
                        undefined

                const latest_serviceVehicleRecord = params_serviceVehicleRecords ? 
                        params_serviceVehicleRecords
                            .reduce((p, v) => ( p.serviceCycle > v.serviceCycle ? p : v ), params_serviceVehicleRecords ? params_serviceVehicleRecords[0] : undefined) 
                    : 
                        undefined

                const [svr_dataSource,svr_column] = formatServiceVehicleRecord(params_serviceVehicleRecords)

                return {
                    key: vehicle.vid,
                    vehiclePlate: (vehicle && vehicle.vehiclePlate) || '-',
                    vehicleModel: (vehicle && vehicle.vehicleModel) || '-',
                    customerName: (customer && customer.name) || '-',
                    latestServiceCycle: latest_serviceVehicleRecord ? latest_serviceVehicleRecord.serviceCycle : '-',
                    latestServicedAt: latest_serviceVehicleRecord ? latest_serviceVehicleRecord.servicedAt : '-',
                    lastServiceEstimatedTotalMileage:  latest_serviceVehicleRecord ? Math.ceil(latest_serviceVehicleRecord.estimatedTotalMileage) : '-',
                    lastServiceActualTotalMileage:  latest_serviceVehicleRecord ?latest_serviceVehicleRecord.actualTotalMileage.toFixed(2) : '-',
                    svr_dataSource,
                    svr_column
                }
            })
            .sort((a,b) => b.latestServicedAt - a.latestServicedAt)

        const column = [
            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                fixed: "left",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel",
                filters: Object.values(VEHICLE_MODELS).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.vehicleModel.indexOf(value) === 0,
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Latest Service Cycle",
                dataIndex: "latestServiceCycle",
                align: 'center',
                sorter: (a, b) => a.latestServiceCycle - b.latestServiceCycle,
                render: serviceCycle => serviceCycle !== '-' ?  <Tag color={serviceConfigurations.byServiceCycle[serviceCycle].tagColor}>{`S${serviceCycle}`}</Tag> : '-'
            },

            {
                title: "Latest Service Date",
                dataIndex: "latestServicedAt",
                sorter: (a, b) => a.latestServicedAt - b.latestServicedAt,
                render: date => date !== '-' ? parseTime(date) : '-'
            },

            {
                title: "Last Estimated Odometer (km)",
                dataIndex: "lastServiceEstimatedTotalMileage",
                sorter: (a, b) => a.lastServiceEstimatedTotalMileage - b.lastServiceEstimatedTotalMileage,
            },

            {
                title: "Last Actual Serviced Odometer (km)",
                dataIndex: "lastServiceActualTotalMileage",
                sorter: (a, b) => a.lastServiceActualTotalMileage - b.lastServiceActualTotalMileage,
            }
        ]

        const exportDataSources = Object.values(serviceVehicleRecords.bySVRID)
            .map(svr => {

                const vehicle =  vehicles.byVID[svr.vid]
                const customer = vehicle && vehicle.cid && customers.byCID[vehicle.cid];

                return {
                    key: svr.svrid,
                    vehiclePlate: (vehicle && vehicle.vehiclePlate) || '-',
                    vehicleModel: (vehicle && vehicle.vehicleModel) || '-',
                    customerName: (customer && customer.name) || '-',
                    serviceCycle: `S${svr.serviceCycle}`,
                    servicedAt: svr.servicedAt,
                    serviceEstimatedTotalMileage:  svr.estimatedTotalMileage.toFixed(2),
                    serviceActualTotalMileage:  svr.actualTotalMileage.toFixed(2),
                }
            })
            .sort((a,b) => a.servicedAt - b.servicedAt)

        const exportColumn = [
            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate"
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel"
            },

            {
                title: "Customer Name",
                dataIndex: "customerName"
            },

            {
                title: "Service Cycle",
                dataIndex: "serviceCycle",
            },

            {
                title: "Service Date",
                dataIndex: "servicedAt",
            },

            {
                title: "Latest Estimated Odometer (km)",
                dataIndex: "serviceEstimatedTotalMileage"
            },

            {
                title: "Latest Serviced Odometer (km)",
                dataIndex: "serviceActualTotalMileage"
            }
        ]

        setDataSource(dataSources)
        setColumns(column)
        setExportDataSource(exportDataSources)
        setExportColumns(exportColumn)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevVehicleProps = usePrevious(vehicles);
    const prevServiceVehicleRecordProps = usePrevious(serviceVehicleRecords);
    const prevCustomerProps = usePrevious(customers);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */

            mounted.current = true;
    
            setupDataSource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevVehicleProps) !== JSON.stringify(vehicles)
                ||
                JSON.stringify(prevServiceVehicleRecordProps) !== JSON.stringify(serviceVehicleRecords)
                ||
                JSON.stringify(prevCustomerProps) !== JSON.stringify(customers)
            ) {
                setupDataSource();
            }
        }
    });

    const vehicleExpandedRowRender = (record) => {
        const {
            svr_dataSource,
            svr_column
        } = record;

        return <div style={{ margin: 20 }}>
            <Table
                columns={svr_column}
                dataSource={svr_dataSource}
                bordered
                size="small"
                pagination={false}
                style={{ width: svr_column.length * 250 }}
            />
        </div>
    }

    return (
        <Page title = "Service Management">

            <ServiceVehicleRecordModal
                visible={serivceVehicleModalVisible} 
                setVisible={(value) => setSerivceVehicleModalVisible(value)}
            />

            <Form layout = 'inline' style = {{ marginBottom: 5 }}>
                {/* <Form.Item>
                    <span>Start time: </span>

                    <DatePicker
                        style = {{ width: 200 }}
                        showTime
                        defaultValue = {startTime}
                        onChange = {(value) => this.setState({ startTime: value })}
                    />

                    <span style = {{ marginLeft: '10px' }}>End time: </span>

                    <DatePicker
                        style = {{ width: 200 }}
                        showTime
                        defaultValue = {endTime}
                        onChange = {(value) => this.setState({ endTime: value })}
                    />
                </Form.Item> */}
{/* 
                <Form.Item>
                    <span>Service Type</span>

                    <Select
                        showSearch
                        style = {{ width: 200 }}
                        placeholder = "Select a service type"
                        optionFilterProp = "children"
                        onChange = {selectedServiceType => {
                            this.setState({ selectedServiceType })
                        }}
                        filterOption = {(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(SERVICE_TYPES).map(value => {
                                return <Select.Option
                                    key = {value}
                                    value = {value}
                                >
                                    {value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item>
                    <span>Vehicle Model </span>

                    <Select
                        showSearch
                        style = {{ width: 200 }}
                        placeholder = "Select a vehicle model"
                        optionFilterProp = "children"
                        onChange = {selectedVehicleModel => {
                            this.setState({ selectedVehicleModel })
                        }}
                        filterOption = {(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(VEHICLE_MODELS).map(value => {
                                return <Select.Option
                                    key = {value}
                                    value = {value}
                                >
                                    {value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item> */}

                {/* <Form.Item>
                    <Button
                        loading = {style.isLoadingSubmit}
                        onClick = {() => {
                            const startTime = this.state.startTime && this.state.startTime.valueOf()
                            const endTime = this.state.endTime && this.state.endTime.valueOf()
                            const serviceType = this.state.selectedServiceType
                            const vehicleModel = this.state.selectedVehicleModel

                            console.table({
                                startTime,
                                endTime,
                                serviceType,
                                vehicleModel,
                            })
                            dispatch(get_service_records(startTime, endTime, serviceType, vehicleModel))
                        }}
                        style = {{ marginLeft: '15px' }}
                    >
                        Submit
                    </Button>
                </Form.Item> */}
            </Form>

            <div
                style = {{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: 10,
                }}
            >
                <PrimaryButton onClick = {() => setSerivceVehicleModalVisible(true)}>
                    + New Service Record
                </PrimaryButton>

                <ExportExcelButton
                    disabled = {!exportDataSource}
                    filename = {excelname}
                    sheetData = {exportDataSource}
                    sheetName = {`Services`}
                    sheetRow = {
                        exportColumns
                            .map(col => {
                                switch (col.dataIndex) {
                                    case `servicedAt`:
                                        return {
                                            label: col.title,
                                            formatter: value => value[col.dataIndex] ? parseTime(value[col.dataIndex]) :  '-'
                                        }
                                    default:
                                        return {
                                            label: col.title,
                                            formatter: value => value[col.dataIndex]
                                        }
                                }
                            })
                    }
                />
            </div>

            {
                (
                    (vehicles.loadingStatus === LOADING_STATUS.LOADING)
                    || (customers.loadingStatus === LOADING_STATUS.LOADING)
                ) ?
                    <Skeleton active /> :
                    <Table
                        columns = {columns}
                        dataSource = {dataSource}
                        pagination = {true}
                        expandedRowRender={vehicleExpandedRowRender}
                        scroll = {{
                            x: columns.length * 150,
                            y: window.innerHeight
                        }}
                    />

            }
        </Page>
    )

}

export default showSecondarySidebar(false)(ServiceReminderDashboard);