import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getDevices = (dgidList) => (
    fetch(`${api}/device/group/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dgids" : dgidList,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const addDevice = (uid, newDevice) => (
    fetch(`${api}/device/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "device" : newDevice,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const editDevice = (editedDevice) => (
    fetch(`${api}/device/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "device" : editedDevice,
        })
    })
    .then(res => res.json())
    .then(data => data)
)