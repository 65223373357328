export const EXPAND_SIDEBAR = "EXPAND_SIDEBAR";
export const ORIGINAL_SIDEBAR = "ORIGINAL_SIDEBAR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CHANGE_PAGE_HEADER_COLOR = "CHANGE_PAGE_HEADER_COLOR";
export const SET_LOGO = "SET_LOGO";
export const SET_LOADING_FLEETRECORD ="SET_LOADING_FLEETRECORD";
export const UNSET_LOADING_FLEETRECORD = "UNSET_LOADING_FLEETRECORD";
export const SET_LOADING_EVENT = "SET_LOADING_EVENT";
export const UNSET_LOADING_EVENT = "UNSET_LOADING_EVENT";

export const SET_LOADING_SUBMIT = "SET_LOADING_SUBMIT";
export const UNSET_LOADING_SUBMIT = "UNSET_LOADING_SUBMIT";
export const SET_LOADING_PDF = "SET_LOADING_PDF";
export const UNSET_LOADING_PDF = "UNSET_LOADING_PDF";
export const TOGGLE_UPDATE = "TOGGLE_UPDATE";

export const expandSidebar = () => {
    return {
        type: EXPAND_SIDEBAR,
    };
};

export const originalSidebar = () => {
    return {
        type: ORIGINAL_SIDEBAR,
    };
};

export const toggleSidebar = () => {
    return {
        type: TOGGLE_SIDEBAR,
    };
};

export const closeSidebar = () => {
    return {
        type: CLOSE_SIDEBAR,
    };
};

export const openSidebar = () => {
    return {
        type: OPEN_SIDEBAR,
    };
};

export const changePageHeaderColor = color => {
    return {
        type: CHANGE_PAGE_HEADER_COLOR,
        color,
    };
};

export const setLogo = url => {
    return {
        type: SET_LOGO,
        url,
    };
};

export const setLoadingFleetRecord = () => {
    return {
        type: SET_LOADING_FLEETRECORD
    }
}

export const unsetLoadingFleetRecord = () => {
    return{
        type: UNSET_LOADING_FLEETRECORD
    }
}

export const setLoadingEvent = () => {
    return {
        type: SET_LOADING_EVENT
    }
}

export const unsetLoadingEvent = () => {
    return{
        type: UNSET_LOADING_EVENT
    }
}

export const setLoadingSubmit = () => {
    return {
        type: "SET_LOADING_SUBMIT"
    }
}

export const unsetLoadingSubmit = () => {
    return{
        type: "UNSET_LOADING_SUBMIT"
    }
}

export const toggleUpdate = () => {
    return{
        type: "TOGGLE_UPDATE"
    }
}