import * as ActionTypes from "../action-types/customers";

const defaultState = {
    byCID: {},
    selectedCID: "",
    loadingStatus: undefined,
}

export const customers = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_CUSTOMERS: {
            newState.byCID = JSON.parse(JSON.stringify(defaultState.byCID)); // Clear newState byCID
            
            action.customers.forEach((currCust) => newState.byCID[currCust.cid] = currCust);

            return newState;
        }

        case ActionTypes.ADD_CUSTOMER: {
            newState.byCID[action.newCustomer.cid] = action.newCustomer;

            return newState;
        }

        case ActionTypes.EDIT_CUSTOMER: {
            newState.byCID[action.editedCustomer.cid] = action.editedCustomer;

            return newState;
        }

        case ActionTypes.SET_SELECTED_CID: {
            newState.selectedCID = action.selectedCID;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_CID: {
            newState.selectedCID = JSON.parse(JSON.stringify(defaultState.selectedCID));

            return newState;
        }

        case ActionTypes.SET_CUSTOMER_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}