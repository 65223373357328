import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getServiceVehicleRecords = () => (
    fetch(`${api}/service/vehicle/record/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({

        })
    })
        .then(res => res.json())
        .then(data => data)
)

export const addServiceVehicleRecord = (item) => (
    fetch(`${api}/service/vehicle/record/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            ...item
        })
    })
        .then(res => res.json())
        .then(data => data)
)