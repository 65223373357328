import 
    React,
    {
        useRef,
        useEffect,
        useState
    }
from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";

import moment from 'moment';
import parseDays from '../../../utils/parseDays';
import parseDate from '../../../utils/parseDate';
import parseTime from "../../../utils/parseTime";
import ExportExcelButton from "../../../components/ExportExcelButton";

import { tableColumnSearchProps } from '../../../components/TableColumnSearchProps';
import {
    Modal,
    Table,
    Select,
    Result,
    Skeleton,
    Descriptions,
} from "antd";
import {
    SERVICE_TYPES,
    TRIGGER_TYPES,
    LOADING_STATUS,
    VEHICLE_MODELS,
    SERVICE_REMINDER_REASONS,
    SERVICE_REMINDER_STATUSES,
} from '../../../constants';

// Redux Actions
import { edit_service_reminder } from '../../../services/redux/actions/serviceReminders';

const PhoneTable = (props) => {

    const dispatch = useDispatch();

    const user = useSelector(state =>state.user)
    const staff = useSelector(state => state.staff)
    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)
    const serviceReminders = useSelector(state => state.serviceReminders)
    const serviceReminderLogs = useSelector(state => state.serviceReminderLogs)

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const setupDataSource = () => {

        const dataSources = props.phoneDatasource
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((serviceReminder) => {
                const vehicle = vehicles.byVID[serviceReminder.vid];
                const customer = vehicle && customers.byCID[vehicle.cid];
                const serviceAdvisor = vehicle && staff.byUID[vehicle.serviceAdvisor];

                // console.log("Current Vehicle:", vehicle);
                // console.log("Current Customer:", customer);
                // console.log("Current Service Advisor:", serviceAdvisor);

                return {
                    key: serviceReminder.srid,
                    vid: serviceReminder.vid,
                    serviceAdvisor: (serviceAdvisor && serviceAdvisor.userName )|| "-",
                    customerName: (customer && customer.name) || "-",
                    contactNumber: (customer && customer.contactNumber) || "-",
                    vehiclePlate: vehicle.vehiclePlate || "-",
                    vehicleModel: vehicle.vehicleModel || "-",
                    lastProcessTotalMileage: !isNaN(vehicle.aSProfile.totalMileage) ? Math.ceil(vehicle.aSProfile.totalMileage) : "-",
                    serviceType: serviceReminder.serviceType || "-",
                    triggerType: serviceReminder.triggerType || "-",
                    dueDate: serviceReminder.estimatedAppointment,
                    dueIn: parseDays(serviceReminder.estimatedAppointment - moment.now()),
                    reminderStatus: serviceReminder.reminderStatus || "-",
                    reason: serviceReminder.reason,
                    createdAt: serviceReminder.createdAt,
                }
            })

        const column = [
            {
                title: "Service Advisor",
                dataIndex: "serviceAdvisor",
                width: 100,
                ...tableColumnSearchProps("serviceAdvisor", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Customer Contact",
                dataIndex: "contactNumber",
                ...tableColumnSearchProps("contactNumber", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel",
                filters: Object.values(VEHICLE_MODELS).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.vehicleModel.indexOf(value) === 0,
            },

            {
                title: "Last Process Total Mileage (km)",
                dataIndex: "lastProcessTotalMileage",
                sorter: (a, b) => a.lastProcessTotalMileage - b.lastProcessTotalMileage
            },

            {
                title: "Service Type",
                dataIndex: "serviceType",
                filters: Object.values(SERVICE_TYPES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.serviceType.indexOf(value) === 0,
            },

            {
                title: "Trigger Type",
                dataIndex: "triggerType",
                filters: Object.values(TRIGGER_TYPES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.triggerType.indexOf(value) === 0,
            },

            {
                title: "Due date",
                dataIndex: "dueDate",
                sorter: (a, b) => b.dueDate - a.dueDate,
                render: dueDate => parseDate(dueDate)
            },

            {
                title: "Due in (Days)",
                dataIndex: "dueIn",
                sorter: (a, b) => b.dueIn - a.dueIn,
            },

            {
                title: "Created Date",
                dataIndex: "createdAt",
                sorter: (a, b) => b.createdAt - a.createdAt,
                render: createdAt => parseDate(createdAt)
            },

            {
                title: "Update reason",
                width: 250,
                fixed: 'right',
                render: (selectedRow) => {
                    return (
                        <Select
                            disabled = {selectedRow.reminderStatus === SERVICE_REMINDER_STATUSES.COMPLETED}
                            defaultValue = {selectedRow.reason}
                            style = {{ width: '95%' }}
                            onSelect = {index => {
                                const srid = selectedRow.key
                                const {
                                    reason,
                                    reminderStatus
                                } = SERVICE_REMINDER_REASONS[index]

                                let serviceReminder = serviceReminders.bySRID[srid]
                                serviceReminder = {
                                    ...serviceReminder,
                                    reason,
                                    reminderStatus,
                                }

                                Modal.confirm({
                                    width: 600,
                                    icon: 'warning',
                                    title: 'The following service reminder would be updated',
                                    content: (
                                        <div>
                                            <Descriptions
                                                bordered
                                                column = {1}
                                            >
                                                <Descriptions.Item label='Vehicle'>
                                                    {selectedRow.vehiclePlate}
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Customer'>
                                                    {selectedRow.customerName}
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Service Type'>
                                                    {selectedRow.serviceType}
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Trigger Type'>
                                                    {selectedRow.triggerType}
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Reminder Status'>
                                                    <p style = {{ color: reminderStatus === SERVICE_REMINDER_STATUSES.COMPLETED ? 'green' : 'red' }}>
                                                        {reminderStatus}
                                                    </p>
                                                </Descriptions.Item>
                                                <Descriptions.Item label='Reason'>
                                                    {reason}
                                                </Descriptions.Item>

                                            </Descriptions>
                                        </div>
                                    ),
                                    onOk: () => {
                                        dispatch(edit_service_reminder(serviceReminder));
                                    },
                                    onCancel() { },
                                })
                            }}
                        >
                            {
                                SERVICE_REMINDER_REASONS
                                    .map((obj, i) => {
                                        return <Select.Option value = {i} key = {i}>{obj.reason}</Select.Option>
                                    })
                            }
                        </Select>
                    )
                }
            },
        ]

        setDataSource(dataSources)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevPhoneDatasourceProps = usePrevious(props.phoneDatasource);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            if(props.hasOwnProperty('phoneDatasource')) {
                setupDataSource();
            }

            mounted.current = true;
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevPhoneDatasourceProps) !== JSON.stringify(props.phoneDatasource)
            ) {
                setupDataSource();
            }
        }
    });

    const isLoading = (user.loadingStatus === LOADING_STATUS.LOADING)
    || (serviceReminders.loadingStatus === LOADING_STATUS.LOADING)
    || (vehicles.loadingStatus === LOADING_STATUS.LOADING)
    || (staff.loadingStatus === LOADING_STATUS.LOADING)
    || (customers.loadingStatus === LOADING_STATUS.LOADING)
    || (serviceReminderLogs.loadingStatus === LOADING_STATUS.LOADING)

    const isAllDone = dataSource.length === 0

    return (
        <div className="page-container">
            <div style = {{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
                <ExportExcelButton
                    loading = {isLoading}
                    filename = {`Service Reminders (Phone Calls) ${moment(moment().toISOString(), 'DD-MM-YYYY')}`}
                    sheetData = {dataSource}
                    sheetName = {`Service Reminders ${moment(moment().toISOString(), 'DD-MM-YYYY')}`}
                    sheetRow = {
                        columns
                            .map(col => {
                                switch (col.dataIndex) {
                                    case 'createdAt':
                                    case 'updatedAt':
                                        return {
                                            label: col.title,
                                            formatter: value => parseTime(value[col.dataIndex])
                                        }
                                    case 'dueDate':
                                        return {
                                            label: col.title,
                                            formatter: value => col.render(value[col.dataIndex])
                                        }
                                    default:
                                        return {
                                            label: col.title,
                                            formatter: value => value[col.dataIndex]
                                        }
                                }
                            })
                    }
                />
            </div>

            {
                isLoading ?
                    <Skeleton active /> :
                    isAllDone ?
                        <Result
                            status="success"
                            title="All Service Reminders Marked Done!"
                        /> :
                        <Table
                            columns = {columns}
                            dataSource = {dataSource}
                            pagination = {true}
                            scroll = {{
                                x: columns.length * 150,
                                y: window.innerHeight,
                            }}
                        />
            }
        </div>
    )

}

export default PhoneTable;