import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getCustomers = (uid) => (
    fetch(`${api}/customer/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            uid : uid,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const addCustomer = (newCustomer, serviceAdvisor, selectedVID) => (
    fetch(`${api}/customer/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            customer : newCustomer,
            serviceAdvisor : serviceAdvisor,
            vid: selectedVID,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const editCustomer = (editedCustomer, serviceAdvisor, selectedVID) => (
    fetch(`${api}/customer/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            customer : editedCustomer,
            serviceAdvisor : serviceAdvisor,
            vid: selectedVID,
        })
    })
    .then(res => res.json())
    .then(data => data)
)