import * as API from "../../api/staff";
import * as ActionTypes from "../action-types/staff";

import { message } from 'antd';
import {
    LOADING_STATUS
} from '../../../constants'

// Exported to user actions
export const get_staff = (startTime = null, endTime = null) => (dispatch) => {
    dispatch(set_staff_loading_status(LOADING_STATUS.LOADING))
    API.getStaff(startTime, endTime)
    .then((data) => {
        // console.log("get_staff data:", data);

        if (data.status !== 200) {
            message.error('Failed To Get Staff: ' + data.message);
            dispatch(set_staff_loading_status(LOADING_STATUS.FAILED))
        }
        else {
            // message.info('Successfully Retrieved Staff.');

            dispatch(get_staff_success(data.users));
            dispatch(set_staff_loading_status(LOADING_STATUS.SUCCESS))
        }
    })
}

const get_staff_success = (staff) => {
    return {
        type: ActionTypes.GET_STAFF,
        staff: staff,
    };
}
export const set_staff_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_STAFF_LOADING_STATUS,
        loadingStatus
    }
}

