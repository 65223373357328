import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useDispatch,
    useSelector
 } from "react-redux";

import moment from "moment";
import Page from "../../components/Page";
import parseTime from '../../utils/parseTime';
import PrimaryButton from '../../components/PrimaryButton';
import ExportExcelButton from "../../components/ExportExcelButton";
import UploadExcelButton from "../../components/UploadExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { moveToPage } from "../../navigation/navigationService";
import { tableColumnSearchProps } from "../../components/TableColumnSearchProps";
import {
    TEMPLATE_URLS,
    LOADING_STATUS,
    DEVICE_STATUSES,
    // PAIRED_STATUSES,
} from '../../constants';
import {
    Form,
    Table,
    Select,
    Button,
    Tooltip,
    Skeleton,
    DatePicker,
} from "antd";

import {
    EditOutlined,
    MonitorOutlined
} from '@ant-design/icons'

// Redux Actions
import {
    get_devices,
    set_selected_dvid,
    add_device_success,
} from "../../services/redux/actions/devices";

// APIs
import * as API from '../../services/api/devices';

// const defaultFilter = "ALL";

const PAIRED_STATUSES = {
    UNPAIRED: { label: `UNPAIRED`, value: 0, color: 'red' },
    PAIRED: { label: `PAIRED`, value: 1, color: 'green' },
}

const DeviceManagement = (props) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user)
    const customers = useSelector(state => state.customers)
    const devices = useSelector(state => state.devices)
    const vehicles = useSelector(state => state.vehicles)
    const deviceGroups = useSelector(state => state.deviceGroups)

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])

    const [startTime, setStartTime] = useState(moment().startOf("day"))
    const [endTime, setEndTime] = useState(moment().add(1, "day").startOf("day"))
    const selectedDeviceConnectStatus = useState('')[0]
    const [selectedDVIDList, setSelectedDVIDList] = useState('');
    const [selectedPairStatus, setSelectedPairStatus] = useState('');

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const excelname =
            startTime && endTime ?
                `Devices (${selectedDeviceConnectStatus}) ${startTime.format('DD-MM-YY')} - ${endTime.format('DD-MM-YY')}` :
                `Devices (${selectedDeviceConnectStatus})`;
    
    const rowSelection = {
        selectedRowKeys: selectedDVIDList,
        onChange: (value) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`);
            setSelectedDVIDList(value)
        },
    };

    const setupDataSource = () => {

        const dataSources =
            Object.values(devices.byDVID)
                .sort((a, b) => b.createdAt - a.createdAt)
                // .filter((currDevice) => {
                //     if (selectedPairStatus === defaultFilter) return true
                //     if (selectedPairStatus === PAIRED_STATUSES[0]) return !vehicles.byDVID[currDevice.dvid] // Unpaired
                //     if (selectedPairStatus === PAIRED_STATUSES[1]) return vehicles.byDVID[currDevice.dvid] // Paired
                //     return true
                // })
                .map((device) => {
                    const vid = vehicles.byDVID[device.dvid];
                    const vehicle = vehicles.byVID[vid]
                    const customer = vehicle && vehicle.cid && customers.byCID[vehicle.cid];

                    return {
                        key: device.dvid, // Used to identify which vehicle the action would work on
                        imei: device.dvid.split('GOS100#')[1] || "-",
                        simCard: device.simCard || "-",
                        deviceStatus: device.connectStatus ? DEVICE_STATUSES.CONNECTED : DEVICE_STATUSES.DISCONNECTED,
                        updatedAt: device.updatedAt,
                        createdAt: device.createdAt,
                        pairStatus: vehicle ? 1 : 0,
                        vehiclePlate: (vehicle && vehicle.vehiclePlate )|| "-",
                        customerName: (customer && customer.name) || "-",
                    };
                })

        const column = [
            {
                title: "Device IMEI",
                dataIndex: "imei",
                fixed: "left",
                ...tableColumnSearchProps("imei", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Mobile Number",
                dataIndex: "simCard",
                ...tableColumnSearchProps("simCard", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Device Status",
                dataIndex: "deviceStatus",
                render: deviceStatus => {
                    let color = 'black'
                    switch (deviceStatus) {
                        case DEVICE_STATUSES.CONNECTED:
                            color = 'green'
                            break
                        case DEVICE_STATUSES.DISCONNECTED:
                            color = 'red'
                            break
                        default:
                            color = 'black'
                            break
                    }
                    return <p style={{ color }}>{deviceStatus}</p>
                },
                filters: Object.values(DEVICE_STATUSES).map(STATUS => ({ text: STATUS, value: STATUS })),
                onFilter: (value, record) => value && record.deviceStatus.indexOf(value) === 0,
            },

            {
                title: "Pair Status",
                dataIndex: "pairStatus",
                render: pairStatus => {
                    const thisPairStatusObject = Object.values(PAIRED_STATUSES).find(obj => obj.value === pairStatus)
                    if (!thisPairStatusObject) return
                    return <p style={{ color: thisPairStatusObject.color }}>{thisPairStatusObject.label}</p>
                },
                // filters: PAIRED_STATUSES.map(STATUS => ({ text: STATUS, value: STATUS })),
                // onFilter: (value, record) => value && record.pairStatus.indexOf(value) === 0,
            },

            {
                title: "Vehicle",
                dataIndex: "vehiclePlate",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Created At",
                dataIndex: "createdAt",
                render: createdAt => parseTime(createdAt),
                sorter: (a, b) => a.createdAt - b.createdAt
            },

            {
                title: "Last Updated Time",
                dataIndex: "updatedAt",
                render: updatedAt => parseTime(updatedAt),
                sorter: (a, b) => a.updatedAt - b.updatedAt,
            },

            {
                title: "Actions",
                fixed: "right",
                render: (rowData) => (
                    <div>
                        <Tooltip title={"Device Log"} style={{ display: "flex", flexDirection: "row" }}>
                            <button className="transparent-button">
                                <MonitorOutlined 
                                    onClick={() => {
                                        dispatch(set_selected_dvid(rowData.key));
                                        // const queryParams = `?dvid=${rowData.key.replace('GOS100#', '')}`
                                        dispatch(moveToPage(`/DeviceManagement/DeviceLogInspector`, rowData.key));
                                    }}
                                />
                            </button>
                        </Tooltip>

                        <Tooltip title={"Edit"} style={{ display: "flex", flexDirection: "row" }}>
                            <button className="transparent-button">
                                <EditOutlined 
                                    onClick={() => {
                                        dispatch(set_selected_dvid(rowData.key));
                                        const queryParams = `?dvid=${rowData.key.replace('GOS100#', '')}`
                                        dispatch(moveToPage(`/DeviceManagement/EditDevice${queryParams}`));
                                    }}
                                />
                            </button>
                        </Tooltip>
                    </div>
                )
            }
        ]

        setDataSource(dataSources)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevUserProps = usePrevious(user);
    const prevVehicleProps = usePrevious(vehicles);
    const prevCustomerProps = usePrevious(customers);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            mounted.current = true;
    
            setupDataSource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevUserProps) !== JSON.stringify(user)
                ||
                JSON.stringify(prevVehicleProps) !== JSON.stringify(vehicles)
                ||
                JSON.stringify(prevCustomerProps) !== JSON.stringify(customers)
            ) {
                setupDataSource();
            }
        }
    });

    return (
        <div className="page-container">
            <Page title="Device Management">
                <Form layout='inline' style={{ marginBottom: 5 }}>
                    <Form.Item>
                        <span>Pair Status </span>

                        <Select
                            showSearch
                            allowClear
                            defaultValue={selectedPairStatus}
                            placeholder="Select a pair status"
                            optionFilterProp="children"
                            onChange={value => {
                                setSelectedPairStatus(value)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 200 }}
                        >
                            {/* <Select.Option
                                key={defaultFilter}
                                value={defaultFilter}
                            >
                                {defaultFilter}
                            </Select.Option> */}

                            {
                                ([
                                    { label: `UNPAIRED`, value: 0 },
                                    { label: `PAIRED`, value: 1 },
                                ]).map((value) => {
                                    return (
                                        <Select.Option
                                            key={value.label}
                                            value={value.value}
                                        >
                                            {value.label}
                                        </Select.Option>
                                    );
                                })
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <span>Created start time: </span>

                        <DatePicker
                            style={{ width: 100 }}
                            showTime
                            defaultValue={startTime}
                            onChange={(value) => setStartTime(value)}
                        />

                        <span style={{ marginLeft: '10px' }}>Created end time: </span>

                        <DatePicker
                            style={{ width: 200 }}
                            showTime
                            defaultValue={endTime}
                            onChange={(value) => setEndTime(value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            onClick={() => {

                                const dgids = Object.keys(deviceGroups.byDGID);
                                const pairedStatus = selectedPairStatus
                                const status = selectedDeviceConnectStatus === DEVICE_STATUSES.CONNECTED ? 1 : undefined
                                const param_startTime = startTime && startTime.valueOf()
                                const param_endTime = endTime && endTime.valueOf()

                                dispatch(get_devices(dgids, status, pairedStatus, param_startTime, param_endTime))
                            }}
                            style={{ marginLeft: '15px' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>


                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        padding: 5,
                        marginBottom: 5,
                    }}
                >
                    <UploadExcelButton
                        disabled={dataSource.length === 0}
                        title='Bulk upload device'
                        buttonName={`Bulk Upload Devices`}
                        excelTemplateName={`Bulk Upload Devices Template`}
                        templateUrl={TEMPLATE_URLS.BULK_UPLOAD_DEVICES_TEMPLATE}
                        dataColumns={
                            {
                                imei: {
                                    label: 'Imei',
                                    rule: (imei, allImeis) => {
                                        if (!imei) throw new Error(`Device Imei Cannot Be Blank`);
                                        if (isNaN(Number(imei))) throw new Error(`Imei is not a number`)

                                        const isExist = Object.values(devices.byDVID).find(device => device.imei === imei);

                                        if (isExist) {
                                            throw new Error(`Imei already exist`)
                                        }

                                        const duplicates = allImeis.filter(_imei => imei === _imei);

                                        if (duplicates.length > 1) {
                                            throw new Error(`Duplicated imei`)
                                        }
                                    },
                                },
                                simCard: {
                                    label: 'Sim Card',
                                    rule: (simCard) => {
                                        const isPassPhoneRegex = phone => {
                                            const regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
                                            return regex.test(phone)
                                        }

                                        if (simCard && !isPassPhoneRegex(simCard)) throw new Error(`Invalid mobile number`);

                                    },
                                },
                                simSerial: {
                                    label: 'Sim Serial Number',
                                    rule: (simSerial) => {
                                        const SIM_SERIAL_EXACT_LENGTH = 18

                                        if (simSerial && isNaN(Number(simSerial))) throw new Error(`Sim Serial Number is not a number`);
                                        if (simSerial && simSerial.length !== SIM_SERIAL_EXACT_LENGTH) throw new Error(`Sim Serial Number must be exactly 18 characters long`);

                                    },
                                }
                            }
                        }
                        uploadLoop={async (excelData) => {
                            excelData.imei = excelData.imei && excelData.imei.toString().replace(/\s+/g, '')
                            excelData.simSerial = excelData.simSerial && excelData.simSerial.toString()
                            excelData.simCard = excelData.simCard && excelData.simCard.toString()

                            const newDevice = {
                                activeStatus: 1,
                                imei: excelData.imei,
                                simCard: excelData.simCard,
                                simSerial: excelData.simSerial,
                            };
                            const { uid } = user

                            // console.log({ excelData, newDevice, uid });

                            // const data = await fakeAPI(newDevice, uid)
                            const data = await API.addDevice(uid, newDevice);

                            // console.log(data);

                            switch (data.status) {
                                case 200:
                                    dispatch(add_device_success(data.device));
                                    return {
                                        uploadStatus: 'SUCCESS',
                                        message: ''
                                    }

                                case 201:
                                    return {
                                        uploadStatus: 'WARNING',
                                        message: data.message
                                    }

                                default:
                                    return {
                                        uploadStatus: 'FAILED',
                                        message: data.message
                                    }
                            }

                            // function fakeAPI() {
                            //     return new Promise((resolve, reject) => {
                            //         setTimeout(() => {
                            //             resolve({
                            //                 status: 200,
                            //                 device: {
                            //                     dvid: `GOS100#${Math.random()}`
                            //                 }
                            //             })
                            //         }, 1 * 1000)
                            //     })
                            // }
                        }}

                    />

                    <PrimaryButton onClick={() => dispatch(moveToPage("DeviceManagement/AddDevice"))}>
                        + New Device
                    </PrimaryButton>

                    <ExportExcelButton
                        disabled={dataSource.length === 0}
                        filename={excelname}
                        sheetData={dataSource}
                        sheetName={`Devices`}
                        sheetRow={
                            columns
                                .filter(col => col.title !== `Actions`)
                                .map(col => {
                                    switch (col.dataIndex) {
                                        case 'updatedAt':
                                        case 'createdAt':
                                            return {
                                                label: col.title,
                                                formatter: value => col.render(value[col.dataIndex])
                                            }

                                        default:
                                            return {
                                                label: col.title,
                                                formatter: value => value[col.dataIndex]
                                            }
                                    }
                                })
                        }
                    />
                </div>

                {
                    (
                        user.loadingStatus === LOADING_STATUS.LOADING
                        || devices.loadingStatus === LOADING_STATUS.LOADING
                        || vehicles.loadingStatus === LOADING_STATUS.LOADING
                    ) ?
                        <Skeleton active /> :
                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={true}
                            scroll={{
                                x: columns && columns.length * 150,
                                y: window.innerHeight
                            }}
                        />
                }
            </Page>
        </div>
    )
}

export default showSecondarySidebar(false)(DeviceManagement);