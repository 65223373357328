import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "./Layout";
import PropTypes from "prop-types";

import { FEATURE } from "../../constants";
import { buildAbility } from "../../services/auth/accessRight";
import { moveToPage } from "../../navigation/navigationService";

import {
  ApiOutlined,
  CarOutlined,
  SolutionOutlined,
  ToolOutlined,
  ReconciliationOutlined,
  LineChartOutlined,
  DashboardOutlined,
  SnippetsOutlined,
  UserOutlined
} from '@ant-design/icons'

class LayoutContainer extends Component {
  static propTypes = {
    children: PropTypes.element
  };

  navigateTo = pageKey => this.props.dispatch(moveToPage(pageKey));

  render() {
    const { 
      user, 
      location 
    } = this.props;

    const sidebarItems = [
      {
        key: `/${FEATURE.VEHICLE_MANAGEMENT}`,
        icon: <CarOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Management"
      },
      
      {
        key: `/${FEATURE.DEVICE_MANAGEMENT}`,
        icon: <ApiOutlined style = {{ fontSize: 24 }}/>,
        label: "Device Management"
      },
      
      {
        key: `/${FEATURE.CUSTOMER_MANAGEMENT}`,
        icon: <SolutionOutlined style = {{ fontSize: 24 }}/>,
        label: "Customer Management"
      },

      {
        key: `/${FEATURE.SERVICE_MANAGEMENT}`,
        icon: <ToolOutlined style = {{ fontSize: 24 }}/>,
        label: "Service Management"
      },
      
      {
        key: `/${FEATURE.SERVICE_REMINDER_RECORD}`,
        icon: <ReconciliationOutlined style = {{ fontSize: 24 }}/>,
        label: "Service Reminder Record"
      },
      
      {
        key: `/${FEATURE.AFTER_SALES_STATUS_LOG}`,
        icon: <LineChartOutlined style = {{ fontSize: 24 }}/>,
        label: "After Sales Status Log"
      },
      
      {
        key: `/${FEATURE.VEHICLE_AVERAGE_MILEAGE_LOG}`,
        icon: <DashboardOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Average Mileage Log"
      },

      // {
      //   key: `/${FEATURE.EVENT_LOG}`,
      //   icon: "pie-chart",
      //   label: "Event Log"
      // },

      // {
      //   key: `/${FEATURE.ROUTE_PLAYBACK}`,
      //   icon: "history",
      //   label: "Route Playback"
      // },

      {
        key: `/${FEATURE.REPORT}`,
        icon: <SnippetsOutlined style = {{ fontSize: 24 }}/>,
        label: "Report"
      },

      {
        key: `/${FEATURE.PROFILE}`,
        icon: <UserOutlined style = {{ fontSize: 24 }}/>,
        label: "Profile"
      },
    ];

    const items = sidebarItems.filter((listItem) => {
      if (user && user.userRole) {
        return buildAbility(user.userRole).can("read", listItem.key.slice(1));
      }
      else {
        return buildAbility('NONE').can("read", listItem.key.slice(1));
      }
    });

    return (
      <Layout
        sidebarItems = {items}
        onClickSidebarItem = {sidebarItem => this.navigateTo(sidebarItem.key|| "/")}
        selectedKeys = {location ? location.pathname : ""}
        themeColor = {(this.props.style && this.props.style.sidebarColor)|| "#fff"}
      >
        {this.props.children}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  style: state.style,
});

export default connect(mapStateToProps)(LayoutContainer);