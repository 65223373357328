import React, { Component } from 'react';
import { connect } from "react-redux";

import firebase from "firebase/app";
import Layout from "./components/Layout";
import AppRoutes from "./navigation/AppRoutes";
import MainRoutes from "./navigation/MainRoutes";

import { Menu } from "antd";
import { moveToMenuSelection } from "./navigation/navigationService";
import {
  ROLE_TYPE,
  SCREEN_LAYOUT
} from './constants';

// Redux Actions
import { user_sign_in_request } from './services/redux/actions/user';

import './App.css';
import './index.css';

class App extends Component {
  state = {
    _loaded: false,
    isSidebarCollapsed: false,
    roleType: ROLE_TYPE.UNKNOWN,
    currentStatusList: [],
  };

  menu = button => (
    <Menu>
      {
        button.list.map((menuItem, index) => (
          <Menu.Item key = {index}>
            <div
              className = "ButtonLink"
              id = {menuItem}
              rel = "noopener noreferrer"
              onClick = {e => this.props.dispatch(moveToMenuSelection(menuItem))}
            >
              {menuItem}
            </div>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  componentDidMount() {
    // console.log("Firebase User:", firebase.auth().currentUser.uid);

    this.props.dispatch(user_sign_in_request(firebase.auth().currentUser.uid));

    // console.log(SCREEN_LAYOUT);
  }

  render() {
    const { 
      user, 
      style,
    } = this.props;

    return (
      <div>
        <Layout user = {(user && user.user) || {}}>
          <div className = "app-frame">
            { 
              style && style.hasOwnProperty('isSidebarCollapsed') && !style.isSidebarCollapsed && 
                <div
                  className = "sidebar card animate-left"
                  id = "SideBar"
                  style = {{ width: SCREEN_LAYOUT.FULL_SIDEBAR_WIDTH }}
                >
                  <div className = "sidebar-content-container">
                    <AppRoutes/>
                  </div>
                </div>
            }

            <div className = "app-content">
              <MainRoutes {...this.props}/>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(appStore => appStore)(App);