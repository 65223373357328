import * as API from "../../api/afterSalesLogs";
import * as ActionTypes from "../action-types/afterSalesLogs";

import { message } from 'antd';
// import { goBackToPrev } from "../../../navigation/navigationService";
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";

import {
    LOADING_STATUS
} from '../../../constants'

export const get_after_sales_log = (vids = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    dispatch(set_after_sales_log_loading_status(LOADING_STATUS.LOADING))

    API.getAfterSalesLogs(vids, startTime, endTime)
        .then(data => {
            // console.log("getAfterSalesLogs data:", data);

            if (data.status !== 200) {
                message.error('Failed To Get AfterSales Log: ' + data.message);
                dispatch(set_after_sales_log_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                dispatch(get_after_sales_log_success(data.afterSalesLogs));
                dispatch(set_after_sales_log_loading_status(LOADING_STATUS.SUCCESS))
            }

            dispatch(unsetLoadingSubmit());
        })
}

const get_after_sales_log_success = (afterSalesLogs) => {
    return {
        type: ActionTypes.GET_AFTER_SALES_LOG,
        afterSalesLogs: afterSalesLogs
    }
}

export const clear_after_sales_log = () => {
    return {
        type: ActionTypes.CLEAR_AFTER_SALES_LOG
    }
}

export const set_selected_sales_log = (selectedLog) => {
    return {
        type: ActionTypes.SET_SELECTED_SALES_LOG,
        selectedLog: selectedLog,
    }
}

export const set_after_sales_log_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_AFTER_SALES_LOADING_STATUS,
        loadingStatus
    }
}

