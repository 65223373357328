export const GET_VEHICLES = "GET_VEHICLES";

export const ADD_VEHICLE = "ADD_VEHICLE";
export const EDIT_VEHICLE = "EDIT_VEHICLE";

export const ALLOCATE_VEHICLE = "ALLOCATE_VEHICLE";
export const ASSIGN_DEVICE_TO_VEHICLE = "ASSIGN_DEVICE_TO_VEHICLE";
export const UNASSIGN_DEVICE = "UNASSIGN_DEVICE";

export const SET_SELECTED_VID = "SET_SELECTED_VID";
export const CLEAR_SELECTED_VID = "CLEAR_SELECTED_VID";

export const SET_VEHICLE_CID = "SET_VEHICLE_CID";
export const CLEAR_VEHICLE_CID = "CLEAR_VEHICLE_CID";

// Pair Progress Stuff
export const SET_UP_PAIR_PROGRESS = "SET_UP_PAIR_PROGRESS";
export const UPDATE_PAIR_PROGRESS = "UPDATE_PAIR_PROGRESS";
export const CLEAR_PAIR_PROGRESS = "CLEAR_PAIR_PROGRESS";

// Unpair Progress Stuff
export const SET_UP_UNPAIR_PROGRESS = "SET_UP_UNPAIR_PROGRESS";
export const UPDATE_UNPAIR_PROGRESS = "UPDATE_UNPAIR_PROGRESS";
export const CLEAR_UNPAIR_PROGRESS = "CLEAR_UNPAIR_PROGRESS";

export const UPDATE_VEHICLE_MILEAGES = "UPDATE_VEHICLE_MILEAGES";
export const SET_VEHICLE_LOADING_STATUS = 'SET_VEHICLE_LOADING_STATUS'