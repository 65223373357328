import 
    React,
    {
        useRef,
        useEffect,
        useState
    }
from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";


import moment from 'moment';
import parseDays from '../../../utils/parseDays';
import parseDate from '../../../utils/parseDate';
import parseTime from "../../../utils/parseTime";
import ExportExcelButton from "../../../components/ExportExcelButton";

import { tableColumnSearchProps } from '../../../components/TableColumnSearchProps';
import {
    Table,
    Modal,
    Result,
    Button,
    Skeleton,
} from "antd";
import {
    CheckCircleFilled
} from '@ant-design/icons'

import {
    STATUS_TYPES,
    SERVICE_TYPES,
    TRIGGER_TYPES,
    VEHICLE_MODELS,
    LOADING_STATUS,
    SERVICE_REMINDER_STATUSES,
} from '../../../constants';

// Redux Actions
import { edit_service_reminder } from '../../../services/redux/actions/serviceReminders';

const SMSTable = (props) => {

    const dispatch = useDispatch();

    const user = useSelector(state =>state.user)
    const staff = useSelector(state => state.staff)
    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)
    const serviceReminders = useSelector(state => state.serviceReminders)

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])
    const [selectedServiceReminders, setSelectedServiceReminders] = useState('');

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const setupDataSource = () => {

        const dataSources = props.smsDatasource
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((serviceReminder) => {
                const vehicle = vehicles.byVID[serviceReminder.vid]
                const customer = vehicle && vehicle.cid && customers.byCID[vehicle.cid]

                return {
                    key: serviceReminder.srid,
                    serviceAdvisor: (vehicle.serviceAdvisor && staff.byUID[vehicle.serviceAdvisor] && staff.byUID[vehicle.serviceAdvisor].userName) || "-",
                    customerName: (customer && customer.name) || "-",
                    contactNumber: (customer && customer.contactNumber )|| "-",
                    vehiclePlate: vehicle.vehiclePlate || "-",
                    vehicleModel: vehicle.vehicleModel || "-",
                    lastProcessTotalMileage: !isNaN(vehicle.aSProfile.totalMileage) ? Math.ceil(vehicle.aSProfile.totalMileage) : "-",
                    serviceType: serviceReminder.serviceType || "-",
                    reminderType: serviceReminder.reminderType,
                    triggerType: serviceReminder.triggerType || "-",
                    dueDate: serviceReminder.estimatedAppointment,
                    dueIn: parseDays(serviceReminder.estimatedAppointment - moment.now()),
                    reminderStatus: serviceReminder.reminderStatus || "-",
                    reason: serviceReminder.reason || "-",
                    createdAt: serviceReminder.createdAt,
                    updatedAt: serviceReminder.updatedAt,
                }
            })
        
        const column = [
            {
                title: "Service Advisor",
                dataIndex: "serviceAdvisor",
                width: 100,
                ...tableColumnSearchProps("serviceAdvisor", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Contact Number",
                dataIndex: "contactNumber",
                ...tableColumnSearchProps("contactNumber", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel",
                filters: Object.values(VEHICLE_MODELS).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.vehicleModel.indexOf(value) === 0,
            },

            {
                title: "Last Process Total Mileage (km)",
                dataIndex: "lastProcessTotalMileage",
                sorter: (a, b) => a.lastProcessTotalMileage - b.lastProcessTotalMileage
            },
            {
                title: "Service Type",
                dataIndex: "serviceType",
                filters: Object.values(SERVICE_TYPES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.serviceType.indexOf(value) === 0,
            },

            {
                title: "Status Type",
                dataIndex: "reminderType",
            },

            {
                title: "Trigger Type",
                dataIndex: "triggerType",
                filters: Object.values(TRIGGER_TYPES).map(s => ({ text: s, value: s })),
                onFilter: (value, record) => value && record.triggerType.indexOf(value) === 0,
            },

            {
                title: "Due date",
                dataIndex: "dueDate",
                sorter: (a, b) => b.dueDate - a.dueDate,
                render: dueDate => parseDate(dueDate)
            },

            {
                title: "Due in (Days)",
                dataIndex: "dueIn",
                sorter: (a, b) => b.dueIn - a.dueIn,
            },
            {
                title: "Created Date",
                dataIndex: "createdAt",
                sorter: (a, b) => b.createdAt - a.createdAt,
                render: createdAt => parseDate(createdAt)
            },
            {
                title: "Last Updated",
                dataIndex: "updatedAt",
                sorter: (a, b) => b.updatedAt - a.updatedAt,
                render: updatedAt => parseTime(updatedAt)
            },
            {
                title: "Mark as done",
                fixed: 'right',
                render: (rowData) => {
                    const srid = rowData.key
                    return (
                        <Button
                            disabled={rowData.reminderStatus === SERVICE_REMINDER_STATUSES.COMPLETED}
                            type='primary'
                            onClick={() => {
                                return Modal.confirm({
                                    icon: <CheckCircleFilled style={{ color: 'green' }}/>,
                                    title: 'Mark service reminder as done ?',
                                    content: (
                                        <div>
                                            <p>Reason: SMS Sent</p>
                                        </div>
                                    ),
                                    onOk: () => { markServiceReminderAsDone(srid) },
                                    onCancel() { },
                                });
                            }}
                        >
                            Done
                        </Button>
                    )
                }
            },
        ]

        setDataSource(dataSources)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSMSDatasourceProps = usePrevious(props.smsDatasource);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            if(props.hasOwnProperty('smsDatasource')) {
                setupDataSource();
            }

            mounted.current = true;
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevSMSDatasourceProps ) !== JSON.stringify(props.smsDatasource)
            ) {
                setupDataSource();
            }
        }
    });

    const markServiceReminderAsDone = (srid) => {
        const param_serviceReminder = serviceReminders.bySRID[srid]
        const vid = param_serviceReminder.vid
        const reason = 'SMS Sent'
        const reminderStatus = STATUS_TYPES.COMPLETED

        // console.log({
        //     vid,
        //     srid,
        //     reason,
        //     reminderStatus
        // })

        const newServiceReminder = {
            ...param_serviceReminder,
            vid,
            srid,
            reason,
            reminderStatus
        }

        // console.log({ newServiceReminder })

        dispatch(edit_service_reminder(newServiceReminder));
    }

    const isLoading = (user.loadingStatus === LOADING_STATUS.LOADING)
            || (serviceReminders.loadingStatus === LOADING_STATUS.LOADING)
            || (vehicles.loadingStatus === LOADING_STATUS.LOADING)
            || (staff.loadingStatus === LOADING_STATUS.LOADING)
            || (customers.loadingStatus === LOADING_STATUS.LOADING)

    const isAllDone = dataSource.length === 0;

    const rowSelection = {
        selectedRowKeys: selectedServiceReminders,
        onChange: (value) => {
            setSelectedServiceReminders(value)
        },
    };

    return (
        <div className="page-container">
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>

                <Button
                    disabled={!selectedServiceReminders || selectedServiceReminders.length === 0}
                    type='primary'
                    onClick={() => {
                        return Modal.confirm({
                            icon: <CheckCircleFilled style={{ color: 'green' }}/>,
                            title: 'Batch mark service reminder as done ?',
                            content: (
                                <div>
                                    <p>Reason: SMS Sent</p>
                                </div>
                            ),
                            onOk: () => {
                                selectedServiceReminders.map(srid => this.markServiceReminderAsDone(srid))
                            },
                            onCancel() { },
                        });
                    }}
                >
                    Batch mark as done
                    </Button>
                <ExportExcelButton
                    loading={isLoading}
                    filename={`Service Reminders (SMS) ${moment(moment().toISOString(), 'DD-MM-YYYY')}`}
                    sheetData={dataSource}
                    sheetName={`Service Reminders ${moment(moment().toISOString(), 'DD-MM-YYYY')}`}
                    sheetRow={
                        columns
                            .map(col => {
                                switch (col.dataIndex) {
                                    case 'createdAt':
                                    case 'updatedAt':
                                        return {
                                            label: col.title,
                                            formatter: value => parseTime(value[col.dataIndex])
                                        }
                                    case 'dueDate':
                                        return {
                                            label: col.title,
                                            formatter: value => col.render(value[col.dataIndex])
                                        }
                                    case undefined:
                                        return null
                                    default:
                                        return {
                                            label: col.title,
                                            formatter: value => value[col.dataIndex]
                                        }
                                }
                            })
                            .filter(c => c)
                    }
                />
            </div>

            {
                isLoading ?
                    <Skeleton active /> :
                    isAllDone ?
                        <Result
                            status="success"
                            title="All Service Reminders Marked Done!"
                        /> :
                        <Table
                            columns={columns}
                            rowSelection={rowSelection}
                            dataSource={dataSource}
                            pagination={true}
                            scroll={{
                                x: columns.length * 150,
                                y: window.innerHeight,
                            }}
                        />
            }
        </div>
    )

}

export default SMSTable;