import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

// Redux Stores
import { user } from './user';
import { style } from './style';
import { staff } from './staff';
import { customers } from './customers';
import { devices } from './devices';
import { deviceGroups } from './deviceGroups';
import { vehicles } from './vehicles';
import { vehicleGroups } from './vehicleGroups';
import { geofences } from './geofences';
import { geofenceTemplates } from './geofenceTemplates';
import { events } from './events';
import { deviceLogs } from './deviceLogs';
import { transitLogs } from './transitLogs';
import { mapControl } from './mapControl';
import { serviceReminders } from './serviceReminders';
import { serviceReminderLogs } from './serviceReminderLogs';
import { afterSalesLogs } from './afterSalesLogs';
import { vehicleASLogs } from './vehicelASLogs';
import { playback } from './playback';
import { serviceConfigurations } from './serviceConfigurations';
import { afterSalesWorkflows } from './afterSalesWorkflows';
import { serviceVehicleRecords } from './serviceVehicleRecords'


export const createRootReducer = (history) => combineReducers({
  user,
  style,
  router: connectRouter(history),
  // ... // rest of your reducers

  // Redux Stores
  serviceConfigurations,
  serviceVehicleRecords,
  afterSalesWorkflows,
  staff,
  customers,
  devices,
  deviceGroups,
  vehicles,
  vehicleGroups,
  geofences,
  geofenceTemplates,
  events,
  deviceLogs,
  transitLogs,
  mapControl,
  serviceReminders,
  serviceReminderLogs,
  afterSalesLogs,
  vehicleASLogs,
  playback,
})