import * as ActionTypes from "../action-types/vehicles";

import moment from "moment";
import ParseTime from "../../../utils/parseTime";

import { 
    STATUS_TYPES,
    PROGRESS_TYPES,
    VEHICLE_GROUP_TYPES 
} from "../../../constants";
// import { set_up_unassign_progress } from "../actions/vehicles";

const defaultState = {
    byCID: {},
    byVID: {},
    byDVID: {},
    selectedVID: "",

    // Used to display allocation progress eg. Dashboard
    // modalProgress contains 
    // {
    //     [vid]: {
    //         key: "", // For tables
    //         vehiclePlate: "",
    //         area: "-",
    //         outlet: "-",
    //         allocatedTime: "-", // Either "-" or time in miliseconds
    //         status: "PENDING",
    //         remarks: "", // Can be left blank
    //     }
    // }
    modalProgress: {},
    loadingStatus: undefined,
}

export const vehicles = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_VEHICLES: {
            newState.byCID = JSON.parse(JSON.stringify(defaultState.byCID)); // Clear newState byCID
            newState.byVID = JSON.parse(JSON.stringify(defaultState.byVID)); // Clear newState byVID
            newState.byDVID = JSON.parse(JSON.stringify(defaultState.byDVID)); // Clear newState byDVID
            
            action.vehicles.forEach((currVehicle) => {
                newState.byVID[currVehicle.vid] = currVehicle;
                newState.byDVID[currVehicle.dvid] = currVehicle.vid;

                if (currVehicle.cid) {
                    newState.byCID[currVehicle.cid] = currVehicle.vid;
                }
            });

            return newState;
        }

        case ActionTypes.ADD_VEHICLE: {
            newState.byVID[action.newVehicle.vid] = action.newVehicle;
            newState.byCID[action.newVehicle.cid] = action.newVehicle.vid;
            newState.byDVID[action.newVehicle.dvid] = action.newVehicle.vid;

            return newState;
        }

        case ActionTypes.EDIT_VEHICLE: {  
            newState.byVID[action.editedVehicle.vid] = action.editedVehicle;

            return newState;
        }
        
        case ActionTypes.ALLOCATE_VEHICLE: {
            action.vgList.forEach((currVG) => {
                if (currVG.type === VEHICLE_GROUP_TYPES.AREA) {
                    newState.byVID[action.selectedVID].area = currVG.vgid;
                }
                else if (currVG.type === VEHICLE_GROUP_TYPES.OUTLET) {
                    newState.byVID[action.selectedVID].outlet = currVG.vgid;
                }
            })

            return newState;
        }
        
        case ActionTypes.ASSIGN_DEVICE_TO_VEHICLE: {
            newState.byVID[action.vehicle.vid] = action.vehicle;
            newState.byDVID[action.vehicle.dvid] = action.vehicle.vid;

            return newState;
        }
        
        case ActionTypes.UNASSIGN_DEVICE: {
            newState.byVID[newState.byDVID[action.dvid]].dvid = "-";

            delete newState.byDVID[action.dvid];

            return newState;
        }
        
        case ActionTypes.SET_SELECTED_VID: {
            return {
                ...newState,
                selectedVID: action.newVID
            };
        }
        
        case ActionTypes.CLEAR_SELECTED_VID: {
            return {
                ...newState,
                selectedVID: defaultState.selectedVID
            };
        }
        
        case ActionTypes.SET_UP_PAIR_PROGRESS: {
            // newState.modalProgress = JSON.parse(JSON.stringify(defaultState.modalProgress)); // Clear newState modalProgress

            newState.modalProgress = {
                // ...newState.modalProgress, // Only add to the modalProgress
                [action.selectedVID]: {
                    key: action.selectedVID, // For tables
                    vehiclePlate: newState.byVID[action.selectedVID].vehiclePlate,
                    allocatedTime: "-",
                    status: PROGRESS_TYPES.PENDING,
                    remarks: "-",
                }
            }

            // console.log("New Pair Progress:", newState.modalProgress);
            
            return newState;
        }
        
        case ActionTypes.UPDATE_PAIR_PROGRESS: {
            newState.modalProgress[action.dvid] = {
                ...newState.modalProgress[action.dvid],
                allocatedTime: ParseTime(moment.now()),
                status: action.isSuccess ? PROGRESS_TYPES.SUCCESS : PROGRESS_TYPES.FAILED,
                remarks: action.remarks,
            }

            // console.log("Updated Pair Progress:", newState.modalProgress);
            
            return newState;
        }

        case ActionTypes.CLEAR_PAIR_PROGRESS: {
            newState.modalProgress = JSON.parse(JSON.stringify(defaultState.modalProgress)); // Clear newState modalProgress

            return newState;
        }
        
        case ActionTypes.SET_UP_UNPAIR_PROGRESS: {
            // newState.modalProgress = JSON.parse(JSON.stringify(defaultState.modalProgress)); // Clear newState modalProgress

            newState.modalProgress = {
                ...newState.modalProgress, // Only add to the modalProgress
                [action.dvid]: {
                    key: action.dvid, // For tables
                    vehiclePlate: newState.byVID[newState.byDVID[action.dvid]].vehiclePlate,
                    dvid: action.dvid,
                    allocatedTime: "-",
                    status: PROGRESS_TYPES.PENDING,
                    remarks: "-",
                }
            }

            // console.log("New Unpair Progress:", newState.modalProgress);
            
            return newState;
        }
        
        case ActionTypes.UPDATE_UNPAIR_PROGRESS: {
            newState.modalProgress[action.dvid] = {
                ...newState.modalProgress[action.dvid],
                allocatedTime: ParseTime(moment.now()),
                status: action.isSuccess ? PROGRESS_TYPES.SUCCESS : PROGRESS_TYPES.FAILED,
                remarks: action.remarks ? action.remarks : "-",
            }

            // console.log("Updated Unpair Progress:", newState.modalProgress);
            
            return newState;
        }

        case ActionTypes.CLEAR_UNPAIR_PROGRESS: {
            newState.modalProgress = JSON.parse(JSON.stringify(defaultState.modalProgress)); // Clear newState modalProgress

            return newState;
        }

        case ActionTypes.SET_VEHICLE_CID: {
            // delete newState.byCID[action.newCID];

            newState.byCID[action.newCID] = action.selectedVID;
            newState.byVID[action.selectedVID].cid = action.newCID;
            newState.byVID[action.selectedVID].afterSalesStatus = STATUS_TYPES.INITIATED;

            return newState;
        }

        case ActionTypes.CLEAR_VEHICLE_CID: {
            delete newState.byCID[action.newCID];
            
            newState.byVID[action.selectedVID].cid = "";

            return newState;
        }

        case ActionTypes.UPDATE_VEHICLE_MILEAGES: {
            newState.byVID[action.selectedVID].totalMileage = action.totalMileage;

            newState.byVID[action.selectedVID].aSProfile.nextMileage = action.nextMileage;
            newState.byVID[action.selectedVID].aSProfile.totalMileage = action.totalMileage;

            return newState;
        }

        case ActionTypes.SET_VEHICLE_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}