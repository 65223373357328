import * as ActionTypes from "../action-types/afterSalesLogs";

const defaultState = {
    selectedLog: {},
    byVID: {},
    loadingStatus: undefined
}

export const afterSalesLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_AFTER_SALES_LOG: {            
            newState.byVID = {}
            action.afterSalesLogs.forEach((log) => {
                if (!newState.byVID[log.vid]) {
                    newState.byVID[log.vid] = [];
                }

                newState.byVID[log.vid].push(log);
            })
            return newState;
        }

        case ActionTypes.CLEAR_AFTER_SALES_LOG: {
            newState.byVID = {};

            return newState;
        }

        case ActionTypes.SET_SELECTED_SALES_LOG: {
            newState.selectedLog = action.selectedLog;

            return newState;
        }
        
        case ActionTypes.SET_AFTER_SALES_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return state;
        }
    }
};
