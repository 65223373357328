import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useDispatch,
    useSelector
 } from "react-redux";

import Page from "../../components/Page";
import parseTime from "../../utils/parseTime";
import PrimaryButton from '../../components/PrimaryButton';
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { moveToPage } from "../../navigation/navigationService";
import { tableColumnSearchProps } from '../../components/TableColumnSearchProps';
import {
    STATUS_TYPES,
    LOADING_STATUS,
    VEHICLE_MODELS,
    DEVICE_STATUSES,
} from "../../constants";
import {
    Form,
    Table,
    Select,
    Button,
    message,
    Skeleton,
    DatePicker,
    AutoComplete,
} from "antd";
import moment from 'moment';

// Pop Ups
import PopUpPair from "./PopUpPair";
import PopUpUnpair from "./PopUpUnpair";

// Constant
import { POP_UP_ACTION } from '../../constants/vehicleAction'

// Redux Actions
import {
    get_vehicles,
    set_selected_vid,
    // clear_unassign_progress,
} from "../../services/redux/actions/vehicles";

const VehicleManagement = (props) => {

    const excelname = `Vehicle (${moment().format('YYYY MM DD')})`

    const dispatch = useDispatch();

    const user = useSelector(state => state.user)
    // const style = useSelector(state => state.style)
    const staff = useSelector(state => state.staff)
    const devices = useSelector(state => state.devices)
    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)
    const vehicleGroups = useSelector(state => state.vehicleGroups)

    const [currPopUp, setCurrPopUp] = useState('');
    const [selectedVID, setSelectedVID] = useState('');
    const [selectedVIDList, setSelectedVIDList] = useState('');
    const [autoCompleteRes, setAutoCompleteRes] = useState([...Object.values(VEHICLE_MODELS)])
    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])
    const [startTime, setStartTime] = useState(moment().startOf("month"))
    const [endTime, setEndTime] = useState(moment().endOf("month"))

    const [selectedVehicleModel, setSelectedVehicleModel] = useState('')
    const [selectedVehicleStatus, setSelectedVehicleStatus] = useState('')

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const onCloseModal = () => {
        setCurrPopUp('')
        setSelectedVID('')
        setSelectedVIDList([])
    }

    const rowSelection = {
        selectedRowKeys: selectedVIDList,
        onChange: (value) => setSelectedVIDList(value)
    };

    const setupDataSource = () => {

        const dataSources = Object.values(vehicles.byVID)
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((vehicle) => {
                const device = devices.byDVID[vehicle.dvid];
                const customer = vehicle.cid && customers.byCID[vehicle.cid];
                const serviceAdvisor = vehicle.serviceAdvisor && staff.byUID[vehicle.serviceAdvisor];

                return {
                    key: vehicle.vid, // Used to identify which vehicle the action would work on
                    vehiclePlate: vehicle.vehiclePlate || "-",
                    vehicleModel: vehicle.vehicleModel || "-",
                    chassisNumber: vehicle.chassisNumber || "-",
                    imei: device && device.imei,
                    deviceStatus: (device && (device.connectStatus ? DEVICE_STATUSES.CONNECTED : DEVICE_STATUSES.DISCONNECTED) )|| '-',
                    deliveryDate: vehicle.deliveredAt,
                    customerName: (customer && customer.name )|| '-',
                    serviceAdvisorName: (serviceAdvisor && serviceAdvisor.userName) || '-',
                    vehicleStatus: vehicle.aSProfile.hasOwnProperty('afterSalesStatus') ? vehicle.aSProfile.afterSalesStatus && STATUS_TYPES[vehicle.aSProfile.afterSalesStatus] : vehicle.afterSalesStatus && STATUS_TYPES[vehicle.afterSalesStatus],
                    lastProcessTotalMileage: !isNaN(vehicle.aSProfile.totalMileage) ? vehicle.aSProfile.totalMileage.toFixed(2) : "-",
                    estimatedAppointment: vehicle.aSProfile && vehicle.aSProfile.estimatedAppointment,
                    createdAt: vehicle.createdAt,
                    updatedAt: vehicle.updatedAt,
                };
            })

        const column = [
            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                fixed: "left",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel",
                ...tableColumnSearchProps("vehicleModel", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Status",
                dataIndex: "vehicleStatus",
                filters: Object.values(STATUS_TYPES).map(value => ({ text: value, value: value })),
                onFilter: (value, record) => value && record.vehicleStatus.indexOf(value) === 0,
            },

            {
                title: "Chassis Number",
                dataIndex: "chassisNumber",
                ...tableColumnSearchProps("chassisNumber", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Device IMEI",
                dataIndex: "imei",
                ...tableColumnSearchProps("imei", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Device Status",
                dataIndex: "deviceStatus",
                render: deviceStatus => {
                    let color = 'black'
                    switch (deviceStatus) {
                        case DEVICE_STATUSES.CONNECTED:
                            color = 'green'
                            break
                        case DEVICE_STATUSES.DISCONNECTED:
                            color = 'red'
                            break
                        default:
                            color = 'black'
                            break
                    }
                    return <p style={{ color }}>{deviceStatus}</p>
                },
                filters: Object.values(DEVICE_STATUSES).map(deviceStatus => ({ text: deviceStatus, value: deviceStatus })),
                onFilter: (value, record) => value && record.deviceStatus.indexOf(value) === 0,
            },

            {
                title: "Actual Delivery Date",
                dataIndex: "deliveryDate",
                render: deliveryDate => parseTime(deliveryDate),
                sorter: (a, b) => a.deliveryDate - b.deliveryDate
            },

            {
                title: "Customer Name",
                dataIndex: "customerName",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Service Advisor",
                dataIndex: "serviceAdvisorName",
                ...tableColumnSearchProps("serviceAdvisorName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Last Process Total Mileage (km)",
                dataIndex: "lastProcessTotalMileage",
            },

            {
                title: "Target Service Date",
                dataIndex: "estimatedAppointment",
                render: estimatedAppointment => parseTime(estimatedAppointment),
                sorter: (a, b) => a.estimatedAppointment - b.estimatedAppointment
            },

            {
                title: "Created At",
                dataIndex: "createdAt",
                render: createdAt => parseTime(createdAt),
                sorter: (a, b) => a.createdAt - b.createdAt
            },

            {
                title: "Updated At",
                dataIndex: "updatedAt",
                render: updatedAt => parseTime(updatedAt),
                sorter: (a, b) => a.updatedAt - b.updatedAt
            },

            {
                title: "Actions",
                fixed: "right",
                render: (rowData) => {
                    const vehicle = vehicles.byVID[rowData.key]

                    if (!vehicle) return 

                    const canUnpair = devices.byDVID[vehicle.dvid] &&
                        [`S2_SERVICE`, `S2_OVERDUE`, `CREATED`, `COMPLETED`].includes(vehicle.afterSalesStatus)

                    const canPair = !devices.byDVID[vehicle.dvid]

                    return (
                        <Select
                            placeholder="Select an action"
                            value={currPopUp ? currPopUp : undefined}
                            style={{ width: 150 }}
                            onSelect={(key) => {

                                switch(key) {
                                    case POP_UP_ACTION[0]: {
                                        dispatch(set_selected_vid(rowData.key));

                                        const queryParams = `?vid=${rowData.key.replace('vhc#', '')}`;

                                        dispatch(moveToPage(`/VehicleManagement/EditVehicle${queryParams}`));
                                        break;
                                    }
                                    case POP_UP_ACTION[1]: {
                                        setCurrPopUp(POP_UP_ACTION[1])
                                        setSelectedVID(rowData.key)
                                        break;
                                    }
                                    case POP_UP_ACTION[2]: {
                                        setCurrPopUp(POP_UP_ACTION[2])
                                        setSelectedVIDList([rowData.key])
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            }}
                        >
                            <Select.Option
                                key={POP_UP_ACTION[0]}
                            >
                                Edit Vehicle
                            </Select.Option>

                            <Select.Option
                                key={POP_UP_ACTION[1]}
                                disabled={!canPair}
                            >
                                Pair Device
                            </Select.Option>

                            <Select.Option
                                disabled={!canUnpair}
                                key={POP_UP_ACTION[2]}
                            >
                                Unpair Device
                            </Select.Option>
                        </Select>
                    )
                }
            }
        ]

        setDataSource(dataSources)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevVehicleProps = usePrevious(vehicles);
    const prevStaffProps = usePrevious(staff);
    const prevDeviceProps = usePrevious(devices);
    const prevCustomerProps = usePrevious(customers);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            const vgids = Object.keys(vehicleGroups.byVGID);

            if (vgids.length) {
                dispatch(get_vehicles(vgids));
            }

            mounted.current = true;
    
            setupDataSource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevVehicleProps) !== JSON.stringify(vehicles)
                ||
                JSON.stringify(prevStaffProps) !== JSON.stringify(staff)
                ||
                JSON.stringify(prevDeviceProps) !== JSON.stringify(devices)
                ||
                JSON.stringify(prevCustomerProps) !== JSON.stringify(customers)
            ) {
                setupDataSource();
            }
        }
    });

    return (
        <div className="page-container">
            <Page title="Vehicle Management">
                <Form layout='inline' style={{ marginBottom: 5 }}>
                    <Form.Item>
                        <span style={{ marginLeft: '10px' }}>Vehicle Model: </span>

                        <AutoComplete
                            placeholder='Select a vehicle model'
                            options={autoCompleteRes.map(acr => ({value:acr}))}
                            onChange={value =>
                                setSelectedVehicleModel(value)
                            }
                            onSearch={(value) => {
                                const acr = [...Object.values(VEHICLE_MODELS)].filter(acr => acr.toLowerCase().includes(value.toLowerCase()))
                                setAutoCompleteRes(acr)
                            }}
                            style={{ width: 150 }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <span>Vehicle Status </span>

                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a vehicle status"
                            optionFilterProp="children"
                            onChange={value =>
                                setSelectedVehicleStatus(value)
                            }
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {
                                Object.keys(STATUS_TYPES).map(key => {
                                    return <Select.Option
                                        key={key}
                                        value={key}
                                    >
                                        {STATUS_TYPES[key]}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <span>Created start time: </span>

                        <DatePicker
                            style={{ width: 200 }}
                            showTime
                            defaultValue={startTime}
                            onChange={(value) => setStartTime(value)}
                        />

                        <span style={{ marginLeft: '10px' }}>Created end time: </span>

                        <DatePicker
                            style={{ width: 200 }}
                            showTime
                            defaultValue={endTime}
                            onChange={(value) => setEndTime(value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            onClick={() => {

                                const vgids = Object.keys(vehicleGroups.byVGID);
                                const model = selectedVehicleModel
                                const status = selectedVehicleStatus

                                dispatch(get_vehicles(vgids, model, status, startTime, endTime))
                            }}
                            style={{ marginLeft: '15px' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 5,
                        marginBottom: 5,
                    }}
                >

                    <div>
                        <PrimaryButton
                            disabled={selectedVIDList.length < 1}
                            onClick={() => {
                                if (
                                    selectedVIDList.filter((currVID) =>
                                        vehicles.byVID[currVID]
                                        && vehicles.byVID[currVID].dvid
                                        && vehicles.byVID[currVID].dvid !== "-"
                                    ).length > 0
                                ) {
                                    setCurrPopUp(POP_UP_ACTION[2])
                                }
                                else {
                                    if (
                                        selectedVIDList.filter((currVID) =>
                                            !(vehicles.byVID[currVID]
                                                && vehicles.byVID[currVID].dvid
                                                && vehicles.byVID[currVID].dvid !== "-")
                                        ).length > 1) {
                                        message.error("Selected vehicles have no paired device.");
                                    }
                                    else {
                                        message.error("Selected vehicle has no paired device.");
                                    }

                                    setSelectedVIDList([])
                                }

                            }}
                        >
                            Bulk Unpair
                    </PrimaryButton>

                        <PrimaryButton onClick={() => dispatch(moveToPage("VehicleManagement/AddVehicle"))}>
                            + New Vehicle
                    </PrimaryButton>

                        <ExportExcelButton
                            disabled={dataSource.length === 0}
                            filename={excelname}
                            sheetData={dataSource}
                            sheetName={`Vehicles`}
                            sheetRow={
                                columns
                                    .filter(col => col.title !== `Actions`)
                                    .map(col => {
                                        switch (col.dataIndex) {
                                            case 'updatedAt':
                                            case 'createdAt':
                                            case 'deliveryDate':
                                            case 'estimatedAppointment':
                                                return {
                                                    label: col.title,
                                                    formatter: value => parseTime(value[col.dataIndex])
                                                }
                                            default:
                                                return {
                                                    label: col.title,
                                                    formatter: value => value[col.dataIndex]
                                                }
                                        }
                                    })
                            }
                        />
                    </div>
                </div>
                {
                    (
                        user.loadingStatus === LOADING_STATUS.LOADING
                        || customers.loadingStatus === LOADING_STATUS.LOADING
                        || vehicles.loadingStatus === LOADING_STATUS.LOADING
                        || devices.loadingStatus === LOADING_STATUS.LOADING
                    ) ?
                        <Skeleton active /> :
                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={true}
                            scroll={{
                                x: columns && columns.length * 200,
                                y: window.innerHeight
                            }}
                        />
                }

                {
                    currPopUp === POP_UP_ACTION[1] &&
                    <PopUpPair onCloseModal={() => onCloseModal()} selectedVID={selectedVID} />
                }

                {
                    currPopUp === POP_UP_ACTION[2] &&
                    <PopUpUnpair onCloseModal={() => onCloseModal()} selectedVIDList={selectedVIDList} />
                }
            </Page>
        </div>
    )
}

export default showSecondarySidebar(false)(VehicleManagement);