import React, { useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

import ParseTime from "../../../utils/parseTime";

import { message } from "antd";
import {
    Marker,
    OverlayView,
} from '@react-google-maps/api';
import {
    RULE_TYPES,
    EVENT_COLORS,
    DISPLAY_RULE_TYPE,
} from "../../../constants";

// Redux Actions
import { 
    set_map_action,
    set_map_selected_evid,
    clear_map_selected_evid
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const EventMarkers = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    const events = useSelector(state => state.events);
    const mapControl = useSelector(state => state.mapControl);

    const getEventMarker = (currEvID) => {
        const currEvent = events.byEvID[currEvID];

        // console.log("Current Event:", currEvent);

        if (currEvent) {
            const currCoor = currEvent.location ? 
                {
                    lat: parseFloat(currEvent.location.lat),
                    lng: parseFloat(currEvent.location.lng)
                } : 
                {
                    lat: 0,
                    lng: 0
                };

            // Some currEventPoint don't have start locations and thus doesn't have tooltips
            function getEventMarkerIcon(eventType) {
                switch (eventType) {
                    case DISPLAY_RULE_TYPE[RULE_TYPES.WARNING]: {
                        return require('../../../img/Trip Record/warning.svg')
                    }
                        
                    case DISPLAY_RULE_TYPE[RULE_TYPES.CRITICAL]: {
                        return require('../../../img/Trip Record/critical.svg')
                    }
                        
                    case DISPLAY_RULE_TYPE[RULE_TYPES.INFO]:
                    default: {
                        return require('../../../img/Trip Record/info.svg')
                    }
                }
            }

            return (
                currEvent.location
                && currEvent.location.lat
                && currEvent.location.lng &&
                    <Marker
                        key = {currEvID}
                        icon = {{
                            url: getEventMarkerIcon(currEvent.eventType),
                            anchor: new window.google.maps.Point(25 / 2, 25 / 2), // 25 is sprite width and height. Make sure to match future anchors accordingly
                        }}
                        position = {currCoor}
                        onClick = {() => {
                            // console.log('Event Marker Clicked');

                            dispatch(set_map_action(1));
                            dispatch(set_map_selected_evid(currEvID));
                        }}
                    >
                        {
                            mapControl.selectedEvID === currEvID &&
                                <OverlayView
                                    options = {{ maxWidth: 250 }}
                                    position = {currCoor}
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 15,
                                    })}
                                    // onCloseClick = {() => dispatch(clear_map_selected_evid())}
                                >
                                    <div className = "geofenceMarkerOverlayView">
                                        <h3>{ParseTime(currEvent.createdAt)}</h3>

                                        <h4
                                            style = {{
                                                fontWeight: 'bold',
                                                color: EVENT_COLORS[currEvent.eventType],
                                            }}
                                        >
                                            <b>{currEvent.eventType}</b>
                                        </h4>

                                        <span style = {{ marginRight: 3 }}>
                                            {currEvent.eventName}
                                        </span>
                                    </div>
                                </OverlayView>
                        }
                    </Marker>
            );
        }
    }

    // ComponentDidUpdate()
    useEffect(() => {
        if (mapControl.selectedEvID && events.byEvID[mapControl.selectedEvID] && mapControl.currAction === 1) {
            const selectedEvent = events.byEvID[mapControl.selectedEvID];

            if (selectedEvent.location && selectedEvent.location.lat && selectedEvent.location.lng) {    
                if (mapRef) {
                    mapRef.setZoom(20);
                    mapRef.panTo({
                        lat: parseFloat(selectedEvent.location.lat),
                        lng: parseFloat(selectedEvent.location.lng),
                    });

                    // Do not set map action to 0 here or panning won't finish
                }
            }
            else {
                message.error("No location found for this event");

                dispatch(clear_map_selected_evid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }
    })

    return (
        Object.keys(events.byEvID).map(currEvID => {
            return getEventMarker(currEvID);
        })
    )
}

export default EventMarkers