import React from "react";
import { connect } from "react-redux";

import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { moveToDash } from "../../navigation/navigationService";
import { signOutAccount } from "../../services/auth/authService";
import { 
    Button
} from "antd";
import {
    LeftOutlined
} from '@ant-design/icons'

import {
    COMPANY,
    IMAGE_RESOURCE,
    DISPLAY_USER_ROLE,
} from "../../constants";

// Redux Actions
import { user_sign_out } from "../../services/redux/actions/user";

class ProfilePage extends React.Component {
    render() {
        const { user } = this.props;

        return (
            <div className = "page-container">
                <div
                    className = "page-header"
                    style = {{
                        backgroundColor: this.props.style.pageHeaderColor,
                        color: this.props.style.textColor
                    }}
                >
                    <div className = "page-header-contents">
                        <button
                            className = "transparent-button"
                            onClick = {() => this.props.dispatch(moveToDash())}
                        >
                            <LeftOutlined />
                        </button>

                        <div>Profile</div>
                    </div>
                </div>

                <div className = 'scroll-div' style = {{display:'flex', flexDirection:'column'}}>
                    <div style = {{ position: "relative", alignSelf: "center" }}>
                        <img
                            alt = {COMPANY.NAME}
                            style = {{
                                padding: "20px 0px 20px 0px",
                                width: "250px",
                                margin: "5px"
                            }}
                            src = {IMAGE_RESOURCE.LOGO}
                        />
                    </div>

                    <div style = {{ alignSelf: "center", width: "80%" }}>
                        <div
                            style = {{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style = {{ width: "40%" }}>
                                <div
                                    style = {{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    Name
                                </div>
                            </div>

                            <div style = {{ width: "60%" }}>
                                {user.userName || "GPSFleet"}
                            </div>
                        </div>

                        <div
                            style = {{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style = {{ width: "40%" }}>
                                <div
                                    style = {{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    Email
                                </div>
                            </div>

                            <div style = {{ width: "60%" }}>{user.userEmail}</div>
                        </div>

                        <div
                            style = {{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style = {{ width: "40%" }}>
                                <div
                                    style = {{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    Phone Number
                                </div>
                            </div>

                            <div style = {{ width: "60%" }}>
                                {user.userContact}
                            </div>
                        </div>

                        <div
                            style = {{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}
                        >
                            <div style = {{ width: "40%" }}>Role</div>

                            <div style = {{ width: "60%" }}>
                                {DISPLAY_USER_ROLE(user.userRole)}
                            </div>
                        </div>

                        <div
                            style = {{
                                position: "absolute",

                                right: "20px",
                                bottom: "20px",
                            }}
                        >
                            <Button onClick = {() => signOutAccount(() => this.props.dispatch(user_sign_out()))}>
                                Log Out
                            </Button>
                        </div>
                    </div>

                    <div style = {{minHeight: '60px'}}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(ProfilePage));