import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getAfterSalesLogs = (vids, startTime, endTime) => (
    fetch(`${api}/aftersaleslog/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vids,
            startTime,
            endTime,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const addServiceRecord = (vid, nextAppointment, serviceStatus, actualOdometer, nextMileage) => (
    fetch(`${api}/servicerecord/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vid: vid,
            nextAppointment: nextAppointment,
            serviceStatus: serviceStatus,
            actualOdometer: actualOdometer,
            nextMileage: nextMileage,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const editServiceRecord = (vid, nextAppointment, actualOdometer, nextMileage) => (
    fetch(`${api}/servicerecord/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vid: vid,
            actualOdometer: actualOdometer,
            nextMileage: nextMileage,
            nextAppointment: nextAppointment,
        })
    })
    .then(res => res.json())
    .then(data => data)
)