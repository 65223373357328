import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import Page from "../../../components/Page";
import PrimaryButton from "../../../components/PrimaryButton";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { goBackToPrev } from "../../../navigation/navigationService";
import {
    LOADING_STATUS,
    VEHICLE_MODELS,
    PROCESS_STATUS,
} from "../../../constants";
import {
    Form,
    Alert,
    Input,
    Button,
    Select,
    message,
    DatePicker,
} from "antd";

// Redux Actions
import {
    add_vehicle,
    edit_vehicle,
} from "../../../services/redux/actions/vehicles";

const FormItem = Form.Item;
const { Option } = Select;

class VehicleForm extends Component {
    state = {

    }

    isPassChassisNumberRegex = chassisNumber => {
        const regex = /[^a-zA-Z0-9]/
        return !regex.test(chassisNumber)
    }

    submitForm = () => {
        let {
            vid,
            cid,
            imei,
            isEdit,
            deliveredAt,
            vehiclePlate,
            vehicleModel,
            chassisNumber,
            serviceAdvisor,
        } = this.state;

        try {
            if (!vehicleModel) throw new Error(`Vehicle Model Cannot Be Blank`)
            if (!chassisNumber) throw new Error(`Chassis Number Cannot Be Blank`)
            if (!this.isPassChassisNumberRegex(chassisNumber)) throw new Error(`Invalid Chassis Number (cannot contain special characters)`)

            /**cid, serviceAdvisor, date check */
            if (cid) {
                if (!serviceAdvisor) throw new Error(`Service Advisor Cannot be Blank`)
                if (!deliveredAt) throw new Error(`Actual Delivery Date Cannot be Blank`)
            }

            deliveredAt = deliveredAt && moment(deliveredAt).valueOf();

            const uid = this.props.user.uid;

            if (isEdit) {
                const newVehicle = {
                    ...this.props.vehicles.byVID[vid],
                    dvid: (imei && `GOS100#${imei}`) || '-',
                    activeStatus: 1,
                    vehiclePlate: vehiclePlate,
                    vehicleModel: vehicleModel,
                    chassisNumber: chassisNumber,
                };

                // console.log({
                //     uid,
                //     cid,
                //     serviceAdvisor,
                //     deliveredAt,
                //     newVehicle,
                // })

                this.props.dispatch(edit_vehicle(
                    uid,
                    cid,
                    serviceAdvisor,
                    deliveredAt,
                    newVehicle
                ))

            }
            else {
                const newVehicle = {
                    activeStatus: 1,
                    vehiclePlate: vehiclePlate,
                    vehicleModel: vehicleModel,
                    chassisNumber: chassisNumber,
                };

                const dvid = (imei && `GOS100#${imei}` )|| '-'

                // console.log({
                //     uid,
                //     cid,
                //     serviceAdvisor,
                //     deliveredAt,
                //     newVehicle,
                // })

                this.props.dispatch(add_vehicle(
                    uid,
                    cid,
                    serviceAdvisor,
                    deliveredAt,
                    newVehicle,
                    dvid,
                ));

            }

        }
        catch (err) {
            message.error(err.message || `Error`)
        }
    }

    componentDidMount = () => {
        this.populateForm()
    }

    componentDidUpdate = prevProps => {
        if (
            (JSON.stringify(this.props.vehicles) !== JSON.stringify(prevProps.vehicles))
            || (JSON.stringify(this.props.devices) !== JSON.stringify(prevProps.devices))
        ) {
            this.populateForm()
        }
    }

    populateForm = () => {
        // console.log(this.props.location.pathname.split('/').pop());

        const mode = this.props.location.pathname.split('/').pop()

        switch (mode) {
            case 'EditVehicle':
                this.setState({ isEdit: true })

                const vid = `vhc#${this.props.location.search.replace('?vid=', '')}`;
                const vehicle = this.props.vehicles.byVID[vid];

                // console.log(vid, { vehicle });

                vehicle && this.setState({
                    cid: vehicle.cid,
                    vid: vehicle.vid,
                    imei: vehicle.dvid && vehicle.dvid !== '-' ? vehicle.dvid.replace('GOS100#', '') : undefined,
                    vehiclePlate: vehicle.vehiclePlate ? vehicle.vehiclePlate : "",
                    vehicleModel: vehicle.vehicleModel,
                    chassisNumber: vehicle.chassisNumber,
                    serviceAdvisor: vehicle.serviceAdvisor,
                    deliveredAt: moment(vehicle.deliveredAt),
                })

                break
            case 'AddVehicle':
            default:
                break
        }
    }

    getInfoBanner = () => {
        /**Check if there are any device created */
        const numberOfDevices = Object.keys(this.props.devices.byDVID).length;

        if (numberOfDevices === 0) return null;

        /**Check if there are any unassigned devices */
        const unassignedDevice = Object.values(this.props.devices.byDVID).find(device => {
            return device.assignedStatus !== 1;
        })

        if (!unassignedDevice) {
            return (
                <Alert
                    type='warning'
                    message={`All devices are already assigned. To create new devices, head to Device Management > Add new device`}
                    showIcon
                />
            )
        }

        return null
    }

    render() {
        const {
            vid,
            cid,
            imei,
            isEdit,
            deliveredAt,
            vehiclePlate,
            vehicleModel,
            chassisNumber,
            serviceAdvisor,
        } = this.state;

        const oldVehicle = this.props.vehicles.byVID[vid]

        return (
            <div className="page-container">
                {!isEdit && this.getInfoBanner()}

                <Page title={isEdit ? 'Edit Vehicle' : 'Add New Vehicle'}>
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Vehicle Plate"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            // required = {true}
                            >
                                <Input
                                    placeholder="Enter an vehicle plate (optional)"
                                    value={vehiclePlate}
                                    onChange={e =>
                                        this.setState({ vehiclePlate: e.target.value })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label="Vehicle Model"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required={true}
                            >
                                <Select
                                    placeholder="Select a vehicle model"
                                    value={vehicleModel}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={vehicleModel =>
                                        this.setState({ vehicleModel })
                                    }
                                >
                                    {
                                        Object.values(VEHICLE_MODELS)
                                            .sort((a, b) => Math.sign(a - b))
                                            .map((model) => {
                                                return (
                                                    <Option
                                                        key={model}
                                                        value={model}
                                                    >
                                                        {model}
                                                    </Option>
                                                );
                                            })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Chassis Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required={true}
                            >
                                <Input
                                    placeholder="Enter a chassis number"
                                    value={chassisNumber}
                                    onChange={e =>
                                        this.setState({ chassisNumber: e.target.value })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label="Device"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={<span style={{ color: "red" }}>Paired device cannot be changed once paired if vehicle status is past CREATED</span>}
                            >
                                <Select
                                    placeholder="Select device to pair vehicle to"
                                    value={imei}
                                    disabled={(isEdit && oldVehicle && oldVehicle.dvid !== '-' && oldVehicle.afterSalesStatus !== PROCESS_STATUS.CREATED) ? true : false}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={imei =>
                                        this.setState({ imei })
                                    }
                                >
                                    {
                                        Object.values(this.props.devices.byDVID)
                                            .filter(device => device.assignedStatus !== 1 || device.dvid === `GOS100#${imei}`)
                                            .map(device => {
                                                const imei = device.dvid.replace('GOS100#', '')
                                                return (
                                                    <Option key={imei} value={imei}>{imei}</Option>
                                                );
                                            })
                                    }
                                </Select>
                            </FormItem>

                            {
                                imei && (
                                    <FormItem
                                        label="Customer"
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 15 }}
                                        value={cid}
                                        help={<span style={{ color: "red" }}>Customer cannot be changed once set</span>}
                                    >
                                        <Select
                                            placeholder="Select a customer (Optional)"
                                            disabled={(isEdit && oldVehicle && oldVehicle.cid) ? true : false}
                                            value={this.props.customers.byCID[cid] && this.props.customers.byCID[cid].name}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(cid) => {
                                                this.setState({ cid })

                                                if (!cid) {
                                                    this.setState({
                                                        serviceAdvisor: undefined,
                                                        deliveredAt: undefined
                                                    })
                                                }
                                            }}
                                        >
                                            {
                                                Object.values(this.props.customers.byCID)
                                                    .filter(customer => {
                                                        const isAssignedToVehicle = Object.values(this.props.vehicles.byVID).find(vehicle => vehicle.cid === customer.cid)
                                                        return !isAssignedToVehicle
                                                    })
                                                    .sort((a, b) => {
                                                        a = a.name
                                                        b = b.name
                                                        return Math.sign(a - b)
                                                    })
                                                    .map(customer =>
                                                        <Option key={customer.cid} value={customer.cid}>
                                                            {customer.name}
                                                        </Option>
                                                    )
                                            }
                                        </Select>
                                    </FormItem>
                                )
                            }

                            {/* serviceAdvisor and serviceDate is only required if vehicle is assigned to a customer */}
                            {
                                cid && (
                                    <div>
                                        <FormItem
                                            label="Service Advisor"
                                            labelCol={{ span: 7 }}
                                            wrapperCol={{ span: 15 }}
                                            required={true}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a service advisor"
                                                value={serviceAdvisor}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(serviceAdvisor) => {
                                                    this.setState({ serviceAdvisor })
                                                }}
                                            >
                                                {
                                                    Object.values(this.props.staff.byUID)
                                                        .sort((a, b) => {
                                                            a = a.userName
                                                            b = b.userName
                                                            return Math.sign(a - b)
                                                        })
                                                        .map(staff => {
                                                            return (
                                                                <Option key={staff.uid} value={staff.uid}>{staff.userName}</Option>
                                                            );
                                                        })
                                                }
                                            </Select>
                                        </FormItem>

                                        <FormItem
                                            label="Delivery Date"
                                            labelCol={{ span: 7 }}
                                            wrapperCol={{ span: 15 }}
                                            required={true}
                                        >
                                            <DatePicker
                                                style={{ width: 200 }}
                                                showTime
                                                value={deliveredAt}
                                                onChange={deliveredAt => {
                                                    this.setState({ deliveredAt })
                                                }}
                                            />
                                        </FormItem>
                                    </div>
                                )
                            }

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading={this.props.vehicles.loadingStatus === LOADING_STATUS.LOADING}
                                    onClick={this.submitForm}
                                >
                                    {isEdit ? 'Save' : 'Add'}
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    staff: state.staff,
    devices: state.devices,
    vehicles: state.vehicles,
    customers: state.customers,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(VehicleForm));