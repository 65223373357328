import React, { Component } from "react";

/**
 * Allows component to be imported in an asynchronous way. Components imported in this way will be compiled into
 * a seperate chunk during build, which loads only when required.
 * @param {object} importComponent Component to import
 */
export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        state = {
            component: null
        };

        async componentDidMount() {
            const { default: component } = await importComponent();
            // console.log("Component loaded", component)
            this.setState({ component });
        }

        render() {
            const C = this.state.component;

            return C && <C {...this.props} />;
        }
    }

    return AsyncComponent;
}