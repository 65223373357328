import React from "react";
import { connect } from "react-redux";
import { 
  Route,
  Switch,
} from "react-router";

import asyncComponent from "../components/AsyncComponent";

// Pages
const AsyncEventLogPage = asyncComponent(() => import("../pages/EventLog"));
const AsyncTransitLogPage = asyncComponent(() => import("../pages/RoutePlayback"));

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path = "/EventLog" component = {AsyncEventLogPage}/>
      <Route exact path = "/RoutePlayback" component = {AsyncTransitLogPage}/>
    </Switch>
  )
}
export default connect(null)(AppRoutes);
