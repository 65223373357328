import * as ActionTypes from "../action-types/staff";

const defaultState = {
    byUID: {},
    loadingStatus: undefined
}

export const staff = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_STAFF: {
            newState.byUID = JSON.parse(JSON.stringify(defaultState.byUID)); // Clear newState byUID

            action.staff.forEach((currStaff) => newState.byUID[currStaff.uid] = currStaff);

            return newState;
        }

        case ActionTypes.SET_STAFF_LOADING_STATUS:
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return newState;
        }
    }
}