import React, { useState } from 'react';
import { useDispatch } from 'react-redux'

import TransitMarkers from "./components/TransitMarkers";
import DeviceLogPolylines from "./components/DeviceLogPolylines";
import NEWGoogleMapsLoadScript from "./components/NEWGoogleMapsLoadScript";

import { GoogleMap } from '@react-google-maps/api';
import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";

// Redux Actions
import { set_map_action } from '../../services/redux/actions/mapControl';

import "./gmaps.css";

const MapTransit = () => {
    const dispatch = useDispatch();

    const [mapRef, setMapRef] = useState(null);

    const getMapCenterAndZoom = () => {
        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    return (
        <GoogleMap
            id = 'example-map'
            zoom = {getMapCenterAndZoom().zoom}
            center = {getMapCenterAndZoom().center}
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => dispatch(set_map_action(0))}
        >
            <TransitMarkers mapRef = {mapRef}/>
            <DeviceLogPolylines mapRef = {mapRef}/>
        </GoogleMap>
    )
}

const MapComponent = (props) => NEWGoogleMapsLoadScript(MapTransit, props);
export default MapComponent;