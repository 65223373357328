import React from "react";

import PropTypes from "prop-types";
import {
  // Menu,
  // Icon,
  Tooltip,
} from 'antd'
import {
  COMPANY,
  IMAGE_RESOURCE
} from "../../../constants";

import "./Logo.css";

const Logo = ({ onClick, selectedKeys }) => {
  return (
    <div
      className="sidebar-logo-container"
      key="/"
      onClick={onClick}
      style={{
        background: selectedKeys[0] === "/" ? "rgba(0, 0, 0, 0.25)" : ""
      }}
    >
      <Tooltip
        title={`Service Reminder Dashboard`}
        placement={`right`}
      >
        <img
          src={IMAGE_RESOURCE.SIDEBARLOGO}
          alt={COMPANY.NAME}
          className="sidebar-logo"
        />

      </Tooltip>
    </div>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func
};

export default Logo;