import * as ActionTypes from "../action-types/playback";

const defaultState = {
    isPlaying: false,
    playbackIndex: 0,
}

export const playback = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.SET_PLAYBACK_INDEX: {
            newState.playbackIndex = action.newIndex;

            return newState;
        }

        case ActionTypes.SET_PLAYBACK_BOOL_TO_TRUE: {
            newState.isPlaying = true;

            return newState;
        }

        case ActionTypes.SET_PLAYBACK_BOOL_TO_FALSE: {
            newState.isPlaying = false;

            return newState;
        }

        case ActionTypes.RESET_PLAYBACK: {
            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}