import * as ActionTypes from "../action-types/mapControl";

const defaultState = {
    // toggleControl: {
    //     // info window
    //     showAllVehiclesInfoWindow: true,
    //     showAllGeofencesInfoWindow: true,

    //     // all geofence stuffs
    //     showAllGeofence: true,

    //     // not used for now
    //     showLiveTraffic: false,
    //     showVehicleTrail: true,
    // },

    selectedEvID: "",
    selectedTrID: "",
    selectedGeoID: "",

    // 0 = Default
    // 1 = Panning
    currAction: "",
};

export const mapControl = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case ActionTypes.SET_MAP_STATUS: {
            newState.currAction = action.newAction;

            return newState;
        }

        case ActionTypes.SET_MAP_SELECTED_EVID: {
            newState.selectedEvID = action.newSelectedEvID;

            return newState;
        }
        
        case ActionTypes.CLEAR_MAP_SELECTED_EVID: {
            newState.selectedEvID = defaultState.selectedEvID;
            newState.selectedTrID = defaultState.selectedTrID;

            return newState;
        }
        
        case ActionTypes.SET_MAP_SELECTED_TRID: {
            newState.selectedTrID = action.newSelectedTrID;

            return newState;
        }
        
        case ActionTypes.SET_MAP_SELECTED_GEOID: {
            newState.selectedGeoID = action.newSelectedGeoID;

            return newState;
        }
        
        case ActionTypes.CLEAR_MAP_SELECTED_GEOID: {
            newState.selectedGeoID = defaultState.selectedGeoID;

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Map Control Store:", defaultState);
    
            return defaultState;
        }

        default: {
            return state;
        }
    }
}