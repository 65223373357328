import * as ActionTypes from "../action-types";

const defaultState = {
    byVID: {},
    bySVRID: {}
}

export const serviceVehicleRecords = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_SERVICE_VEHICLE_RECORDS: {
            newState.byVID = JSON.parse(JSON.stringify(defaultState.byVID)); // Clear newState bySRID
            newState.bySVRID = JSON.parse(JSON.stringify(defaultState.bySVRID)); // Clear newState bySVRID
            
            action.serviceVehicleRecords.forEach((svr) => {
                
                newState.bySVRID[svr.svrid] = svr;
                
                if (!newState.byVID[svr.vid]) {
                    newState.byVID[svr.vid] = [];
                }

                newState.byVID[svr.vid].push(svr.svrid);
            });

            return newState;
        }

        case ActionTypes.ADD_SERVICE_VEHICLE_RECORD: {
            const svr = action.serviceVehicleRecord

            newState.bySVRID[svr.svrid] = svr

            if (!newState.byVID[svr.vid]) {
                newState.byVID[svr.vid] = [];
            }

            newState.byVID[svr.vid].push(svr.svrid)

            return newState;
        }
        
        default: {
            return newState;
        }
    }
}