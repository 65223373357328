import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}


export const getGeofences = (gtidList) => (
    fetch(`${api}/geofence/template/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "gtids" : gtidList,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const addGeofence = (uid, newGeofence) => (
    fetch(`${api}/geofence/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "geofence": newGeofence,
        })
    })  
    .then(res => res.json())
)

export const editGeofence = (editedGeofence) => (
    fetch(`${api}/geofence/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "geofence": editedGeofence,
        })
    })  
    .then(res => res.json())
)