import * as API from "../../api/customers";
import * as ActionTypes from "../action-types/customers";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    set_vehicle_cid,
    // clear_vehicle_cid
} from './vehicles';
// import {
//     setLoadingSubmit,
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'

// Exported to user actions
export const get_customers = () => (dispatch) => {
    dispatch(set_customers_loading_status(LOADING_STATUS.LOADING))

    API.getCustomers()
        .then((data) => {

            if (data.status !== 200) {
                message.error('Failed To Get Customers: ' + data.message);
                dispatch(set_customers_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                dispatch(get_customers_success(data.customers));
                dispatch(set_customers_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const get_customers_success = (customers) => {
    return {
        type: ActionTypes.GET_CUSTOMERS,
        customers: customers,
    };
}

export const add_customer = (newCustomer, serviceAdvisor, selectedVID = null) => (dispatch) => {
    dispatch(set_customers_loading_status(LOADING_STATUS.LOADING))

    API.addCustomer(newCustomer, serviceAdvisor, selectedVID)
        .then((data) => {
            // console.log("add_customer data:", data);

            if (data.status !== 200) {
                message.error('Failed To Add Customers: ' + data.message);
                dispatch(set_customers_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Added Customer.');

                if (selectedVID) {
                    dispatch(set_vehicle_cid(selectedVID, data.customer.cid));
                }

                dispatch(add_customers_success(data.customer));
                dispatch(goBackToPrev());
                dispatch(set_customers_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const add_customers_success = (newCustomer) => {
    return {
        type: ActionTypes.ADD_CUSTOMER,
        newCustomer: newCustomer,
    };
}

export const edit_customer = (editedCustomer, serviceAdvisor, selectedVID = null) => (dispatch) => {
    dispatch(set_customers_loading_status(LOADING_STATUS.LOADING))

    API.editCustomer(editedCustomer, serviceAdvisor, selectedVID)
        .then((data) => {
            // console.log("edit_customer data:", data);

            if (data.status !== 200) {
                message.error('Failed To Edit Customers: ' + data.message);
                dispatch(set_customers_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Edited Customer.');

                if (data.customer.vid) {
                    dispatch(set_vehicle_cid(data.customer.vid, data.customer.cid));
                }

                dispatch(edit_customers_success(data.customer));
                dispatch(goBackToPrev());
                dispatch(set_customers_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const edit_customers_success = (editedCustomer) => {
    return {
        type: ActionTypes.EDIT_CUSTOMER,
        editedCustomer: editedCustomer,
    };
}

export const set_selected_cid = (selectedCID) => {
    return {
        type: ActionTypes.SET_SELECTED_CID,
        selectedCID: selectedCID,
    };
}

export const clear_selected_cid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_CID,
    };
}
export const set_customers_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_CUSTOMER_LOADING_STATUS,
        loadingStatus
    }
}

