import React from "react";

const SvgSignalCellular4Bar = props => (
    <svg width={24} height={24} {...props}>
        <path d="M2 22h20V2z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export default SvgSignalCellular4Bar;
