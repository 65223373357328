import React, { useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

import ParseTime from "../../../utils/parseTime";

import { message } from "antd";
import { TRANSIT_COLORS } from "../../../constants";
import {
    Marker,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import {
    set_map_action,
    set_map_selected_trid,
    clear_map_selected_trid,
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const TransitMarkers = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    const mapControl = useSelector(state => state.mapControl);
    const transitLogs = useSelector(state => state.transitLogs);

    const getTransitMarker = (currTrID) => {
        const currTransit = transitLogs.byTrID[currTrID];

        // console.log("Current Transit:", currTransit);

        if (currTransit) {
            const currCoor = currTransit.startLocation ? 
                {
                    lat: parseFloat(currTransit.startLocation.lat),
                    lng: parseFloat(currTransit.startLocation.lng)
                } : 
                {
                    lat: 0,
                    lng: 0
                };

            return (
                currTransit.startLocation
                && currTransit.startLocation.lat
                && currTransit.startLocation.lng &&
                    <Marker
                        key = {currTrID}
                        icon = {{
                            fillOpacity: 1,
                            fillColor: 'white',

                            scale: 7,
                            strokeWeight: 3,
                            strokeColor: TRANSIT_COLORS[currTransit.tripStatus],

                            path: window.google.maps.SymbolPath.CIRCLE,
                        }}
                        position = {currCoor}
                        onClick = {() => {
                            // console.log('Transit Marker Clicked');

                            dispatch(set_map_action(1));
                            dispatch(set_map_selected_trid(currTrID));
                        }}
                    >
                        {
                            mapControl.selectedTrID === currTrID &&
                                <OverlayView
                                    options = {{ maxWidth: 250 }}
                                    position = {currCoor}
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 15,
                                    })}
                                    style = {{
                                        backgroundColor: "white",
                                    }}
                                    // onCloseClick = {() => dispatch(clear_map_selected_trid())}
                                >
                                    <div className = "geofenceMarkerOverlayView">
                                        <h3>{ParseTime(currTransit.startTime)}</h3>

                                        <h4
                                            style = {{
                                                fontWeight: 'bold',
                                                color: TRANSIT_COLORS[currTransit.tripStatus],
                                            }}
                                        >
                                            <b>{currTransit.tripStatus}</b>
                                        </h4>
                                    </div>
                                </OverlayView>
                        }
                    </Marker>
            );
        }
    }

    // ComponentDidUpdate()
    useEffect(() => {
        if (mapControl.selectedTrID && transitLogs.byTrID[mapControl.selectedTrID] && mapControl.currAction === 1) {
            const selectedTransit = transitLogs.byTrID[mapControl.selectedTrID];

            if (selectedTransit.startLocation && selectedTransit.startLocation.lat && selectedTransit.startLocation.lng) {    
                if (mapRef) {
                    mapRef.setZoom(20);
                    mapRef.panTo({
                        lat: parseFloat(selectedTransit.startLocation.lat),
                        lng: parseFloat(selectedTransit.startLocation.lng),
                    });

                    // Do not set map action to 0 here or panning won't finish
                }
            }
            else {
                message.error("No location found for this transit point");

                dispatch(clear_map_selected_trid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }
    })

    return (
        Object.keys(transitLogs.byTrID).map((currTrID) => {
            return getTransitMarker(currTrID);
        })
    )
}

export default TransitMarkers;