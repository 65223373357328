import 
    React, 
    {
        useState
    }    
from "react";
import 
    { 
        useSelector     
    } 
from "react-redux";

import moment from "moment";

import ServiceCycleTable from "./ServiceCycleTable"
import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import {
    Tabs,
    Form,
    Button,
    DatePicker,
    Statistic,
    Row, 
    Col
} from "antd";

import {
    AFTERSALES_WORKFLOW_STATUS
} from "../../constants/afterSalesWorkflow";
import {
    SERVICE_REMINDER_STATUSES,
} from "../../constants";


const ReportPage = (props) => {


    const vehicles = useSelector(state => state.vehicles)
    const afterSalesLogs = useSelector(state => state.afterSalesLogs)
    const serviceReminders = useSelector(state => state.serviceReminders)
    const serviceConfigurations = useSelector(state => state.serviceConfigurations)
    const afterSalesWorkflows = useSelector(state => state.afterSalesWorkflows)

    const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"))
    const [serviceCycleReportData, setServiceCycleReportData] = useState([])
    const [totalVehicles, setTotalVehicles] = useState(0)
    const [totalDeliveredVehicles, setTotalDeliveredVehicles] = useState(0)

    const onSubmit = (selectedMonth) => {

        const filteredVehicles = Object.values(vehicles.byVID).filter(vhc => vhc.aSProfile.createdAt >= selectedMonth && vhc.aSProfile.createdAt <= moment(selectedMonth).endOf("month").utc().valueOf())
        const activatedVehicle = filteredVehicles.filter(vhc => vhc.aSProfile.activatedAt)

        const param_totalVehicles = filteredVehicles.length
        const param_totalDeliveredVehicles = activatedVehicle.length

        let scData = {};
        let calSCData = {};

        Object.keys(serviceConfigurations.byServiceCycle).map(sc => {
            scData[sc] = []

            if(!calSCData.hasOwnProperty(sc)) calSCData[sc] = {}
            if(!calSCData[sc].hasOwnProperty('inCycle')) calSCData[sc]['inCycle'] = {};
            if(!calSCData[sc].hasOwnProperty('outCycle')) calSCData[sc]['outCycle'] = {};

            afterSalesWorkflows.byServiceCycle[sc].map(asw => {

                calSCData[sc]['inCycle'][asw.afterSalesStatus.split('_')[1]] = {
                    totalStateVehicles: 0,
                    completeNumber: 0,
                    overallPercentage: 0,
                    totalVehicles: param_totalVehicles,
                    totalDeliveredVehicles: param_totalDeliveredVehicles
                }
                return null;
            })
            
            calSCData[sc]['outCycle'][AFTERSALES_WORKFLOW_STATUS[1]] = {
                    totalStateVehicles: 0,
                    overallPercentage: 0,
                    totalVehicles: param_totalVehicles,
                    totalDeliveredVehicles: param_totalDeliveredVehicles
            }
            calSCData[sc]['outCycle'][AFTERSALES_WORKFLOW_STATUS[2]] = {
                    totalStateVehicles: 0,
                    overallPercentage: 0,
                    totalVehicles: param_totalVehicles,
                    totalDeliveredVehicles: param_totalDeliveredVehicles
            }
            return null
        })

        filteredVehicles.map(vhc => {

            serviceReminders.byVID[vhc.vid] && serviceReminders.byVID[vhc.vid].map(sr => {
                scData[serviceReminders.bySRID[sr].serviceType.split('S')[1]].push(serviceReminders.bySRID[sr])
                return null
            })

            afterSalesLogs.byVID[vhc.vid].map(asl => {
                if(asl.afterSalesStatus.split('_')[1])
                    if(AFTERSALES_WORKFLOW_STATUS.includes(asl.afterSalesStatus.split('_')[1]))
                        scData[asl.afterSalesStatus.split('_')[0].split('S')[1]].push(asl)
                return null
            })

            return null;
        })

        Object.keys(scData).map(sc => {
            scData[sc].map(scd => {

                if(AFTERSALES_WORKFLOW_STATUS.includes(scd.afterSalesStatus.split('_')[1])) {
                    calSCData[sc][`outCycle`][scd.afterSalesStatus.split('_')[1]].totalStateVehicles = calSCData[sc][`outCycle`][scd.afterSalesStatus.split('_')[1]].totalStateVehicles + 1;
                    calSCData[sc][`outCycle`][scd.afterSalesStatus.split('_')[1]].overallPercentage = calSCData[sc][`outCycle`][scd.afterSalesStatus.split('_')[1]].totalStateVehicles/param_totalVehicles*100;

                } else {
                    calSCData[sc]['inCycle'][scd.afterSalesStatus.split('_')[1]].totalStateVehicles = calSCData[sc]['inCycle'][scd.afterSalesStatus.split('_')[1]].totalStateVehicles + 1

                    if(scd.reminderStatus === Object.keys(SERVICE_REMINDER_STATUSES)[0]) {
                        calSCData[sc]['inCycle'][scd.afterSalesStatus.split('_')[1]].completeNumber =  calSCData[sc]['inCycle'][scd.afterSalesStatus.split('_')[1]].completeNumber + 1;
                    }

                    calSCData[sc][`inCycle`][scd.afterSalesStatus.split('_')[1]].completedPercentage = calSCData[sc][`inCycle`][scd.afterSalesStatus.split('_')[1]].completeNumber/calSCData[sc][`inCycle`][scd.afterSalesStatus.split('_')[1]].totalStateVehicles*100;
                    calSCData[sc][`inCycle`][scd.afterSalesStatus.split('_')[1]].overallPercentage = calSCData[sc][`inCycle`][scd.afterSalesStatus.split('_')[1]].totalStateVehicles/param_totalVehicles*100;
                }

                return null;
            })

            return null;
        })

        const SCTempData = JSON.parse(JSON.stringify(calSCData))

        Object.keys(calSCData).map(sc => {
            SCTempData[sc][`outCycle`] = Object.keys(calSCData[sc][`outCycle`]).map(as => ({key: as, ...calSCData[sc][`outCycle`][as]}))
            SCTempData[sc][`inCycle`] =  Object.keys(calSCData[sc][`inCycle`]).map(as => ({key: as, ...calSCData[sc][`inCycle`][as]}))
            return null;
        })

        setServiceCycleReportData(SCTempData)
        setTotalVehicles(param_totalVehicles) 
        setTotalDeliveredVehicles(param_totalDeliveredVehicles)
    }

    return (
        <Page title="Report">
            <Form layout='inline' style={{ marginBottom: 5 }}>
                <Form.Item>
                    <span>Selected Month: </span>

                    <DatePicker
                        // showTime
                        picker="month"
                        defaultValue={selectedMonth}
                        onChange={(value) => setSelectedMonth(value)}
                        style={{ width: 200 }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={!(Object.keys(vehicles.byVID).length && Object.keys(afterSalesLogs.byVID).length && Object.keys(serviceReminders.bySRID).length)}
                        disabled={!(Object.keys(vehicles.byVID).length && Object.keys(afterSalesLogs.byVID).length && Object.keys(serviceReminders.bySRID).length && selectedMonth)}
                        onClick={() => {
                            onSubmit(selectedMonth.startOf('Month').utc().valueOf())
                        }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <Row gutter={16}>
                <Col span={12}>
                    <Statistic title="Total Registered Vehicle" value={totalVehicles} />
                </Col>
                <Col span={12}>
                    <Statistic title="Total Delivered Vehicle" value={totalDeliveredVehicles} />
                </Col>
            </Row>

            <Tabs
                type="card"
                defaultActiveKey={serviceConfigurations.byServiceCycle[0]}
                style={{ marginTop: 20 }}
            >
                {
                    Object.keys(serviceConfigurations.byServiceCycle).map(sc => (
                        <Tabs.TabPane tab={`S${sc}`} key={sc}>
                            <ServiceCycleTable data={(Object.keys(serviceCycleReportData) && serviceCycleReportData[sc]) || []} />
                        </Tabs.TabPane>
                    ))
                }
            </Tabs>
        </Page>
    )
}

export default showSecondarySidebar(false)(ReportPage)
