import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useDispatch,
    useSelector
 } from "react-redux";

import Page from "../../components/Page";
import parseTime from "../../utils/parseTime";
import PrimaryButton from '../../components/PrimaryButton';
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { LOADING_STATUS } from '../../constants';
import { moveToPage } from "../../navigation/navigationService";
import { tableColumnSearchProps } from '../../components/TableColumnSearchProps';
import {
    Form,
    Table,
    Button,
    Tooltip,
    Skeleton,
    DatePicker,
} from "antd";

import {
    EditOutlined
} from '@ant-design/icons'

// Redux Actions
import {
    set_selected_cid,
    clear_selected_cid,
} from "../../services/redux/actions/customers";

const CustomerManagement = (props) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user)
    const staff = useSelector(state => state.staff)
    const vehicles = useSelector(state => state.vehicles)
    const customers = useSelector(state => state.customers)

    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()

    // const [currPopUp, setCurrPopUp] = useState('')
    // const [selectedVID, setSelectedVID] = useState('')
    const [selectedVIDList, setSelectedVIDList] = useState([])

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    // const onCloseModal = () => {

    //     setCurrPopUp('')
    //     setSelectedVID('')
    //     setSelectedVIDList([])
    // }

    const setupDataSource = () => {

        const dataSources =
            Object.values(customers.byCID)
                .filter(customer => {
                    if (!startTime && endTime) return startTime.valueOf() <= customer.createdAt && endTime.valueOf() >= customer.createdAt
                    if (startTime) return startTime.valueOf() <= customer.createdAt
                    if (endTime) return endTime.valueOf() >= customer.createdAt
                    return true
                })
                .map((customer) => {
                    const vehicle = Object.values(vehicles.byVID).find(vehicle => vehicle.cid === customer.cid);
                    const serviceAdvisor = vehicle && staff.byUID[vehicle.serviceAdvisor]

                    return {
                        key: customer.cid,
                        customerName: customer.name || "-",
                        serviceAdvisor: (serviceAdvisor && serviceAdvisor.userName) || '-',
                        gender: customer.gender || "-",
                        age: customer.age || "-",
                        contactNumber: customer.contactNumber || "-",
                        email: customer.email || "-",
                        vehiclePlate: (vehicle && vehicle.vehiclePlate )|| "-",
                        createdAt: customer.createdAt,
                    };
                })

        const column = [
            {
                title: "Customer Name",
                dataIndex: "customerName",
                // fixed: "left",
                ...tableColumnSearchProps("customerName", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "M/F",
                dataIndex: "gender",
                filters: [`Male`, `Female`].map(gender => ({ text: gender, value: gender })),
                onFilter: (value, record) => value && record.gender.indexOf(value) === 0,
            },

            {
                title: "Age",
                dataIndex: "age",
                sorter: (a, b) => a.age - b.age,
                ...tableColumnSearchProps("age", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Contact Number",
                dataIndex: "contactNumber",
                ...tableColumnSearchProps("contactNumber", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Email",
                dataIndex: "email",
                ...tableColumnSearchProps("email", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
            },
            {
                title: "Service Advisor",
                dataIndex: "serviceAdvisor",
                ...tableColumnSearchProps("serviceAdvisor", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "Created At",
                dataIndex: "createdAt",
                render: createdAt => parseTime(createdAt),
                sorter: (a, b) => a.createdAt - b.createdAt,
                defaultSortOrder: 'descend',
            },

            {
                title: "Actions",
                fixed: "right",
                render: (rowData) => (
                    <Tooltip title={"Edit"} style={{ display: "flex", flexDirection: "row" }}>
                        <button className="transparent-button">
                            <EditOutlined 
                                onClick={() => {
                                    dispatch(set_selected_cid(rowData.key));
                                    const queryParams = `?cid=${rowData.key.replace('cid#', '')}`
                                    dispatch(moveToPage(`/CustomerManagement/EditCustomer${queryParams}`));
                                }}
                            />
                        </button>
                    </Tooltip>
                )
            },
        ]

        setDataSource(dataSources)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevUserProps = usePrevious(user);
    const prevVehicleProps = usePrevious(vehicles);
    const prevStaffProps = usePrevious(staff);
    const prevCustomerProps = usePrevious(customers);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            mounted.current = true;
    
            setupDataSource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevUserProps) !== JSON.stringify(user)
                ||
                JSON.stringify(prevVehicleProps) !== JSON.stringify(vehicles)
                ||
                JSON.stringify(prevStaffProps) !== JSON.stringify(staff)
                ||
                JSON.stringify(prevCustomerProps) !== JSON.stringify(customers)
            ) {
                setupDataSource();
            }
        }
    });

    // With componentDidUnmount()
    useEffect(() => {
        return () => {
            dispatch(clear_selected_cid());
        }
    }, [dispatch])

    const rowSelection = {
        selectedRowKeys: selectedVIDList,
        onChange: (value) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`);
            setSelectedVIDList(value)
        },
    };

    const excelname =
            startTime && endTime ?
                `Customers ${startTime.format('DD-MM-YY')} - ${endTime.format('DD-MM-YY')}` :
                `Customers`;
    
    return (
        <div className="page-container">
            <Page title="Customer Management">
                <Form layout='inline' style={{ marginBottom: 5 }}>
                    <Form.Item>
                        <span>Start time: </span>

                        <DatePicker
                            style={{ width: 200 }}
                            showTime
                            defaultValue={startTime}
                            onChange={(value) => setStartTime(value)}
                        />

                        <span style={{ marginLeft: '10px' }}>End time: </span>

                        <DatePicker
                            style={{ width: 200 }}
                            showTime
                            defaultValue={endTime}
                            onChange={(value) => setEndTime(value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            disabled={!(Object.keys(vehicles.byVID).length > 0 && (startTime &&endTime))}
                            onClick={() => setupDataSource()}
                            style={{ marginLeft: '15px' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        padding: 5,
                        marginBottom: 5,
                    }}
                >
                    <PrimaryButton onClick={() => props.dispatch(moveToPage("CustomerManagement/AddCustomer"))}>
                        + New Customer
                    </PrimaryButton>

                    <ExportExcelButton
                        disabled={dataSource.length === 0}
                        filename={excelname}
                        sheetData={dataSource}
                        sheetName={`Vehicles`}
                        sheetRow={
                            columns
                                .filter(col => col.title !== `Actions`)
                                .map(col => ({
                                    label: col.title,
                                    formatter: value => 
                                        col.dataIndex === "createdAt" ? 
                                            parseTime(value[col.dataIndex]) :
                                            value[col.dataIndex]
                                }))
                        }
                    />
                </div>

                {
                    (
                        user.loadingStatus === LOADING_STATUS.LOADING
                        || customers.loadingStatus === LOADING_STATUS.LOADING
                        || vehicles.loadingStatus === LOADING_STATUS.LOADING
                    ) ?
                        <Skeleton active /> :
                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={true}
                            scroll={{
                                x: columns && columns.length * 150,
                                y: window.innerHeight
                            }}
                        />
                }
            </Page>
        </div>
    )
}

export default showSecondarySidebar(false)(CustomerManagement);