import * as ActionTypes from "../action-types/deviceLogs";

const defaultState = {
    byDVID: {},
    selectedDVID: undefined,
    loadingStatus: undefined,
}

export const deviceLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICE_LOG: {
            // newState.byDVID = defaultState.byDVID; // Reset byDVID property\
            newState.byDVID = {}

            // newState.byDVID = JSON.parse(JSON.stringify(defaultState.byDVID)); // Clear newState byDVID

            // console.log("New Device Logs", action.newDeviceLogs);

            action.newDeviceLogs.forEach((currDeviceLog) => {
                if (!newState.byDVID[currDeviceLog.dvid]) {
                    newState.byDVID[currDeviceLog.dvid] = [];
                }

                newState.byDVID[currDeviceLog.dvid].push(currDeviceLog);
            })

            return newState;
        }

        case ActionTypes.GET_DEVICE_LOG_DEBUG: {
            newState.byDVID = JSON.parse(JSON.stringify(defaultState.byDVID)); // Clear newState byDVID

            // console.log("New Debug Device Logs", action.newDeviceLogs);

            action.newDeviceLogs.forEach((currDeviceLog) => {
                if (!newState.byDVID[currDeviceLog.dvid]) {
                    newState.byDVID[currDeviceLog.dvid] = [];
                }

                newState.byDVID[currDeviceLog.dvid].push(currDeviceLog);
            })

            return newState;
        }

        case ActionTypes.CLEAR_DEVICE_LOG: {
            // newState.byDVID = defaultState.byDVID;
            newState.byDVID = {}
            return newState;
        }

        case ActionTypes.CLEAR_DEVICE_LOG_DEBUG: {
            newState.byDVID = defaultState.byDVID;

            return newState;
        }

        case ActionTypes.SET_DEVICE_LOG_LOADING_STATUS: {
            newState.loadingStatus = action.loadingStatus;

            return newState
        }

        case ActionTypes.SET_DEVICE_LOG_SELECTED_DVID: {
            newState.selectedDVID = action.selectedDVID;

            return newState;
        }

        case ActionTypes.CLEAR_DEVICE_LOG_SELECTED_DVID: {
            newState.selectedDVID = defaultState.selectedDVID;

            return newState;
        }

        default: {
            return state;
        }
    }
};
