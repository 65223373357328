import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const PrimaryButton = props => {
  const { color, children, onClick, ...otherProps } = props;

  let reduxlessProps = {...otherProps};
  delete reduxlessProps.dispatch

  return (
    <Button
      type="primary"
      onClick={props.onClick}
      style={{
        marginLeft: "10px",
        backgroundColor: !otherProps.disabled && color,
        borderColor: !otherProps.disabled && color
      }}
      {...reduxlessProps}
    >
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default (PrimaryButton);
