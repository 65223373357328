import React, { useEffect } from 'react';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';

import moment from 'moment';
import ParseTime from "../../../utils/parseTime";

// import { moveToPage } from "../../../../navigation/navigationService";
import {
    VEHICLE_COLOR,
    VEHICLE_STATUS,
} from "../../../constants";
import {
    Col,
    Row,
    message,
} from 'antd';
import {
    Marker,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
// import { set_drawn_geofence } from "../../../services/redux/actions/mapDrawControl";
// import {
//     set_selected_device,
//     clear_selected_device,
// } from "../../../services/redux/actions/devices.js";
import {
    set_map_action,
    // add_enabled_vehicle_pop_up,
    // clear_enabled_vehicle_pop_ups,
    // remove_enabled_vehicle_pop_up,
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const VehicleMarkers = (props) => {
    const {
        mapRef,
        cusStyle,
    } = props;

    const devices = useSelector(state => state.devices);
    const vehicles = useSelector(state => state.vehicles);
    const geofences = useSelector(state => state.geofences);
    const mapControl = useSelector(state => state.mapControl);
    // const mapDrawControl = useSelector(state => state.mapDrawControl);

    const dispatch = useDispatch()

    // ComponentDidUpdate
    useEffect(() => {

        let location, zoom;
        
        /**
         * Pan to selected device
         */
        if (
            devices.selectedId
            && devices.byDVID[devices.selectedId]
            && devices.byDVID[devices.selectedId].location
            && devices.byDVID[devices.selectedId].location.lat
            && devices.byDVID[devices.selectedId].location.lng
            && mapControl.currAction === devices.selectedId)
        {
            zoom = 20;

            location = {
                lat: parseFloat(devices.byDVID[devices.selectedId].location.lat),
                lng: parseFloat(devices.byDVID[devices.selectedId].location.lng),
            }

            if (mapRef) {
                mapRef.panTo(location);
                mapRef.setZoom(zoom);
            }
        }
        else if (devices.selectedId) {
            if (!devices.byDVID[devices.selectedId]) {
                message.error("Selected vehicle's device is not found");
            }

            if (!devices.byDVID[devices.selectedId].location
                || !devices.byDVID[devices.selectedId].location.lat
                || !devices.byDVID[devices.selectedId].location.lng) 
            {
                message.error("Selected vehicle's location is not found");
            }
        }
    })

    // Initial mount of component - ComponentDidMount()
    useEffect(() => {     
        // dispatch(clear_enabled_vehicle_pop_ups())
        // dispatch(clear_selected_device())
    }, 
        [dispatch]
    )


    const getVehicleTransitColor = (dvid) => {
        const vid = Object.keys(vehicles.byVID).filter((vid) => vehicles.byVID[vid].dvid === dvid);

        if (vid.length > 0) {
            return VEHICLE_COLOR[vehicles.byVID[vid[0]].transitStatus]
        }
        else return VEHICLE_COLOR[null];
    }

    const timeDiff = (startTime, endTime) => {
        const duration = durationFormat(moment(Number(endTime)).utc().diff(moment(startTime).utc()));

        return duration;
    }

    const durationFormat = (duration) => {
        const momentDuration = moment.duration(duration);

        const days = parseInt(momentDuration.asDays(), 10);
        const hrs = momentDuration.hours();
        const mins = momentDuration.minutes();
        const secs = momentDuration.seconds();

        return (
            days ?
                `${days} days ${hrs} hrs ${mins} mins` :
                hrs ?
                    `${hrs} hrs ${mins} mins` :
                    mins ?
                        `${mins} mins ${secs} secs` :
                        `${secs} secs`
        )
    }

    const getVehicleMarker = (thisVehicle, thisDevice) => {
        if (
            thisDevice &&
            thisVehicle &&
            thisDevice.location
        ) {
            const vid = thisVehicle.vid;

            const inGeofenceGeoid = Object.keys(thisVehicle.geofenceProfile).find(geoid => {
                return thisVehicle.geofenceProfile[geoid] === 1
            })
    
            let inGeofence;
    
            if (inGeofenceGeoid) {
                inGeofence = geofences.byGeoID[inGeofenceGeoid];
            }
    
            const stylePOI = cusStyle && cusStyle.hasOwnProperty('POI') ? cusStyle.POI : {};
            const stylePOIDescription = cusStyle && cusStyle.hasOwnProperty('POI_Description') ? cusStyle.POI_Description : {};

            return (
                <Marker
                    key = {vid}
                    icon = {{
                        path: window.google && window.google.maps && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 5,
                        strokeColor: getVehicleTransitColor(thisDevice.dvid),
                        rotation: Number(thisDevice.heading),
                        fillOpacity: 1,

                        // 24 is sprite width and 28 is sprite height. Make sure to match future anchors accordingly
                        // anchor: new window.google.maps.Point(24 / 2, 28 / 2),
                    }}
                    position = {{
                        lat: parseFloat(thisDevice.location.lat),
                        lng: parseFloat(thisDevice.location.lng)
                    }}
                    onClick = {() => {
                        // console.log(device.location);

                        // dispatch(add_enabled_vehicle_pop_up(vid));
                        // dispatch(set_selected_device(thisDevice.dvid));
                        dispatch(set_map_action(thisDevice.dvid));
                    }}
                >
                    {
                        mapControl.toggleControl.showInfoWindowVehicles.includes(thisVehicle.vid)
                        && devices.selectedId 
                        && devices.selectedId === thisVehicle.dvid ?
                            <OverlayView
                                options = {{ maxWidth: 250 }}
                                position = {{
                                    lat: parseFloat(thisDevice.location.lat),
                                    lng: parseFloat(thisDevice.location.lng)
                                }}
                                mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset = {(width, height) => ({
                                    x: -(width / 2),
                                    y: -height - 15,
                                })}
                            >
                                <div>
                                    <h1>{thisVehicle.vehiclePlate}</h1>

                                    <h3
                                        style={{
                                            fontWeight: "bold",
                                            color: getVehicleTransitColor(thisDevice.dvid),
                                        }}
                                    >
                                        <b>{thisVehicle.transitName}</b>
                                    </h3>

                                    <p
                                        style={{
                                            color: thisVehicle.transitStatus ? VEHICLE_COLOR[thisVehicle.transitStatus] : VEHICLE_COLOR["NULL"]
                                        }}
                                    >
                                        {
                                            thisVehicle.transitStatus && thisVehicle.transitStatus !== "DISCONNECTED" ?
                                                `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                thisVehicle.hasOwnProperty('updatedAt') ?
                                                    `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                    thisVehicle.gpsStatus ?
                                                        `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                        `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.noGPSAt)}`
                                        }
                                    </p>

                                    <p>
                                        {
                                            thisVehicle.hasOwnProperty('updatedAt') ?
                                                `Last Updated: ${ParseTime(thisVehicle.updatedAt)}` :
                                                thisVehicle.gpsStatus ?
                                                    `Last Updated: ${ParseTime(thisVehicle.updatedAt)}` :
                                                    thisVehicle.isAlive ?
                                                        `Last Updated: ${ParseTime(thisVehicle.noGPSAt)}` :
                                                        `Last Updated: ${ParseTime(thisVehicle.deviceTime)}`
                                        }
                                    </p>

                                    {/* <br /><br /><br /> */}

                                    {thisVehicle.transitName === VEHICLE_STATUS.MOVING && <p>Speed: {thisVehicle.speed.toFixed(1)} KM/H</p>}

                                    {
                                        inGeofence && thisVehicle.transitStatus !== VEHICLE_STATUS.DISCONNECTED &&
                                            <div>
                                                <Row style={{ margin: 1 }}>
                                                    <Col
                                                        span = {7}
                                                        className = "POI"
                                                        style = {stylePOI}
                                                    >
                                                        {inGeofence.geofenceType}
                                                    </Col>

                                                    <Col
                                                        span = {16}
                                                        offset = {1}
                                                        className = "POI_Description"
                                                        style = {stylePOIDescription}
                                                    >
                                                        {inGeofence.geofenceName}
                                                    </Col>
                                                </Row>
                                            </div>
                                    }

                                    <span style={{ fontWeight: "bold" }}>
                                        <p>{thisVehicle.address || "Address not found"}</p>
                                    </span>

                                    <br />
                                    {/* <br /> */}

                                    <span
                                        onClick = {() => {
                                            // const currDevice = devices.byDVID[thisDevice.dvid];
                                            // const currCoor = {
                                            //     lat: parseFloat(currDevice.location.lat),
                                            //     lng: parseFloat(currDevice.location.lng)
                                            // }

                                            // console.log("Adding new POI from vehicle:", thisDevice.dvid, currDevice.location);

                                            // dispatch(set_drawn_geofence({
                                            //     ...mapDrawControl.drawnGeofence,
                                            //     coordinates: [currCoor],
                                            // }));

                                            // dispatch(moveToPage("/Geofence/AddPOI"), { 'coordinates': [currCoor] });
                                        }}
                                    >
                                        <div className = "ButtonLink">Add as POI</div>
                                    </span>
                                </div>
                            </OverlayView> :
                            mapControl.toggleControl.showAllVehiclesInfoWindow &&
                                <OverlayView
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    options = {{ 
                                        disableAutoPan: true,
                                        maxWidth: 250
                                    }}
                                    position = {{
                                        lat: parseFloat(thisDevice.location.lat),
                                        lng: parseFloat(thisDevice.location.lng)
                                    }}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 5,
                                    })}
                                >
                                    <div
                                        // className = "overlayPopUp"
                                        style = {{
                                            background: 'white',
                                            
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',

                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 8,
                                            paddingBottom: 1,
                                        }}
                                    >
                                        <h1>{thisVehicle.vehiclePlate}</h1>
                                    </div>
                                </OverlayView>
                    }
                </Marker>
            )
        }
    }

    return (
        Object.keys(vehicles.byVID).map(vid => {
            const thisVehicle = vehicles.byVID[vid];
            const thisDevice = devices.byDVID[thisVehicle.dvid];

            return getVehicleMarker(thisVehicle, thisDevice);
        })
    )
}

export default VehicleMarkers