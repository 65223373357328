import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getVehicles = (vgidList) => (
    fetch(`${api}/vehicle/group/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "vgids": vgidList,
        })
    })
        .then(res => res.json())
        .then(data => data)
)

export const addVehicle = (uid, cid, serviceAdvisor, deliveryDate, newVehicle, dvid) => {
    // console.log({uid, cid, serviceAdvisor, deliveryDate, newVehicle});

    return fetch(`${api}/vehicle/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "cid": cid,
            "serviceAdvisor": serviceAdvisor,
            "deliveryDate": deliveryDate,
            "vehicle": newVehicle,
            "dvid": dvid,
        })
    })
        .then(res => res.json())
        .then(data => data)
}

export const editVehicle = (uid, cid, serviceAdvisor, deliveryDate, newVehicle) => (
    fetch(`${api}/vehicle/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "cid": cid,
            "serviceAdvisor": serviceAdvisor,
            "deliveryDate": deliveryDate,
            "vehicle": newVehicle,
        })
    })
        .then(res => res.json())
        .then(data => data)
)

export const allocateVehicleToGroups = (uid, vid, vgidList) => (
    fetch(`${api}/vehicle/group/reassign`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "vid": vid,
            "vgids": vgidList,
        })
    })
        .then(res => res.json())
        .then(data => data)
)

export const assignDeviceToVehicle = (uid, vid, dvid) => (
    fetch(`${api}/vehicle/assign/device`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "vid": vid,
            "dvid": dvid,
        })
    })
        .then(res => res.json())
        .then(data => data)
)

export const unassignDevices = (dvids, uid) => (
    fetch(`${api}/device/unassign`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dvids": dvids,
            uid,
        })
    })
        .then(res => res.json())
        .then(data => data)
)