import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";

import PagesWithMaps from "../navigation/PagesWithMaps";
import asyncComponent from "../components/AsyncComponent";

// Pages
import ServiceReminderDashboard from "../pages/ServiceReminderDashboard";
import ServiceManagement from "../pages/ServiceManagement";

import VehicleManagement from "../pages/VehicleManagement";
import AddVehicle from "../pages/VehicleManagement/VehicleForm";
import EditVehicle from "../pages/VehicleManagement/VehicleForm";

import CustomerManagement from "../pages/CustomerManagement";
import AddCustomer from "../pages/CustomerManagement/CustomerForm";
import EditCustomer from "../pages/CustomerManagement/CustomerForm";

import DeviceManagement from "../pages/DeviceManagement";
import DeviceLogInspector from "../pages/DeviceManagement/DeviceLogInspector";
import AddDevice from "../pages/DeviceManagement/DeviceForm"
import EditDevice from "../pages/DeviceManagement/DeviceForm";

import ServiceReminderRecord from "../pages/ServiceReminderRecord";
import AfterSalesStatusLog from "../pages/AfterSalesStatusLog";
import VehicleAverageMileageLog from "../pages/VehicleAverageMileageLog";
import ReportPage from "../pages/ReportPage";
import ProfilePage from "../pages/ProfilePage";

const AsyncNotFoundPage = asyncComponent(() => import("../pages/NotFoundPage"));

const pagesWithMaps = [
    "/EventLog",
    "/RoutePlayback",
]

const MainRoutes = props => {
    return (
        <Switch>
            {
                pagesWithMaps.map(mr =>
                    <Route
                        key={mr}
                        exact path={mr}
                        render={routeProps => <PagesWithMaps {...props} {...routeProps} />}
                    />
                )
            }

            <Route
                exact path={"/"}
                render={routeProps => (
                    <ServiceReminderDashboard {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/ServiceManagement"}
                render={routeProps => (
                    <ServiceManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement"}
                render={routeProps => (
                    <VehicleManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement/AddVehicle"}
                render={routeProps => (
                    <AddVehicle {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement/EditVehicle"}
                render={routeProps => (
                    <EditVehicle {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/CustomerManagement"}
                render={routeProps => (
                    <CustomerManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/CustomerManagement/AddCustomer"}
                render={routeProps => (
                    <AddCustomer {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/CustomerManagement/EditCustomer"}
                render={routeProps => (
                    <EditCustomer {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement"}
                render={routeProps => (
                    <DeviceManagement {...props} {...routeProps} />
                )}
            />


            <Route
                exact path={"/DeviceManagement/DeviceLogInspector"}
                render={routeProps => (
                    <DeviceLogInspector {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement/AddDevice"}
                render={routeProps => (
                    <AddDevice {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement/EditDevice"}
                render={routeProps => (
                    <EditDevice {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/ServiceReminderRecord"}
                render={routeProps => (
                    <ServiceReminderRecord {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/AfterSalesStatusLog"}
                render={routeProps => (
                    <AfterSalesStatusLog {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleAverageMileageLog"}
                render={routeProps => (
                    <VehicleAverageMileageLog {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/Report"}
                render={routeProps => (
                    <ReportPage {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/Profile"}
                render={routeProps => (
                    <ProfilePage {...props} {...routeProps} />
                )}
            />

            <Route component={AsyncNotFoundPage} />
        </Switch>
    )
}

export default connect(null)(MainRoutes);