import * as API from "../../api";
import * as ActionTypes from "../action-types";

export const get_service_configuration = () => (dispatch) => {

    API.getAllServiceConfigurations()
        .then(data => {

            if(data.status === 200) {
                dispatch(get_service_configuration_success(data.serviceConfigurations))
            }
        })
}

const get_service_configuration_success = (serviceConfigurations) => {
    return {
        type: ActionTypes.GET_SERVICE_CONFIGURATION,
        serviceConfigurations
    }
}