import * as ActionTypes from "../action-types/mapControl";

export const set_map_action = (newAction) => {
  return {
    type: ActionTypes.SET_MAP_STATUS,
    newAction: newAction
  }
}

export const set_map_selected_evid = (newSelectedEvID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_EVID,
    newSelectedEvID: newSelectedEvID
  }
}

export const clear_map_selected_evid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_EVID,
  }
}

export const set_map_selected_trid = (newSelectedTrID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_TRID,
    newSelectedTrID: newSelectedTrID
  }
}

export const clear_map_selected_trid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_TRID,
  }
}

export const set_map_selected_geoid = (newSelectedGeoID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_GEOID,
    newSelectedGeoID: newSelectedGeoID
  }
}

export const clear_map_selected_geoid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_GEOID,
  }
}