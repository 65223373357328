import React from "react";
import { connect } from "react-redux";

import { 
    Form,
    Modal, 
    Input,
    Select,
    message,
} from "antd";

// Redux Actions
import { assign_device_to_vehicle } from "../../../services/redux/actions/vehicles";

class PopUpPair extends React.Component {
    state = {
        deviceList: [],
        selectedDVID: "",
    }

    componentDidMount = () => {
        if (Object.keys(this.props.devices.byDVID).length > 1) {
            const { devices } = this.props;

            this.setState({
                deviceList: Object.keys(devices.byDVID).filter((currDVID) => devices.byDVID[currDVID].assignedStatus === 0)
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.vehicles !== this.props.vehicles) {
            this.props.onCloseModal();
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title = "Pair Device"
                    visible = {true}
                    closable = {false}
                    okButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    cancelButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    onOk = {() => 
                        this.state.selectedDVID ? 
                            this.props.dispatch(assign_device_to_vehicle(this.props.user.uid, this.props.selectedVID, this.state.selectedDVID)) :
                            message.error("Please select a device to pair with this vehicle.")
                    }
                    onCancel = {() => this.props.onCloseModal()}
                >
                    <Form> 
                        <Form.Item
                            label = "Engine Number"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Input
                                disabled = {true}
                                placeholder = "Vehicle not selected"
                                value = {this.props.vehicles.byVID[this.props.selectedVID].vehiclePlate}
                            />
                        </Form.Item>

                        <Form.Item
                            label = "Pair Devices"
                            labelCol = {{ span: 7 }}
                            wrapperCol = {{ span: 15 }}
                        >
                            <Select
                                disabled = {this.state.deviceList.length < 1}
                                placeholder = {this.state.deviceList.length > 0 ? "Select a device" : "No devices found"}
                                onChange = {value => 
                                    this.setState({
                                        ...this.state,
                                        selectedDVID: value,
                                    })
                                }
                            >
                                {
                                    this.state.deviceList.map((currDVID) => 
                                        <Select.Option key = {currDVID} value = {currDVID}>
                                            {currDVID}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default connect(mapStateToProps)(PopUpPair);