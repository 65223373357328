import React from "react";

import { 
  Menu
} from "antd";

const SidebarItems = (props) => {
  return (
    <Menu
      mode = 'inline'
      theme = {props.theme}
      selectedKeys = {[props.selectedKeys]}
      style = {{
        height: "calc(90vh - 68px)", // Minus the logo height
        overflowY: "auto",
        background: "transparent"
      }}
      onClick = {props.onClick}
    >
      {
        props.items.map(item => 
          <Menu.Item key = {item.key} {...props}>
            {item.icon}
            <span>{item.label}</span>
          </Menu.Item>
        )
      }
    </Menu>
  );
};

SidebarItems.defaultProps = {
  items: [],
  theme: "dark",
  collapsed: "true",
  onClick: () => {},
};

export default SidebarItems;