import * as ActionTypes from "../action-types/vehicleASLogs";

const defaultState = {
    byVID: {},
    loadingStatus: undefined,
}

export const vehicleASLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_AS_LOG: {
            
            newState.byVID = {}
            action.vehicleASLogs.forEach((log) => {
                if (!newState.byVID[log.vid]) {
                    newState.byVID[log.vid] = [];
                }

                newState.byVID[log.vid].push(log);
            })
            return newState;
        }

        case ActionTypes.CLEAR_VEHICLE_AS_LOG: {
            // newState.byVID = defaultState.byVID;
            newState.byVID = {}
            return newState;
        }

        case ActionTypes.SET_VEHICLE_AS_LOG_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return state;
        }
    }
};
