import { defineAbility, createAliasResolver } from "@casl/ability";
import { 
  FEATURE, 
  ROLE_TYPE 
} from "../../constants";

/**
 * Defines the access right for the user
 * @param {string} roleType Role of the user
 */
export const buildAbility = roleType => {

  const resolveAction = createAliasResolver({
    modify: ['update', 'delete']
  });

  // console.log(roleType, ROLE_TYPE.SUPER)
  
  switch (roleType) {
    case ROLE_TYPE.SUPER: {
      // console.log("Hi Super");
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("manage", FEATURE.FLEET_TRACKING);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("manage", FEATURE.TRIP_RECORD);
        // can("manage", FEATURE.VEHICLE_ACTIVITY);
        // can("manage", FEATURE.EVENT_RECORD);
        // can("manage", FEATURE.GOSUN_INSPECTOR)
        // can("manage", FEATURE.POI);
        // can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE_RECORD);
        // can("manage", FEATURE.USERS); //newly added
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.USER_MANAGEMENT);
        // can("manage", FEATURE.DEVICE_MANAGEMENT);
        // can("manage", FEATURE.REPORT);
        // can("manage", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.CALLED_REPORT);
        // can("manage", FEATURE.NOTIFICATIONS);
        // can("manage", FEATURE.PROFILE);
        // can("manage", FEATURE.PREFERENCES);
        // can("manage", FEATURE.ABOUT);
        // can("manage", FEATURE.CASES);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
        // can("read", FEATURE.DEVICE_INSPECTOR);
      });
    }
    case ROLE_TYPE.SUPPORT: {
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("manage", FEATURE.FLEET_TRACKING);
        // can("manage", FEATURE.TRIP_RECORD);
        // can("manage", FEATURE.VEHICLE_ACTIVITY);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("manage", FEATURE.EVENT_RECORD);
        // can("manage", FEATURE.POI);
        // can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE_RECORD);
        // can("manage", FEATURE.USERS); //newly added
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("read", FEATURE.DEVICE_MANAGEMENT);
        // can("manage", FEATURE.DEVICE_MANAGEMENT);
        // can("read", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.CALLED_REPORT);
        // can("manage", FEATURE.NOTIFICATIONS);
        // can("manage", FEATURE.PROFILE);
        // can("manage", FEATURE.PREFERENCES);
        // can("manage", FEATURE.ABOUT);
        // can("manage", FEATURE.CASES);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
      });
    }
    case ROLE_TYPE.DISTRIBUTOR: {
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("read", FEATURE.FLEET_TRACKING);
        // can("manage", FEATURE.TRIP_RECORD);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("read", FEATURE.VEHICLE_ACTIVITY);
        // can("read", FEATURE.EVENT_RECORD);
        // can("read", FEATURE.POI);
        // can("manage", FEATURE.POI);
        // can("read", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE_RECORD);
        // can("manage", FEATURE.USERS); //newly added
        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.USER_MANAGEMENT);
        // can("read", FEATURE.DEVICE_MANAGEMENT);
        // can("manage", FEATURE.DEVICE_MANAGEMENT);
        // can("read", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.VEHICLE_MANAGEMENT);
        // can("read", FEATURE.CALLED_REPORT);
        // can("read", FEATURE.NOTIFICATIONS);
        // can("read", FEATURE.PROFILE);
        // can("manage", FEATURE.PROFILE);
        // can("read", FEATURE.PREFERENCES);
        // can("manage", FEATURE.PREFERENCES);
        // can("read", FEATURE.ABOUT);
        // can("read", FEATURE.CASES);
        // can("manage", FEATURE.CASES);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
        // can("read", FEATURE.DEVICE_INSPECTOR);
      });
    }
    case ROLE_TYPE.FLEET_MANAGER: {
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("read", FEATURE.FLEET_TRACKING);
        // can("read", FEATURE.TRIP_RECORD);
        // can("read", FEATURE.VEHICLE_ACTIVITY);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("read", FEATURE.EVENT_RECORD);
        // can("read", FEATURE.POI);
        // can("read", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE_RECORD);

        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("read", FEATURE.VEHICLE_MANAGEMENT);
        // can("read", FEATURE.CALLED_REPORT);
        // can("read", FEATURE.NOTIFICATIONS);
        // can("read", FEATURE.PROFILE);
        // can("manage", FEATURE.PROFILE);
        // can("read", FEATURE.PREFERENCES);
        // can("manage", FEATURE.PREFERENCES);
        // can("read", FEATURE.ABOUT);
        // can("read", FEATURE.CASES);
        // can("manage", FEATURE.CASES);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
      });
    }
    case ROLE_TYPE.FLEET_OWNER: {
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("read", FEATURE.FLEET_TRACKING);
        // can("read", FEATURE.TRIP_RECORD);
        // can("read", FEATURE.VEHICLE_ACTIVITY);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("read", FEATURE.EVENT_RECORD);
        // can("read", FEATURE.POI);
        // can("manage", FEATURE.POI);
        // can("read", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.GEOFENCE_RECORD);
        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("read", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.VEHICLE_MANAGEMENT);
        // can("read", FEATURE.CALLED_REPORT);
        // can("read", FEATURE.NOTIFICATIONS);
        // can("read", FEATURE.PROFILE);
        // can("manage", FEATURE.PROFILE);
        // can("read", FEATURE.PREFERENCES);
        // can("manage", FEATURE.PREFERENCES);
        // can("read", FEATURE.ABOUT);
        // can("read", FEATURE.CASES);
        // can("manage", FEATURE.CASES);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
        
        // can("manage", FEATURE.USERS); //newly added
        // can("manage", FEATURE.USER_MANAGEMENT);
        // can("manage", FEATURE.REPORT);
      });
    }
    case ROLE_TYPE.FLEET_OPERATOR: {
      return defineAbility({ resolveAction }, (can, cannot) => {
        // can("read", FEATURE.POI);
        // can("read", FEATURE.ABOUT);
        // can("read", FEATURE.CASES);
        // can("read", FEATURE.PROFILE);
        // can("read", FEATURE.GEOFENCE);
        // can("read", FEATURE.PREFERENCES);
        // can("read", FEATURE.EVENT_RECORD);
        // can("read", FEATURE.TRIP_RECORD);
        // can("read", FEATURE.CALLED_REPORT);
        // can("read", FEATURE.NOTIFICATIONS);
        // can("read", FEATURE.FLEET_TRACKING);
        // can("read", FEATURE.VEHICLE_ACTIVITY);
        // can("read", FEATURE.VEHICLE_MANAGEMENT);
        // can("manage", FEATURE.VEHICLE_DISPLAY);
        // can("manage", FEATURE.GEOFENCE_RECORD);
        // can("manage", FEATURE.CONFIGURATION_MANAGEMENT);
        // can("manage", FEATURE.FUEL_ANALYSIS);
      });
    }
    default: {
      // console.log("Hi Default");
      return defineAbility({ resolveAction }, (can, cannot) => {
        can("manage", FEATURE.PROFILE);
        can("manage", FEATURE.EVENT_LOG);
        can("manage", FEATURE.REPORT);
        can("manage", FEATURE.ROUTE_PLAYBACK);
        can("manage", FEATURE.SERVICE_MANAGEMENT);
        can("manage", FEATURE.DEVICE_MANAGEMENT);
        can("manage", FEATURE.VEHICLE_MANAGEMENT);
        can("manage", FEATURE.CUSTOMER_MANAGEMENT);
        can("manage", FEATURE.AFTER_SALES_STATUS_LOG);
        can("manage", FEATURE.SERVICE_REMINDER_RECORD);
        can("manage", FEATURE.SERVICE_REMINDER_DASHBOARD);
        can("manage", FEATURE.VEHICLE_AVERAGE_MILEAGE_LOG);
      });
    }
  }
};
