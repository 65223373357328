import * as ActionTypes from "../action-types/serviceReminders";

const defaultState = {
    byVID: {},
    bySRID: {},
    loadingStatus: undefined
}

export const serviceReminders = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_SERVICE_REMINDERS: {
            newState.byVID = JSON.parse(JSON.stringify(defaultState.byVID)); // Clear newState bySRID
            newState.bySRID = JSON.parse(JSON.stringify(defaultState.bySRID)); // Clear newState bySRID
            
            action.serviceReminders.forEach((serviceReminder) => {
                newState.bySRID[serviceReminder.srid] = serviceReminder;
                
                if (!newState.byVID[serviceReminder.vid]) {
                    newState.byVID[serviceReminder.vid] = [];
                }

                newState.byVID[serviceReminder.vid].push(serviceReminder.srid);
            });

            return newState;
        }

        case ActionTypes.ADD_SERVICE_REMINDER: {
            newState.bySRID[action.serviceReminder.srid] = action.serviceReminder;
            return newState;
        }

        case ActionTypes.EDIT_SERVICE_REMINDER: {
            newState.bySRID[action.serviceReminder.srid] = action.serviceReminder;
            return newState;
        }

        case ActionTypes.SET_SELECTED_SRID: {
            newState.selectedSRID = action.selectedSRID;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_SRID: {
            newState.selectedSRID = JSON.parse(JSON.stringify(defaultState.selectedSRID));

            return newState;
        }

        case ActionTypes.SET_SERVICE_REMINDER_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}