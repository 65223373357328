import * as COLOR from "./color";
import * as IMAGE_RESOURCE from "./img";

import { config } from '../config';

const MAP_DEFAULT_ZOOM = 7.5;

const MAP_DEFAULT_LOCATION = {
    lat: 3.156982,
    lng: 101.6637798
};

const TEMPLATE_URLS = {
    BULK_UPLOAD_DEVICES_TEMPLATE: `https://as-templates.s3-ap-southeast-1.amazonaws.com/Bulk+Upload+Devices+Template.xlsx`
}

const SERVICE_REMINDER_STATUSES = {
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE',
}

const SERVICE_REMINDER_REASONS = [
    {
        reason: 'Make Appointment',
        reminderStatus: SERVICE_REMINDER_STATUSES.COMPLETED
    },
    {
        reason: 'Service At Others',
        reminderStatus: SERVICE_REMINDER_STATUSES.COMPLETED
    },
    {
        reason: 'No budget/time',
        reminderStatus: SERVICE_REMINDER_STATUSES.COMPLETED
    },
    {
        reason: 'Already serviced at outlet',
        reminderStatus: SERVICE_REMINDER_STATUSES.COMPLETED
    },
    {
        reason: 'Call back for appointment',
        reminderStatus: SERVICE_REMINDER_STATUSES.INCOMPLETE
    },
    {
        reason: 'No answer',
        reminderStatus: SERVICE_REMINDER_STATUSES.INCOMPLETE
    },
]

const VEHICLE_STATUS = {
    ALL: "ALL",
    EVENT: "EVENT",
    MOVING: "MOVING",
    IDLING: "IDLING",
    PARKING: "PARKING",
    OFFLINE: "OFFLINE",
    INACTIVE: "INACTIVE",
    STOP: "ENGINE STARTED",
    DISCONNECTED: "DISCONNECTED",
    NULL: "NULL", // In case vehicle transit status does not return truthy
};

const VEHICLE_COLOR = {
    [VEHICLE_STATUS.ALL]: "#C4C4C4",
    [VEHICLE_STATUS.STOP]: "#856B37", //old color: #2A2AFF
    [VEHICLE_STATUS.EVENT]: "#81201B", //old color: #2A2AFF
    [VEHICLE_STATUS.MOVING]: "#52C41A", //old color: #37c837
    [VEHICLE_STATUS.IDLING]: "#FFB517", //old color: #7F2AFF
    [VEHICLE_STATUS.PARKING]: "#0488DB", //old color: #D4FF2A
    [VEHICLE_STATUS.OFFLINE]: "#333333", //black
    [VEHICLE_STATUS.INACTIVE]: "#ff7f2a", //orange
    [VEHICLE_STATUS.DISCONNECTED]: "#81201B",
    [VEHICLE_STATUS.NULL]: "#333333", //black
};

const VEHICLE_MODELS = {
    PERODUA_AXIA: "Perodua Axia",
    PERODUA_MYVI: "Perodua Myvi",
    PERODUA_BEZZA: "Perodua Bezza",
    PERODUA_ARUZ: "Perodua Aruz",
};

const EVENT_TYPES = {
    ALL: "ALL",
    INFO: "INFO",
    WARNING: "WARNING",
    CRITICAL: "CRITICAL",
    CONTROL: "CONTROL"
};

const EVENT_COLORS = {
    [EVENT_TYPES.ALL]: "#C4C4C4",
    [EVENT_TYPES.INFO]: "#52C41A",
    [EVENT_TYPES.CONTROL]: "#856B37",
    [EVENT_TYPES.WARNING]: "#FFB517",
    [EVENT_TYPES.CRITICAL]: "#0488DB",
    [undefined]: "black",
};

const TRANSIT_TYPES = {
    TRANSIT: "TRANSIT",
    PARKING: "PARKING",
    DISCONNECTED: "DISCONNECTED",
};

const TRANSIT_COLORS = {
    [TRANSIT_TYPES.TRANSIT]: "#52C41A",
    [TRANSIT_TYPES.PARKING]: "#0091D4",
    [TRANSIT_TYPES.DISCONNECTED]: "black",
    [undefined]: "black",
};

const RULE_TYPES = {
    INFO: "INFO",
    WARNING: "WARNING",
    CRITICAL: "CRITICAL",
    CONTROL: "CONTROL"
};

const DISPLAY_RULE_TYPE = {
    0: "INFO",
    1: "WARNING",
    2: "CRITICAL",
};

const ROLE_TYPE = {
    SUPER: "super",
    DISTRIBUTOR: "distributor",
    SUPPORT: "support",
    FLEET_OWNER: "fleetOwner",
    FLEET_OPERATOR: "fleetOperator",
    SERVICE_ADVISOR: "serviceAdvisor",
    UNKNOWN: "Unknown",
};

const ROLE_DEFINE = {
    SUPER: "Admin",
    DISTRIBUTOR: "Distributor",
    SUPPORT: "Support",
    FLEET_OWNER: "Fleet Owner",
    FLEET_OPERATOR: "Fleet Operator",
    SERVICE_ADVISOR: "Service Advisor",
    UNKNOWN: "Unknown",
};

const DISPLAY_USER_ROLE = (userRole) => {
    if (userRole === ROLE_TYPE.FLEET_OPERATOR) {
        return ROLE_DEFINE.FLEET_OPERATOR;
    }
    else if (userRole === ROLE_TYPE.FLEET_MANAGER) {
        return ROLE_DEFINE.FLEET_MANAGER;
    }
    else if (userRole === ROLE_TYPE.FLEET_OWNER) {
        return ROLE_DEFINE.FLEET_OWNER;
    }
    else if (userRole === ROLE_TYPE.DISTRIBUTOR) {
        return ROLE_DEFINE.DISTRIBUTOR;
    }
    else if (userRole === ROLE_TYPE.SUPER) {
        return ROLE_DEFINE.SUPER;
    }
    else if (userRole === ROLE_TYPE.SERVICE_ADVISOR) {
        return ROLE_DEFINE.SERVICE_ADVISOR;
    }
};

switch (config.project) {
    case 'gpsfleetMY': {
        ROLE_TYPE.SUPER = 'super';
        ROLE_TYPE.DISTRIBUTOR = "distributor";
        ROLE_DEFINE.SUPER = "Admin";
        ROLE_DEFINE.DISTRIBUTOR = "Distributor";

        break;
    }
    case 'gpsfleetLAB': {
        ROLE_TYPE.SUPER = 'super';
        ROLE_TYPE.DISTRIBUTOR = "distributor";
        ROLE_DEFINE.SUPER = "Admin";
        ROLE_DEFINE.DISTRIBUTOR = "Distributor";

        break;
    }

    default: {
        break;
    }
};

const COMPANY = {
    NAME: 'Celcom',
    ADDRESS: 'Not Available'
};

const FEATURE = {
    SERVICE_REMINDER_DASHBOARD: "ServiceReminderDashboard",
    SERVICE_MANAGEMENT: "ServiceManagement",
    VEHICLE_MANAGEMENT: "VehicleManagement",
    CUSTOMER_MANAGEMENT: "CustomerManagement",
    DEVICE_MANAGEMENT: "DeviceManagement",
    SERVICE_REMINDER_RECORD: "ServiceReminderRecord",
    AFTER_SALES_STATUS_LOG: "AfterSalesStatusLog",
    VEHICLE_AVERAGE_MILEAGE_LOG: "VehicleAverageMileageLog",
    EVENT_LOG: "EventLog",
    ROUTE_PLAYBACK: "RoutePlayback",
    REPORT: "Report",
    PROFILE: "Profile",
};

const SCREEN_LAYOUT = {
    FULL_SIDEBAR_WIDTH: 400, // window.innerWidth*0.2, //Usual 400px,
    EXPAND_SIDEBAR_WIDTH: 700 // window.innerWidth*0.3 //Usual 400px,
};

const FUEL_EVENT_TYPE = {
    REFUEL: "isRefuel",
    MOVING: "isMoving",
    IDLING: "isIdling",
    FUEL_LOSS: "isFuelLoss",
};

const VEHICLE_GROUP_TYPES = {
    AREA: "AREA",
    OUTLET: "OUTLET"
};

const PRODUCTION_STATUS = {
    ACC: "ACC",
    STAGED: "STAGED",
    LOADED: "LOADED",
    ALLOCATED: "ALLOCATED",
};

const PROGRESS_TYPES = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
};

const PROCESS_STATUS = {
    CREATED: "CREATED",
    INITIATED: "INITIATED",
    ACC: "ACC",
    YARD: "YARD",
    ALLOCATED: "ALLOCATED",
    STAGED: "STAGED",
    LOADED: "LOADED",
    OUTLET: "OUTLET",
};

const SERVICE_TYPES = {
    S1: "S1",
    S2: "S2",
};

const TRIGGER_TYPES = {
    MILEAGE: 'Mileage',
    PERDIODICS: 'Periodics'
}

const REMINDER_TYPES = {
    SMS: "SMS",
    PHONE: "PHONE",
};

const DEVICE_STATUSES = {
    CONNECTED: "CONNECTED",
    DISCONNECTED: "DISCONNECTED",
}

const PAIRED_STATUSES = [
    'UNPAIRED',
    'PAIRED'
]


const STATUS_TYPES = {
    CREATED: "CREATED",
    INITIATED: "INITIATED",
    S1_SMS: "S1 SMS",
    S1_CALL: "S1 CALL",
    S1_SERVICE: "S1 SERVICE",
    S1_OVERDUE: "S1 OVERDUE",
    S2_COURTESY: "S2 COURTESY",
    S2_SMS: "S2 SMS",
    S2_CALL: "S2 CALL",
    S2_SERVICE: "S2 SERVICE",
    S2_OVERDUE: "S2 OVERDUE",
    COMPLETED: "COMPLETED",
}

const LOADING_STATUS = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
}

const COUNTRY_CODES = {
    MALAYSIA: '+60'
}

export {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
    COLOR,
    COMPANY,
    FEATURE,
    ROLE_TYPE,
    RULE_TYPES,
    SCREEN_LAYOUT,
    VEHICLE_COLOR,
    VEHICLE_STATUS,
    IMAGE_RESOURCE,
    DISPLAY_USER_ROLE,
    FUEL_EVENT_TYPE,
    VEHICLE_GROUP_TYPES,
    DISPLAY_RULE_TYPE,
    PRODUCTION_STATUS,
    PROGRESS_TYPES,
    PROCESS_STATUS,
    EVENT_TYPES,
    EVENT_COLORS,
    TRANSIT_TYPES,
    TRANSIT_COLORS,
    SERVICE_TYPES,
    TRIGGER_TYPES,
    REMINDER_TYPES,
    VEHICLE_MODELS,
    DEVICE_STATUSES,
    STATUS_TYPES,
    TEMPLATE_URLS,
    SERVICE_REMINDER_REASONS,
    SERVICE_REMINDER_STATUSES,
    LOADING_STATUS,
    PAIRED_STATUSES,
    COUNTRY_CODES,
}
