import * as ActionTypes from "../action-types/geofences";

const defaultState = {
    byGeoID: {},
    selectedGeoID: "",
    loadingStatus: undefined
}

export const geofences = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_GEOFENCES: {
            newState.byGeoID = defaultState.byGeoID; // Clear newState byGeoID
            
            action.geofences.forEach((currGeofence) => newState.byGeoID[currGeofence.geoid] = currGeofence);

            return newState;
        }

        case ActionTypes.ADD_GEOFENCE: {       
            newState.byGeoID[action.newGeofence.geoid] = action.newGeofence;

            return newState;
        }

        case ActionTypes.EDIT_GEOFENCE: {       
            newState.byGeoID[action.editedGeofence.geoid] = action.editedGeofence;

            return newState;
        }

        case ActionTypes.SET_SELECTED_GEOID: {       
            newState.selectedGeoID = action.selectedGeoID;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_GEOID: {       
            newState.selectedGeoID = defaultState.selectedGeoID;

            return newState;
        }

        case ActionTypes.SET_GEOFENCE_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}