import React from "react";

const SvgSignalCellularOff = props => (
    <svg width={24} height={24} {...props}>
        <path d="M21 1l-8.59 8.59L21 18.18V1zM4.77 4.5L3.5 5.77l6.36 6.36L1 21h17.73l2 2L22 21.73 4.77 4.5z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export default SvgSignalCellularOff;
