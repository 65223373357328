// import * as API from "../../api/vehicles";
import * as ActionTypes from "../action-types/geofenceTemplates";

// Exported to user action
export const get_geofence_templates_success = (geofenceTemplates) => {
    return {
        type: ActionTypes.GET_GEOFENCE_TEMPLATES,
        geofenceTemplates: geofenceTemplates,
    };
}
export const set_geofence_templates_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_GEOFENCE_TEMPLATES_LOADING_STATUS,
        loadingStatus
    }
}