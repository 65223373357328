import * as API from "../../api/deviceLogs";
import * as ActionTypes from "../action-types/deviceLogs";

import { message } from 'antd';
// import {
//     setLoadingSubmit,
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'
export const get_device_log = (dvidList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(set_device_log_loading_status(LOADING_STATUS.LOADING))

    API.getDeviceLog(dvidList, startTime, endTime)
        .then(data => {
            // console.log("Get Device Log Data:", data);

            if (data.status !== 200) {
                message.error('Failed To Get Device Log: ' + data.message);
                dispatch(set_device_log_loading_status(LOADING_STATUS.FAILED))
            }
            else {

                if (data.deviceLogs.length < 1) {
                    message.warning("No Logs Found Within This Timeframe.");
                }
                else {
                    message.info('Successfully Retrieved Device Log.');
                }

                dispatch(get_device_log_success(data.deviceLogs));
                dispatch(set_device_log_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const get_device_log_success = (newDeviceLogs) => {
    return {
        type: ActionTypes.GET_DEVICE_LOG,
        newDeviceLogs: newDeviceLogs
    }
}

export const get_device_log_debug = (dvidList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(set_device_log_loading_status(LOADING_STATUS.LOADING))

    API.getDeviceLog_Debug(dvidList, startTime, endTime)
        .then(data => {
            // console.log("Debug Device Log Data:", data);

            if (data.status !== 200) {
                message.error('Failed To Get Debug Device Log: ' + data.message);
                dispatch(set_device_log_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Retrieved Debug Device Log.');

                // if (data.deviceLogs.length < 1) {
                //     message.info("No Logs Found Within This Timeframe.");
                // }

                dispatch(get_device_log_debug_success(data.deviceLogs));
                dispatch(set_device_log_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const get_device_log_debug_success = (newDeviceLogs) => {
    return {
        type: ActionTypes.GET_DEVICE_LOG_DEBUG,
        newDeviceLogs: newDeviceLogs
    }
}

export const clear_device_log = () => {
    return {
        type: ActionTypes.CLEAR_DEVICE_LOG
    }
}

export const clear_device_log_debug = () => {
    return {
        type: ActionTypes.CLEAR_DEVICE_LOG_DEBUG
    }
}
export const set_device_log_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_DEVICE_LOG_LOADING_STATUS,
        loadingStatus
    }
}

export const set_device_log_selected_dvid = (selectedDVID) => {
    return {
        type: ActionTypes.SET_DEVICE_LOG_SELECTED_DVID,
        selectedDVID,
    }
}

export const clear_device_log_selected_dvid = () => {
    return {
        type: ActionTypes.CLEAR_DEVICE_LOG_SELECTED_DVID,
    }
}