import 
    React,
    {
        useEffect,
        useRef,
        useState
    }
from "react";
import { 
    useDispatch,
    useSelector
 } from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
import EmptyIndicator from "../../../components/EmptyIndicator";
import ExportExcelButton from "../../../components/ExportExcelButton";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";
import parseTime from '../../../utils/parseTime';

import { tableColumnSearchProps } from "../../../components/TableColumnSearchProps";
import {
    Form,
    Table,
    Button,
    Select,
    DatePicker,
} from 'antd';

// React Actions
import { clear_selected_dvid } from "../../../services/redux/actions/devices";
import {
    get_device_log,
    clear_device_log,
} from "../../../services/redux/actions/deviceLogs";

const DeviceLogInspector = (props) => {

    const dispatch = useDispatch();

    const devices = useSelector(state => state.devices)
    const deviceLogs = useSelector(state => state.deviceLogs)

    const [dataSource, setDataSource] = useState([])
    const [columns, setColumns] = useState([])

    const [startTime, setStartTime] = useState(moment().startOf("month"))
    const [endTime, setEndTime] = useState(moment().add(1, "day").startOf("day"))
    const [selectedDVID, setSelectedDVID] = useState(null)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const setupDataSource = () => {

        const newDataSource = Object.values(deviceLogs.byDVID[selectedDVID] || {})
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((log, index) => (
                {
                    key: index,
                    createdAt: parseTime(log.createdAt),
                    deviceTime: parseTime(log.deviceTime),
                    package: log.devicePackage,
                    deviceMessage: log.alarmMessage || "-",
                    gpsStatus: log.gpsStatus || 0,
                    engineStatus: log.engineStatus || 0,
                    location: log.location
                }
            ))

        const column = [
            {
                title: "Device Time",
                dataIndex: "deviceTime",
                key: 'deviceTime',
                sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
                // sortOrder: sortedInfo.columnKey === 'deviceTime' && sortedInfo.order,
            },

            {
                title: 'Server Time',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
                // sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
            },

            {
                title: "Data Package",
                dataIndex: "package",
                filters: ['auth', 'heartbeat', 'gps#latest', 'gps#memory', 'event#session#error', 'event#session#close', 'event#session#timeout']
                    .map(f => ({ text: f, value: f })),
                onFilter: (value, record) => value && record.package.indexOf(value) === 0,
            },

            {
                title: "Message",
                dataIndex: "deviceMessage",
                ...tableColumnSearchProps("deviceMessage", searchText, searchedColumn, handleSearch, handleReset),
            },

            {
                title: "GPS Status",
                dataIndex: "gpsStatus",
                filters: [{ text: "0", value: 0 }, { text: "1", value: 1 },],
                onFilter: (value, record) => value && record.gpsStatus.indexOf(value) === 0,
            },

            {
                title: "ACC",
                dataIndex: "engineStatus",
                filters: [{ text: "0", value: 0 }, { text: "1", value: 1 },],
                onFilter: (value, record) => value && record.engineStatus.indexOf(value) === 0,
            },

            {
                title: "Location",
                dataIndex: "location",
                render: location => {
                    if (!location) return `N/A`
                    return (
                        <a
                            href={`https://www.google.com.my/maps/place/${location.lat},${location.lng}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {location.lat},{location.lng}
                        </a>
                    )
                }
            },
        ]

        setDataSource(newDataSource)
        setColumns(column)
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevDeviceProps = usePrevious(devices);
    const prevDeviceLogProps = usePrevious(deviceLogs);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            mounted.current = true;
    
            setSelectedDVID(props.location.state)
        }
    }, [props.location.state]);

    useEffect(() => {
        if(mounted.current) {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevDeviceLogProps) !== JSON.stringify(deviceLogs)
                ||
                JSON.stringify(prevDeviceProps) !== JSON.stringify(devices)
            ) {
                setupDataSource()
            }
        }
    })

    // With componentDidUnmount()
    useEffect(() => {
        return () => {
            // Clearing device log because it's unlikely that user will be asking for the same device log within the same period
            dispatch(clear_device_log());
            dispatch(clear_selected_dvid());
        };
    }, [dispatch])

    const onSubmit = () => {
        let dvids = [selectedDVID];
        const param_startTime = startTime.valueOf();
        const param_endTime = endTime.valueOf();

        dispatch(get_device_log(dvids, param_startTime, param_endTime));
    }

    return (
        <div className="page-container">
            <Page title="Device Inspector Table">
                <Form layout='inline' style={{ marginBottom: 15 }}>
                    <Form.Item>
                        <span style={{ marginLeft: '10px' }}>Device IMEI: </span>

                        <Select
                            showSearch
                            placeholder='Device IMEI'
                            value={selectedDVID}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            onChange={value => {
                                setSelectedDVID(value)
                            }}
                            style={{ width: 250 }}
                        >
                            {
                                Object.values(devices.byDVID)
                                    .sort((a, b) => a.imei - b.imei)
                                    .map((device) => {
                                        const {
                                            imei,
                                            dvid,
                                        } = device;

                                        return (
                                            <Select.Option
                                                key={dvid}
                                                value={dvid}
                                            >
                                                {imei}
                                            </Select.Option>
                                        )
                                    })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <span>Start time: </span>

                        <DatePicker
                            showTime
                            style={{ width: 150 }}
                            defaultValue={startTime}
                            onChange={value => setStartTime(value)}
                        />

                        <span style={{ marginLeft: '10px' }}>End time: </span>

                        <DatePicker
                            showTime
                            style={{ width: 150 }}
                            defaultValue={endTime}
                            onChange={value => setEndTime(value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            disabled={!selectedDVID}
                            onClick={onSubmit}
                            loading={deviceLogs.loadingStatus === "LOADING"}
                            style={{
                                marginLeft: '15px',
                                marginRight: '10px'
                            }}
                        >
                            Submit
                        </Button>

                        <ExportExcelButton
                            disabled={deviceLogs.loadingStatus === "LOADING" || dataSource.length === 0}
                            filename={`Device Inspector ${selectedDVID} ${moment().format('DD-MM-YYYY')}`}
                            sheetData={dataSource}
                            sheetName={`${moment().format('DD-MM-YYYY')}`}
                            sheetRow={
                                columns
                                    .map(col => {

                                        switch (col.title) {
                                            case 'Location':
                                                return {
                                                    label: col.title,
                                                    formatter: value => {
                                                        const location = value[col.dataIndex];

                                                        return location ? `${location.lat}, ${location.lng}` : '';
                                                    }
                                                }
                                            default:
                                                return {
                                                    label: col.title,
                                                    formatter: value => value[col.dataIndex]
                                                }
                                        }
                                    })
                            }
                        />

                    </Form.Item>
                </Form>

                <div >
                    {
                        dataSource.length ?
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                loading={deviceLogs.loadingStatus === "LOADING"}
                                pagination={{ pageSize: 25 }}
                                scroll={{
                                    // x: "max-content",
                                    y: window.innerHeight - 20 - 49 - 25 - 40 - 150
                                }}
                            /> :
                            <EmptyIndicator />
                    }
                </div>
            </Page>
        </div>
    )

}

export default showSecondarySidebar(false)(DeviceLogInspector);