import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getStaff = (startTime, endTime) => (
    fetch(`${api}/user/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "startTime" : startTime,
            "endTime" : endTime,
        })
    })
    .then(res => res.json())
    .then(data => data)
)