import * as API from "../../api/serviceReminderLogs";
import * as ActionTypes from "../action-types/serviceReminderLogs";

import { message } from 'antd';
import {
    // setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";
import {
    LOADING_STATUS
} from '../../../constants'

export const get_service_reminder_log = (vids = null, startTime = null, endTime = null, reminderType, serviceType, vehicleModel) => (dispatch) => {
    dispatch(set_service_reminder_log_loading_status(LOADING_STATUS.LOADING))

    API.getServiceReminderLogs(vids, startTime, endTime)
        .then(data => {
            // console.log("getServiceReminderLogs data:", data);

            if (data.status !== 200) {
                message.error('Failed To Get Service Reminder Log: ' + data.message);
                dispatch(set_service_reminder_log_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                let { serviceReminderLogs = [] } = data;

                // console.log(`before ${serviceReminderLogs.length}`)

                serviceReminderLogs = filterServiceReminderLog(serviceReminderLogs, reminderType, serviceType, vehicleModel)

                // console.log(`after ${serviceReminderLogs.length}`)

                dispatch(get_service_reminder_log_success(serviceReminderLogs));
                dispatch(set_service_reminder_log_loading_status(LOADING_STATUS.SUCCESS))
            }

            dispatch(unsetLoadingSubmit());
        })

    function filterServiceReminderLog(serviceReminderLogs, reminderType, serviceType, vehicleModel) {
        return serviceReminderLogs.filter(serviceReminderLog => {
            try {
                if (reminderType && serviceReminderLog.reminderType !== reminderType) throw new Error('reminderType')
                if (serviceType && serviceReminderLog.serviceType !== serviceType) throw new Error('serviceType')

                return true
            } catch (err) {
                console.log({ err })
                return false
            }
        })
    }
}

const get_service_reminder_log_success = (serviceReminderLogs) => {
    return {
        type: ActionTypes.GET_SERVICE_REMINDER_LOG,
        serviceReminderLogs: serviceReminderLogs
    }
}

export const clear_service_reminder_log = () => {
    return {
        type: ActionTypes.CLEAR_SERVICE_REMINDER_LOG
    }
}

export const set_service_reminder_log_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_SERVICE_REMINDER_LOG_LOADING_STATUS,
        loadingStatus
    }
}