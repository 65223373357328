import * as API from "../../api";
import * as ActionTypes from "../action-types";

export const get_after_sales_workflow = () => (dispatch) => {

    API.getAllAfterSalesWorkflows()
        .then(data => {
            // console.log("getAfterSalesLogs data:", data);

            if(data.status === 200) {
                dispatch(get_after_sales_workflow_success(data.afterSalesWorkflows))
            }
        })
}

const get_after_sales_workflow_success = (afterSalesWorkflows) => {
    return {
        type: ActionTypes.GET_AFTER_SALES_WORKFLOW,
        afterSalesWorkflows
    }
}