import 
    React, 
    { 
        useEffect,
        useState
    } 
from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';

import moment from 'moment';
import parseTime from '../../../utils/parseTime';
import parseDate from '../../../utils/parseDate'
import {
    Form,
    Modal,
    InputNumber,
    Select,
    DatePicker,
    message
    // Descriptions,
} from 'antd';

// Constants
import {
    AFTERSALES_WORKFLOW_STATUS
} from '../../../constants/afterSalesWorkflow'

// Redux Actions
import {
    add_service_vehicle_record
} from '../../../services/redux/actions/serviceVehicleRecords'

const FormItem = Form.Item;

const ServiceVehicleRecordModal = (props) => {

    const dispatch = useDispatch();

    const vehicles = useSelector(state => state.vehicles)
    const afterSalesLogs = useSelector(state => state.afterSalesLogs)
    const serviceConfigurations = useSelector(state => state.serviceConfigurations)
    const vehicleASLogs = useSelector(state => state.vehicleASLogs)

    const [selectedVID, setSelectedVID] = useState('');
    const [servicedStatus, setServicedStatus] = useState('');
    const [actualTotalMileage, setActualTotalMileage] = useState(0);
    const [lastProcTotalMileage, setProcTotalMileage] = useState(0);
    const [servicedDate, setServicedDate] = useState(moment().startOf('day').utc().valueOf())

    const populateModal = () => {

    }

    // With ccomponentDidMount()
    useEffect(() => {
        populateModal()
    }, [])

    // With ccomponentDidUpdate()
    useEffect(() => {
        populateModal()
    }, [vehicles, serviceConfigurations])

    const handleOnSumbit = () => {

        try {
            if (!selectedVID) throw new Error(`Vehicle cannot be blank`)
            if (!servicedStatus) throw new Error(`Service type cannot be blank`)
            if (!servicedDate) throw new Error(`Service date cannot be blank`)
            if (!actualTotalMileage) throw new Error(`Actual odometer cannot be blank`)
            if (isNaN(actualTotalMileage)) throw new Error(`Actual odometer must be number`)

            const vehicle = vehicles.byVID[selectedVID]
            const serviceAdvisor = vehicle.serviceAdvisor;

            const addEditServiceVehicleRecord = {
                vid: selectedVID,
                serviceStatus: servicedStatus,
                serviceAdvisor,
                actualTotalMileage,
                lastProcTotalMileage,
                dvid: vehicles.byVID[selectedVID].dvid,
                serviceDate: servicedDate
            }
            
            dispatch(add_service_vehicle_record(addEditServiceVehicleRecord))

            props.setVisible(false)

        } catch (err) {
            message.error(err.message || `Error`)
        }
    }

    const handleOnCancel = () => {

        props.setVisible(false)
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }

    const getEstimatedOdometer = vid => {
        if (!vid) return ``
        const vehicle = vehicles.byVID[vid]
        if (!vehicle) return

        const lastProcVehicleASLogs = vehicleASLogs.byVID[vid].find(vas => vas.date === moment(servicedDate).startOf('day').subtract(1, 'days').utc().valueOf())

        const totalMileage = (lastProcVehicleASLogs && lastProcVehicleASLogs.totalMileage) || 0

        if(totalMileage !== lastProcTotalMileage)
            setProcTotalMileage(totalMileage)

        return `Currently estimated odometer for ${vehicle.vehiclePlate}: ${totalMileage.toFixed(2)} km`
    }

    const getServiceTypeHelp = (vid) => {
        if (!vid) return ``

        const vehicle = vehicles.byVID[vid]
        const afterSalesLog = afterSalesLogs.byVID[vid] || []

        const latestServicedStatus = afterSalesLog
            .filter(log => log.afterSalesStatus.split('_')[1] === AFTERSALES_WORKFLOW_STATUS[2])
            .reduce((max, x) => (
                (
                    max
                    &&
                    max.hasOwnProperty('afterSalesStatus')
                    &&
                    x.afterSalesStatus.split('_')[0].split('S')[1] > max.afterSalesStatus.split('_')[0].split('S')[1] ? x : max
                )
                ||
                x
            ), undefined)

        const latestServiceCycle = latestServicedStatus ? parseInt(latestServicedStatus.afterSalesStatus.split('_')[0].split('S')[1]) : 0

        const nextEligibleServiceCycle = Object.keys(serviceConfigurations.byServiceCycle).filter(sc => latestServiceCycle  > sc)

        if(latestServicedStatus) {
            if(nextEligibleServiceCycle.length) {
                return `${vehicle.vehiclePlate} had been through S${latestServiceCycle} SERVICE on ${parseDate(latestServicedStatus.createdAt)}. It is eligible for S${latestServiceCycle+1} Service`
            } else {
                return `${vehicle.vehiclePlate} had been through S${latestServiceCycle} SERVICE on ${parseDate(latestServicedStatus.createdAt)}. It is not eligible for any service`
                
            }
        }
    }

    return (
        <Modal
          title="Add Service Vehicle Record"
          visible={props.visible}
          okText='Add'
          onOk={handleOnSumbit}
          onCancel={handleOnCancel}
          width={1000}
        >
            <div style={{ padding: '30px 20px 0px 20px' }}>
                <Form className='login-form'>

                    <FormItem
                        label="Vehicle"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        required={true}
                        help={`Only vehicles which are assigned to customers are available`}
                    >
                        <Select
                            // disabled={isEdit}
                            placeholder="Select vehicle"
                            value={selectedVID && vehicles.byVID[selectedVID].vehiclePlate}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            onChange={value =>
                                setSelectedVID(value)
                            }
                        >
                            {
                                Object.values(vehicles.byVID)
                                    .map((vehicle, i) => {
                                        const { 
                                            vehiclePlate, 
                                            vid, 
                                            cid 
                                        } = vehicle

                                        return (
                                            <Select.Option
                                                key={vid}
                                                value={vid}
                                                disabled={!cid}
                                            >
                                                {vehiclePlate}
                                            </Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </FormItem>

                    <FormItem
                        label="Service Type"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        required={true}
                        help={getServiceTypeHelp(selectedVID)}
                    >
                        <Select
                            disabled={!selectedVID}
                            placeholder="Select a service type"
                            value={servicedStatus}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={value => {
                                setServicedStatus(value)
                            }}
                        >
                            {
                                Object.values(serviceConfigurations.byServiceCycle)
                                .map(x => {

                                    const afterSalesLog = afterSalesLogs.byVID[selectedVID] || []
                                    const disabled = afterSalesLog.find(log => log.afterSalesStatus === `${x.serviceStatus}_SERVICE`)

                                    return (
                                        <Select.Option
                                            disabled={disabled}
                                            key={x.serviceStatus}
                                            value={x.serviceStatus}
                                        >
                                            {x.serviceStatus}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </FormItem>

                    <FormItem
                        label='Service Date'
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        required={true}
                    >
                        <DatePicker 
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                            onChange={value => setServicedDate(value.valueOf())} 
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </FormItem>

                    <FormItem
                        label='Actual Odometer (km)'
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        required={true}
                        help={getEstimatedOdometer(selectedVID)}
                    >
                        <InputNumber
                            placeholder={`Enter actual odometer`}
                            disabled={!selectedVID}
                            style={{ width: 200 }}
                            value={actualTotalMileage}
                            onChange={value =>
                                setActualTotalMileage(value)
                            }
                        />
                    </FormItem>

                    <FormItem
                        label='Next Servicing Odometer (km)'
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        required={true}
                    >
                        {servicedStatus && serviceConfigurations.byServiceCycle[servicedStatus.split('S')[1]].nextMileage}
                    </FormItem>

                    <FormItem
                        label="Next Service Date"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                    >
                        {
                            (
                                selectedVID 
                                && servicedStatus 
                                && vehicles.byVID[selectedVID].hasOwnProperty('aSProfile')
                                && vehicles.byVID[selectedVID].aSProfile.hasOwnProperty('activatedAt')
                                && parseTime(moment(vehicles.byVID[selectedVID].aSProfile.activatedAt).add(serviceConfigurations.byServiceCycle[servicedStatus.split('S')[1]].nextAppointment, 'day').valueOf())
                            )
                            || `NOT AVAILABLE`
                        }
                    </FormItem>
                </Form>
            </div>
        </Modal>
    )
}

export default ServiceVehicleRecordModal;