import React, { Component } from "react";

import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";

// Maps
import MapDefault from "../components/Maps/MapDefault";
import MapEvent from "../components/Maps/MapEvent";
import MapTransit from "../components/Maps/MapTransit";

class PagesWithMaps extends Component {
    renderMap = pathname => {
        switch (pathname) {
            case '/EventLog': {
                return <MapEvent/>
            }

            case '/RoutePlayback': {
                return <MapTransit/>
            }
            
            default: {
                return <MapDefault/>
            }
        }
    }

    render() {
        return (
            <div>{this.renderMap(this.props.location.pathname)}</div>
        );
    }
}

export default showSecondarySidebar(true)(PagesWithMaps);