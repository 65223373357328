import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';

import Page from "../../components/Page";
import parseTime from '../../utils/parseTime';
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { tableColumnSearchProps } from '../../components/TableColumnSearchProps';
import {
    // STATUS_TYPES,
    LOADING_STATUS
} from "../../constants";
import {
    Form,
    Table,
    Button,
    Select,
    Skeleton,
    DatePicker,
} from "antd";

// const defaultFilter = "ALL";

const VehicleAverageMileageLog = (props) => {
    const user = useSelector(state => state.user)
    const vehicles = useSelector(state => state.vehicles)
    const vehicleASLogs = useSelector(state => state.vehicleASLogs)
    const style = useSelector(state => state.style)

    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [dataSource, setDataSource] = useState([])
    const [selectedVid, setSelectedVid] = useState()

    const [ searchText, setSearchText ] = useState({})
    const [ searchedColumn, setSearchedColumn ] = useState({})

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
        setSearchedColumn({
            searchedColumn,
            [dataIndex]: dataIndex
        })
    };

    const handleReset = (dataIndex, clearFilters) => {
        clearFilters();
        setSearchText({
            ...searchText,
            [dataIndex]: ''
        })
    };

    const setUpDataSource = useCallback(() => {
        const dataSource = Object.values(vehicleASLogs.byVID)
            .reduce((a, b) => [...a, ...b], [])
            .filter((log) => {
                if (!selectedVid) return true
                return selectedVid === log.vid
            })
            .filter(log => {
                if (startTime && endTime) return startTime.valueOf() <= log.createdAt && endTime.valueOf() >= log.createdAt
                if (startTime) return startTime.valueOf() <= log.createdAt
                if (endTime) return endTime.valueOf() >= log.createdAt
                return true
            })
            .map((log, i) => {
                const currVehicle = vehicles.byVID[log.vid];

                return {
                    key: i,
                    vehiclePlate: (currVehicle && currVehicle.vehiclePlate )|| "-",
                    vehicleModel: (currVehicle && currVehicle.vehicleModel) || "-",
                    pairedDevice: (currVehicle && currVehicle.dvid && currVehicle.dvid.split("#")[1]) || "-",
                    mileage: log.dailyMileage,
                    accumulatedMileage: log.totalMileage,
                    averageMileage: log.averageMileage,
                    createdAt: log.createdAt,
                }

                // return {
                //     key: i,
                //     vehiclePlate: (currVehicle && currVehicle.vehiclePlate )|| "-",
                //     engineNumber: (currVehicle && currVehicle.chassisNumber) || "-",
                //     pairedDevice: (currVehicle && currVehicle.dvid.split("#")[1]) || "-",
                //     mileage: log.dailyMileage,
                //     accumulatedMileage: log.totalMileage,
                //     averageMileage: log.averageMileage,
                //     daysBeforeS2: 0,
                //     afterSalesStatus: STATUS_TYPES[currVehicle.afterSalesStatus],
                // }
            })

        setDataSource(dataSource)
    }, [vehicles, vehicleASLogs, startTime, endTime, selectedVid])

    useEffect(() => {
        setUpDataSource()
    }, [setUpDataSource])

    // Need to rerender when search text changes so is placed in render
    const columns = [
        {
            title: "Vehicle Plate",
            dataIndex: "vehiclePlate",
            ...tableColumnSearchProps("vehiclePlate", searchText, searchedColumn, handleSearch, handleReset),
        },

        {
            title: "Vehicle Model",
            dataIndex: "vehicleModel",
            // ...tableColumnSearchProps("vehicleModel", searchText, this.onSearchTextChange, this.onSearchTextReset),
        },

        {
            title: "Device IMEI",
            dataIndex: "pairedDevice",
            ...tableColumnSearchProps("pairedDevice", searchText, searchedColumn, handleSearch, handleReset),
        },

        {
            title: "Mileage",
            dataIndex: "mileage",
            render: mileage => `${(!isNaN(mileage) && Math.ceil(mileage)) || 0} km`
        },

        {
            title: "Accumulated Mileage",
            dataIndex: "accumulatedMileage",
            render: accumulatedMileage => `${(!isNaN(accumulatedMileage) && Math.ceil(accumulatedMileage)) || 0} km`
        },

        {
            title: "Average Mileage",
            dataIndex: "averageMileage",
            render: averageMileage => `${(!isNaN(averageMileage) && Math.ceil(averageMileage)) || 0} km`
        },

        {
            title: "Created Time",
            dataIndex: "createdAt",
            render: createdAt => parseTime(createdAt)
        },
    ]

    // const columns = [
    //     {
    //         title: "Vehicle Plate",
    //         dataIndex: "vehiclePlate",
    //         ...tableColumnSearchProps("vehiclePlate"),
    //     },

    //     {
    //         title: "Engine Number",
    //         dataIndex: "engineNumber",
    //         ...tableColumnSearchProps("engineNumber"),
    //     },

    //     {
    //         title: "Device IMEI",
    //         dataIndex: "pairedDevice",
    //         ...tableColumnSearchProps("pairedDevice"),
    //     },

    //     {
    //         title: "Mileage",
    //         dataIndex: "mileage",
    //         render: mileage => `${(!isNaN(mileage) && Math.ceil(mileage)) || 0} km`
    //     },

    //     {
    //         title: "Accumulated Mileage",
    //         dataIndex: "accumulatedMileage",
    //         render: accumulatedMileage => `${(!isNaN(accumulatedMileage) && Math.ceil(accumulatedMileage)) || 0} km`
    //     },

    //     {
    //         title: "Average Mileage",
    //         dataIndex: "averageMileage",
    //         render: averageMileage => `${(!isNaN(averageMileage) && Math.ceil(averageMileage)) || 0} km`
    //     },

    //     {
    //         title: "Days Before S2",
    //         dataIndex: "daysBeforeS2",
    //     },

    //     {
    //         title: "After Sales Status",
    //         dataIndex: "afterSalesStatus",
    //     },
    // ]

    const excelname =
        startTime && endTime ?
            `${selectedVid && vehicles.byVID[selectedVid] && vehicles.byVID[selectedVid].vehiclePlate ? vehicles.byVID[selectedVid].vehiclePlate : 'ALL'} ${startTime.format('DD-MM-YY')} - ${endTime.format('DD-MM-YY')}` :
            `${selectedVid && vehicles.byVID[selectedVid] && vehicles.byVID[selectedVid].vehiclePlate ? vehicles.byVID[selectedVid].vehiclePlate : 'ALL'}`;

    return (
        <Page title="Vehicle Average Mileage Log">
            <Form layout='inline' style={{ marginBottom: 5 }}>
                <Form.Item>
                    <span>Start time: </span>

                    <DatePicker
                        showTime
                        defaultValue={startTime}
                        onChange={(value) => setStartTime(value)}
                        style={{ width: 200 }}
                    />

                    <span style={{ marginLeft: '10px' }}>End time: </span>

                    <DatePicker
                        showTime
                        defaultValue={endTime}
                        onChange={(value) => setEndTime(value)}
                        style={{ width: 200 }}
                    />
                </Form.Item>

                <Form.Item>
                    <span style={{ marginLeft: '10px' }}>Vehicle Plate: </span>

                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select a vehicle"
                        optionFilterProp="children"
                        onChange={(selectedVid, key) => {
                            setSelectedVid(selectedVid)
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            Object.values(vehicles.byVID).map((vehicle, i) => {
                                return <Select.Option
                                    key={vehicle.vid}
                                    value={vehicle.vid}
                                >
                                    {vehicle.vehiclePlate}
                                </Select.Option>

                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={style.isLoadingSubmit}
                        onClick={() => setUpDataSource()}
                        style={{ marginLeft: '15px' }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: 10,
                }}
            >
                <ExportExcelButton
                    disabled={dataSource.length === 0}
                    filename={excelname}
                    sheetData={dataSource}
                    sheetName={`Vehicle Average Mileage Log`}
                    sheetRow={
                        columns
                            .filter(col => col.title !== `Actions`)
                            .map(col => {
                                switch (col.dataIndex) {
                                    case 'mileage':
                                    case 'accumulatedMileage':
                                    case 'averageMileage':
                                    case 'createdAt':
                                        return {
                                            label: col.title,
                                            formatter: value => col.render(value[col.dataIndex])
                                        }

                                    default:
                                        return {
                                            label: col.title,
                                            formatter: value => value[col.dataIndex]
                                        }
                                }
                            })
                    }
                />
            </div>

            {(
                user.loadingStatus === LOADING_STATUS.LOADING
                || vehicles.loadingStatus === LOADING_STATUS.LOADING
                || vehicleASLogs.loadingStatus === LOADING_STATUS.LOADING
            ) ?
                <Skeleton active /> :
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={true}
                    scroll={{
                        x: columns && columns.length * 150,
                        y: window.innerHeight
                    }}
                />
            }
        </Page>
    )
}
export default showSecondarySidebar(false)(VehicleAverageMileageLog);