import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getDeviceLog = (dvidList, startTime, endTime) => (
    fetch(`${api}/devicelog/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dvids" : dvidList, // Filter returned data by DVID (Setting this to an empty array would return no device logs)
            "startTime": startTime, // Filter returned data by Start Time (Must also have endTime)
            "endTime": endTime, // Filter returned data by End Time (Must also have startTime)
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const getDeviceLog_Debug = (dvidList, startTime, endTime) => (
    fetch(`${api}/devicelog/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dvids" : dvidList, // Filter returned data by DVID (Setting this to an empty array would return no device logs)
            "startTime": startTime, // Filter returned data by Start Time (Must also have endTime)
            "endTime": endTime, // Filter returned data by End Time (Must also have startTime)
            "engineStatus": 1, // Must be set to 1 to get debug device log
            "gpsStatus": null,
        })
    })
    .then(res => res.json())
    .then(data => data)
)