function parseDays(duration) {
    const MS_IN_DAYS = 24 * 60 * 60 * 1000;

    if (isNaN(duration)) return `-`

    const days = Math.ceil(duration / MS_IN_DAYS);

    return days
}

export default parseDays