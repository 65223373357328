import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import parseTime from '../../utils/parseTime';
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import {
    STATUS_TYPES,
    LOADING_STATUS,
} from "../../constants";
import {
    Tag,
    Form,
    Table,
    Button,
    Select,
    Skeleton,
} from "antd";

// Redux Actions
import { get_after_sales_log } from '../../services/redux/actions/afterSalesLogs';

const defaultFilter = "ALL";

class AfterSalesStatusLog extends React.Component {
    state = {
        dataSource: [],

        // Filter Stuff
        selectedStatusType: defaultFilter,
        selectedVehiclePlate: defaultFilter,

        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        vehicleModelList: [],
    }

    onSearchTextReset = () => { this.setState({ searchText: '' }) };
    onSearchTextChange = (searchText) => { this.setState({ searchText }) };

    returnDataSource = () => {
        const newDataSource = Object.values(this.props.vehicles.byVID)
            .map(vehicle => {
                if (!vehicle) return 0
                if (!this.props.afterSalesLogs.byVID[vehicle.vid]) return {
                    key: vehicle.vid,
                    vehiclePlate: vehicle.vehiclePlate
                }

                const logs = Object.values(this.props.afterSalesLogs.byVID[vehicle.vid])

                let logsByAfterSalesStatus = {}

                logs.map(log => {
                    if (logsByAfterSalesStatus[log.afterSalesStatus]) {
                        if (logsByAfterSalesStatus[log.afterSalesStatus] < log.createdAt) {
                            logsByAfterSalesStatus[log.afterSalesStatus] = log.createdAt
                        }
                    } else {
                        logsByAfterSalesStatus[log.afterSalesStatus] = log.createdAt
                    }
                    return 1
                })

                return {
                    ...logsByAfterSalesStatus,
                    key: vehicle.vid,
                    vehiclePlate: vehicle.vehiclePlate
                }
            })
            .filter(l => l)

        // console.log("New Data Source:", newDataSource);

        return newDataSource;
    }

    filterDataSource = () => {
        const filteredDataSource = this.returnDataSource()
        .filter((currData) => this.state.selectedVehiclePlate === defaultFilter || currData.vehiclePlate === this.state.selectedVehiclePlate);

        // console.log("Filtered Data Source:", filteredDataSource);

        this.setState({
            dataSource: filteredDataSource,
        })
    }

    componentDidMount = () => {
        this.setState({
            dataSource: this.returnDataSource(),
        })

        /**
         * pseudo refresh
         */
        const vids = Object.keys(this.props.vehicles.byVID)

        this.props.dispatch(get_after_sales_log(vids))
    }


    componentDidUpdate = (prevProps) => {
        if ((JSON.stringify(prevProps.vehicles) !== JSON.stringify(this.props.vehicles)) || (JSON.stringify(prevProps.afterSalesLogs) !== JSON.stringify(this.props.afterSalesLogs))) {
            this.setState({
                dataSource: this.returnDataSource(),
            })
        }
    }

    render() {
        const {
            dataSource,
            selectedVehiclePlate,
        } = this.state;

        const excelname = `After Sales Status Log (${moment().format('YYYY MM DD')})`;

        const columns = [
            {
                title: `Vehicle Plate`,
                dataIndex: `vehiclePlate`,
                fixed: 'left'
            },
            ...Object.keys(STATUS_TYPES).map(key => {
                const label = STATUS_TYPES[key]
                return {
                    title: label,
                    dataIndex: key,
                    render: (createdAt) => {
                        if (!createdAt) return
                        return <Tag key = {createdAt}>{parseTime(createdAt)}</Tag>
                    }
                }
            })
        ];

        return (
            <Page title = "After Sales Status Log">
                <Form layout = 'inline' style = {{ marginBottom: 5 }}>
                    {/* <Form.Item>
                        <span>Start time: </span>

                        <DatePicker
                            style = {{ width: 200 }}
                            showTime
                            defaultValue = {startTime}
                            onChange = {(value) => this.setState({ startTime: value })}
                        />

                        <span style = {{ marginLeft: '10px' }}>End time: </span>

                        <DatePicker
                            style = {{ width: 200 }}
                            showTime
                            defaultValue = {endTime}
                            onChange = {(value) => this.setState({ endTime: value })}
                        />
                    </Form.Item>

                    <Form.Item>
                        <span style = {{ marginLeft: '10px' }}>Status: </span>

                        <AutoComplete
                            placeholder = 'Select a status'
                            value = {selectedStatusType}
                            dataSource = {[defaultFilter, ...Object.values(STATUS_TYPES)
                                .filter(statusType => {
                                    return ![STATUS_TYPES.CREATED, STATUS_TYPES.INITIATED, STATUS_TYPES.COMPLETED].includes(statusType)
                                })
                            ]}
                            onChange = {(newSelectedStatusType) => {
                                this.setState({
                                    selectedStatusType: newSelectedStatusType,
                                })
                            }}
                            style = {{ width: 150 }}
                        />
                    </Form.Item> */}

                    {/* <Form.Item>
                        <span style = {{ marginLeft: '10px' }}>Vehicle Model: </span>

                        <AutoComplete
                            placeholder = 'Select a vehicle model'
                            value = {selectedVehiclePlate}
                            dataSource = {Object.values(VEHICLE_MODELS)}
                            onChange = {(selectedVehiclePlate) => {
                                this.setState({ selectedVehiclePlate })
                            }}
                            style = {{ width: 150 }}
                        />
                    </Form.Item> */}

                    <Form.Item>
                        <span style = {{ marginLeft: '10px' }}>Vehicle Plate: </span>

                        <Select
                            showSearch
                            // allowClear
                            style = {{ width: 200 }}
                            placeholder = "Select a vehicle plate"
                            optionFilterProp = "children"
                            value = {this.state.selectedVehiclePlate}
                            onChange = {(selectedVehiclePlate) => {
                                // console.log("New Selected Vehicle Plate:", selectedVehiclePlate);

                                this.setState({ selectedVehiclePlate })
                            }}
                            filterOption = {(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option
                                key = {defaultFilter}
                                value = {defaultFilter}
                            >
                                {defaultFilter}
                            </Select.Option>

                            {
                                Object.values(this.props.vehicles.byVID)
                                .sort((a, b) => {
                                    a = a.vehiclePlate;
                                    b = b.vehiclePlate;
                            
                                    if (a < b) return -1;
                                    if (a > b) return 1;
                                    return 0;
                                })
                                .map((vehicle, i) => {
                                    return (
                                        <Select.Option
                                            key = {vehicle.vid}
                                            value = {vehicle.vehiclePlate}
                                        >
                                            {vehicle.vehiclePlate}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading = {this.props.style.isLoadingSubmit}
                            disabled = {!(Object.keys(this.props.vehicles.byVID).length > 0 && selectedVehiclePlate)}
                            onClick = {() => {
                                this.filterDataSource();
                            }}
                            style = {{ marginLeft: '15px' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

                <div
                    style = {{
                        display: "flex",
                        justifyContent: "flex-end",

                        marginBottom: 10,
                    }}
                >
                    <ExportExcelButton
                        disabled = {dataSource.length === 0}
                        filename = {excelname}
                        sheetData = {dataSource}
                        sheetName = {`Service Reminder Record`}
                        sheetRow = {
                            columns
                                .filter(col => col.title !== `Actions`)
                                .map(col => {
                                    if (Object.keys(STATUS_TYPES).includes(col.dataIndex)) {
                                        return {
                                            label: col.title,
                                            formatter: value => parseTime(value[col.dataIndex])
                                        }
                                    }
                                    return {
                                        label: col.title,
                                        formatter: value => value[col.dataIndex]
                                    }
                                })
                        }
                    />
                </div>

                {
                    (
                        this.props.afterSalesLogs.loadingStatus === LOADING_STATUS.LOADING
                        || this.props.customers.loadingStatus === LOADING_STATUS.LOADING
                        || this.props.vehicles.loadingStatus === LOADING_STATUS.LOADING
                    ) ?
                        <Skeleton active /> :
                        <Table
                            columns = {columns}
                            dataSource = {dataSource}
                            pagination = {true}
                            scroll = {{
                                x: columns && columns.length * 200,
                                y: window.innerHeight
                            }}
                        />
                }
            </Page>
        )
    };
}

const mapStateToProps = (state) => ({
    vehicles: state.vehicles,
    customers: state.customers,
    afterSalesLogs: state.afterSalesLogs,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(AfterSalesStatusLog));