import * as ActionTypes from "../action-types";

const defaultState = {
    byServiceCycle: {}
}

export const serviceConfigurations = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_SERVICE_CONFIGURATION: {
            const serviceConfigurations = action.serviceConfigurations

            if(serviceConfigurations.length) {
                serviceConfigurations.map(sc => {
                    newState.byServiceCycle[sc.serviceCycle] = sc
                    return 0;
                })
            }

            return newState
        }

        default: {
            return state;
        }
    }
};
