import React from "react";
import { connect } from "react-redux";

import { 
    PROGRESS_TYPES,
    LOADING_STATUS
 } from "../../../constants";
import {
    Modal, 
    Table,
    Button, 
    message,
} from "antd";

// Redux Actions
import { 
    set_up_unassign_progress,
    unassign_devices_from_vehicles 
} from "../../../services/redux/actions/vehicles";

const modalHeight = 400;

class PopUpUnpair extends React.Component {
    state = {
        dvidList: [],
        dataSource: [],
        
        isLoading: false,
    }

    enableLoadingModal = () => {
        if (this.state.dvidList.length > 0) {
            // console.log(`
            //     Allocating Vehicle:
            //     UID - ${this.props.user.uid}
            //     VID - ${this.props.selectedVIDList}
            //     VGIDs - ${vgidList}`
            // );

            this.setState({
                isLoading: true,
            },
                () => {
                    // Set up allocate progress first
                    this.state.dvidList.forEach((currDVID) => {
                        this.props.dispatch(set_up_unassign_progress(currDVID));
                    })

                    this.props.dispatch(unassign_devices_from_vehicles(this.state.dvidList, this.props.user.uid));
                }
            )
        }
        else {
            message.error("Please select an area or a outlet for this vehicle.");
        }
    }

    componentDidMount = () => {
        // console.log("Unpairing device from", this.props.selectedVID);

        if (this.props.selectedVIDList.length > 0) {
            const { 
                vehicles, 
                selectedVIDList, 
            } = this.props;

            this.setState({
                dvidList: selectedVIDList.map((selectedVID) => vehicles.byVID[selectedVID].dvid), // Need to use an array for unassign API
                dataSource: 
                    selectedVIDList.map((selectedVID) => ({
                        key: selectedVID,
                        dvid: vehicles.byVID[selectedVID].dvid,
                        vehiclePlate: vehicles.byVID[selectedVID].vehiclePlate,
                    }))
            },
                () => {
                    // console.log("New State:", this.state);
                    // console.log("New Data Source:", this.state.dataSource);
                }
            )
        }
    }

    render() {
        const { vehicles } = this.props;

        const columns = [
            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
            },
    
            {
                title: "Device ID",
                dataIndex: "dvid",
            },
        ]

        return (
            <div>
                {
                    !this.state.isLoading ?
                        <Modal
                            title = "Unpair Device"
                            visible = {true}
                            closable = {false}
                            okButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                            cancelButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                            onOk = {() => this.enableLoadingModal()}
                            onCancel = {() => this.props.onCloseModal()}
                        >
                            <b 
                                style = {{
                                    display: "flex",
                                    justifyContent: "center",
        
                                    marginBottom: 20,
                                }}
                            >
                                Are you sure you want to unpair this device?
                            </b>
                            
                            <Table 
                                columns = {columns}
                                dataSource = {this.state.dataSource}
                                pagination = {true}
                            />
                        </Modal> :
                        <Modal
                            title = "Unpair Progress"
                            visible = {true}
                            closable = {false}
                            width = {800}
                            bodyStyle = {{ height: modalHeight }}
                            footer = {[
                                <Button 
                                    key = "submit" 
                                    loading = {this.props.vehicles.loadingStatus === LOADING_STATUS.LOADING} 
                                    onClick = {() => this.props.onCloseModal()}
                                >
                                    Finish
                                </Button>,
                            ]}
                        >        
                            <Table
                                columns = {[
                                    ...columns,

                                    {
                                        title: "Allocated Time",
                                        dataIndex: "allocatedTime"
                                    },
        
                                    {
                                        title: "Status",
                                        dataIndex: "status",
                                        render: (text) => 
                                            <span 
                                                style = {{ 
                                                    color: text === PROGRESS_TYPES.PENDING ? 
                                                        "blue" :
                                                        text === PROGRESS_TYPES.SUCCESS ? 
                                                            "green" :
                                                            text === PROGRESS_TYPES.FAILED && 
                                                                "red"
                                                }}
                                            >
                                                {text}
                                            </span>,
                                    },
        
                                    {
                                        title: "Remarks",
                                        dataIndex: "remarks",
                                        width: 236
                                    },
                                ]}
                                dataSource = {Object.keys(vehicles.modalProgress).map((currDVID) => vehicles.modalProgress[currDVID])}
                                pagination = {false}
                                style = {{
                                    height: modalHeight - 48, // Modal default padding is 24
                                    overflowY: "auto"
                                }}
                            />
                        </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default connect(mapStateToProps)(PopUpUnpair);