import React from "react";
import { connect } from "react-redux";

import SMSTable from "./SMSTable";
import PhoneTable from "./PhoneTable";
import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { Tabs } from "antd";
import {
    STATUS_TYPES,
    REMINDER_TYPES,
    SERVICE_REMINDER_STATUSES,
} from "../../constants";

class ServiceReminderDashboard extends React.Component {
    state = {}

    setUpDataSource = () => {
        const dataSource = Object.values(this.props.serviceReminders.bySRID)
            .filter((serviceReminder) => {
                /**Only shows incompleted serviceReminder */
                return serviceReminder.reminderStatus === SERVICE_REMINDER_STATUSES.INCOMPLETE
            })

        const phoneDatasource = dataSource.filter(serviceReminder => {
            const afterSalesStatus = serviceReminder.afterSalesStatus.replace('_', ' ');

            return [STATUS_TYPES.S1_CALL, STATUS_TYPES.S2_CALL].includes(afterSalesStatus);
        })

        const smsDatasource = dataSource.filter(serviceReminder => {
            const afterSalesStatus = serviceReminder.afterSalesStatus.replace('_', ' ');

            return [STATUS_TYPES.S1_SMS, STATUS_TYPES.S2_SMS, STATUS_TYPES.S2_COURTESY].includes(afterSalesStatus);
        })

        window.dataSource = dataSource

        this.setState({
            smsDatasource,
            phoneDatasource,
        })
    }

    componentDidMount = () => {
        this.setUpDataSource();
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.serviceReminders) !== JSON.stringify(this.props.serviceReminders)) {
            this.setUpDataSource();
        }
    }

    render() {
        const {
            smsDatasource = [],
            phoneDatasource = [],
        } = this.state;

        return (
            <Page title = "Service Reminder Dashboard">
                <Tabs
                    type = "card"
                    defaultActiveKey = {REMINDER_TYPES.SMS}
                >
                    <Tabs.TabPane tab = {`SMS (${(smsDatasource && smsDatasource.length) || 0})`} key = {REMINDER_TYPES.SMS}>
                        <SMSTable smsDatasource = {smsDatasource} />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab = {`Phone Call (${(phoneDatasource && phoneDatasource.length) || 0})`} key = {REMINDER_TYPES.PHONE}>
                        <PhoneTable phoneDatasource = {phoneDatasource} />
                    </Tabs.TabPane>
                </Tabs>
            </Page>
        )
    };
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    router: state.router,
    vehicles: state.vehicles,
    customers: state.customers,
    serviceReminders: state.serviceReminders,
    serviceReminderLogs: state.serviceReminderLogs,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(ServiceReminderDashboard));
