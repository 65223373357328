const config = {
    project: process.env.REACT_APP_PROJECT_MODE,
    version: process.env.REACT_APP_PROJECT_VERSION,
    api: process.env.REACT_APP_FE_API_GATEWAY,
    googleMapAPI: process.env.REACT_APP_GOOGLE_MAP_API,
    firebaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
};

module.exports = {
    config
};
