import React, { useRef, useState, useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

import moment from "moment";

import { message } from "antd";
import {
    Marker,
    Polyline,
    InfoWindow,
} from '@react-google-maps/api';

// Redux Actions
import { set_map_action } from "../../../services/redux/actions/mapControl";
import { clear_device_log_selected_dvid } from "../../../services/redux/actions/deviceLogs";

import "../gmaps.css";

// Get prevProps
function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

const DeviceLogPolylines = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    // State Stuff
    const [logList, setLogList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDisplayingInfoWindow, setIsDisplayingInfoWindow] = useState(false); // Starts as false first. Will be set to true when playback.isPlaying is set to true

    // Redux Props Stuff
    const playback = useSelector(state => state.playback);
    const mapControl = useSelector(state => state.mapControl);
    const deviceLogs = useSelector(state => state.deviceLogs);
    
    const prevPlayback = usePrevious(playback);
    const prevDeviceLogs = usePrevious(deviceLogs);

    const pathParse = (logData) => {
        // console.log("Path Parse Log Data:", logData);
    
        return({
            deviceTime: logData.deviceTime,
            lat: parseFloat(logData.location.lat),
            lng: parseFloat(logData.location.lng),
        })
    }

    const getPolylines = () => {
        return (
            logList.length ? 
                logList.map((currPoint, currIndex) => {
                    if (currIndex < logList.length - 1) {
                        return(
                            <Polyline
                                key = {currIndex}
                                path = {[pathParse(currPoint), pathParse(logList[currIndex + 1])]}
                                onClick = {() => {
                                    // console.log("Device Log Polyline Clicked!");

                                    setSelectedIndex(currIndex);
                                }}
                                options = {{
                                    strokeWeight: 2,
                                    strokeColor: 
                                        // currPoint.devicePackage === "gps#memory" ? 
                                        //     "#808080" : // Grey
                                        //     tripRecord.selectedTrID
                                        //     && tripRecord.byTrID[tripRecord.selectedTrID]
                                        //     && currPoint.deviceTime >= tripRecord.byTrID[tripRecord.selectedTrID].startTime
                                        //     && currPoint.deviceTime <= tripRecord.byTrID[tripRecord.selectedTrID].endTime ? 
                                        //         "#0000FF" : // Blue
                                        //         "#ff2527", // Red
                                        "#ff2527",
                                    icons: [{
                                        offset: "3%",
                                        icon: {
                                            scale: 2,
                                            fillOpacity: 1,
                                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        },
                                    }]
                                }}
                            />
                        )
                    } 
                    else {
                        return null;
                    }
                }) :
                null
        );
    }

    // const getInfoWindow = () => {
    //     return (
    //         logList.length       
    //         && !isNaN(selectedIndex) &&
    //             <InfoWindow
    //                 onCloseClick = {() => setSelectedIndex(null)}
    //                 position = {{
    //                     lat: parseFloat(logList[selectedIndex].location.lat),
    //                     lng: parseFloat(logList[selectedIndex].location.lng)
    //                 }}
    //             >
    //                 <div style = {{ width: "250px" }}>
    //                     <h3>{moment(logList[selectedIndex].deviceTime).format("YYYY/MM/DD HH:mm:ss")}</h3>
        
    //                     <p>
    //                         Engine Status: {logList[selectedIndex].engineStatus} <br/>
    //                         Speed: {logList[selectedIndex].speed.toFixed(1)} KM/H <br/>
    //                         Heading: {logList[selectedIndex].heading} <br/>
    //                     </p>
        
    //                     <span>
    //                         <a 
    //                             href = {`https://www.google.com.my/maps/place/${parseFloat(logList[selectedIndex].location.lat)},${parseFloat(logList[selectedIndex].location.lng)}`}
    //                             target = "_blank"
    //                             rel = "noopener noreferrer"
    //                         >
    //                             View On Maps
    //                         </a>
    //                     </span>
    //                 </div>
    //             </InfoWindow>
    //     );   
    // }

    // Marker will still appear even when playback is paused just to show info window
    const getPlaybackMarker = () => {
        const currLog = logList.length && !isNaN(playback.playbackIndex) && logList[playback.playbackIndex] ? logList[playback.playbackIndex] : null;

        return (
            currLog && 
                <Marker 
                    onClick = {() => props.togglePlaybackMarker(true)}
                    position = {{
                        lat: parseFloat(currLog.location.lat),
                        lng: parseFloat(currLog.location.lng)
                    }}
                    icon = {{
                        fillOpacity: 1,
                        fillColor: "gold",

                        scale: playback.isPlaying ? 10 : 0, // Marker will still appear even when playback is paused just to show info window
                        strokeWeight: 3,
                        strokeColor: "gold",

                        path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                    }}
                >
                    {
                        isDisplayingInfoWindow &&
                            <InfoWindow 
                                position = {{
                                    lat: parseFloat(currLog.location.lat),
                                    lng: parseFloat(currLog.location.lng)
                                }}
                                onCloseClick = {() => {setIsDisplayingInfoWindow(false)}}
                            >
                                <div style = {{ width: "250px" }}>
                                    <h3>{moment(currLog.deviceTime).format("YYYY/MM/DD HH:mm:ss")}</h3>
        
                                    <p>
                                        Engine Status: {currLog.engineStatus} <br></br>
                                        Speed: {currLog.speed.toFixed(1)} KM/H <br></br>
                                        Heading: {currLog.heading + "\xB0"} <br></br>
                                    </p>
        
                                    <br/>
        
                                    <span>
                                        <a
                                            href = {`https://www.google.com.my/maps/place/${parseFloat(currLog.location.lat)},${parseFloat(currLog.location.lng)}`}
                                            target = "_blank"
                                            rel = "noopener noreferrer"
                                        >
                                            View On Maps
                                        </a>
                                    </span>
                                </div>
                            </InfoWindow>
                    }
                </Marker>
        );
    }

    // ComponentDidUpdate()
    useEffect(() => {
        if (JSON.stringify(prevDeviceLogs) !== JSON.stringify(deviceLogs) && deviceLogs.selectedDVID && deviceLogs.byDVID[deviceLogs.selectedDVID]) {
            setLogList(
                // Matches RoutePlayback's playMarker()'s deviceLogList
                deviceLogs.byDVID[deviceLogs.selectedDVID]
                .filter((currLog) => currLog.devicePackage === "gps#latest")
                .sort((a, b) => {
                    a = a.deviceTime;
                    b = b.deviceTime;
            
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                })
            );
        }

        if (
            selectedIndex
            && logList 
            && logList[selectedIndex]
            && mapControl.currAction === 1
        ) {
            const selectedLog = deviceLogs.byDVID[deviceLogs.selectedDVID][selectedIndex];

            if (selectedLog.location && selectedLog.location.lat && selectedLog.location.lng) {    
                if (mapRef) {
                    mapRef.setZoom(20);
                    mapRef.panTo({
                        lat: parseFloat(selectedLog.location.lat),
                        lng: parseFloat(selectedLog.location.lng),
                    });

                    // Do not set map action to 0 here or panning won't finish
                }
            }
            else {
                message.error("No location found for this transit point");

                dispatch(clear_device_log_selected_dvid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }

        // Turn on Info Window whenever isPlaying is changed from false to true
        if (prevPlayback && prevPlayback.isPlaying !== playback.isPlaying && prevPlayback.isPlaying === false && playback.isPlaying === true) {
            // console.log("Test");

            setIsDisplayingInfoWindow(true);
        }
    }, [setLogList, deviceLogs, dispatch, logList, mapControl.currAction, mapRef, playback.isPlaying, prevDeviceLogs, prevPlayback, selectedIndex])

    return (
        <div>
            {getPolylines()}
            {/* {getInfoWindow()} */}
            {getPlaybackMarker()}
        </div>
    )
}

export default DeviceLogPolylines;