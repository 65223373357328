// import * as API from "../../api/vehicles";
import * as ActionTypes from "../action-types/deviceGroups";

// Exported to user action
export const get_device_groups_success = (deviceGroups) => {
    return {
        type: ActionTypes.GET_DEVICE_GROUPS,
        deviceGroups: deviceGroups,
    };
}
export const set_device_groups_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_DEVICE_GROUPS_LOADING_STATUS,
        loadingStatus
    }
}

