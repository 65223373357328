import * as API from "../../api/devices";
import * as ActionTypes from "../action-types/devices";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
// import {
//     setLoadingSubmit,
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'
import { get_device_groups_success, set_device_groups_loading_status } from './deviceGroups';


export const get_devices = (dgids, status, pairedStatus, startTime, endTime) => (dispatch) => {
    dispatch(set_devices_loading_status(LOADING_STATUS.LOADING))
    dispatch(set_device_groups_loading_status(LOADING_STATUS.LOADING))

    API.getDevices(dgids)
        .then(data => {
            // console.log("get_user_devices_and_device_groups_by_dgids data:", data);

            if (data.status !== 200) {
                message.error('Failed To Retrieve User Devices From Database: ' + data.message);
                dispatch(set_devices_loading_status(LOADING_STATUS.FAILED))
                dispatch(set_device_groups_loading_status(LOADING_STATUS.FAILED))
            }
            else {

                let {
                    devices,
                    deviceGroups
                } = data

                devices = filterDevices(devices, status, pairedStatus, startTime, endTime)

                dispatch(get_device_groups_success(deviceGroups))
                dispatch(get_devices_success(devices));
                dispatch(set_devices_loading_status(LOADING_STATUS.SUCCESS))
                dispatch(set_device_groups_loading_status(LOADING_STATUS.SUCCESS))
            }
        })



    function filterDevices(devices, status, pairedStatus, startTime, endTime) {
        return devices
        .map(device=>{
            /**sanitize device */
            if([0, undefined, '-', '0'].includes(device.assignedStatus)){
                device.assignedStatus = 0
            }else if([1, '1'].includes(device.assignedStatus)){
                device.assignedStatus = 1
            }
            return device
        })        
        .filter(device => {
            try {
                if (pairedStatus !== undefined && device.assignedStatus !== pairedStatus) throw new Error(`pairedStatus`)
                if (status !== undefined && device.connectStatus !== status) throw new Error('status')
                if (startTime && device.createdAt < startTime) throw new Error('startTime')
                if (endTime && device.endTime > endTime) throw new Error('endTime')

                return true
            } catch (err) {
                return false
            }
        })
    }
}

export const get_devices_success = (devices) => {
    return {
        type: ActionTypes.GET_DEVICES,
        devices: devices,
    };
}

export const add_device = (uid, newDevice) => (dispatch) => {
    dispatch(set_devices_loading_status(LOADING_STATUS.LOADING))

    API.addDevice(uid, newDevice)
        .then((data) => {
            // console.log("add_device data:", data);

            if (data.status !== 200) {
                message.error('Failed To Add Device: ' + data.message);
                dispatch(set_devices_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Added Device.');

                dispatch(add_device_success(data.device));
                dispatch(goBackToPrev());
                dispatch(set_devices_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

export const add_device_success = (newDevice) => {
    return {
        type: ActionTypes.ADD_DEVICE,
        newDevice: newDevice,
    };
}

export const edit_device = (editedDevice) => (dispatch) => {
    dispatch(set_devices_loading_status(LOADING_STATUS.LOADING))

    API.editDevice(editedDevice)
        .then((data) => {
            // console.log("edit_device data:", data);

            if (data.status !== 200) {
                message.error('Failed To Edit Device: ' + data.message);
                dispatch(set_devices_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                message.info('Successfully Edited Device.');

                dispatch(edit_device_success(data.device));
                dispatch(goBackToPrev());
                dispatch(set_devices_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const edit_device_success = (editedDevice) => {
    return {
        type: ActionTypes.EDIT_DEVICE,
        editedDevice: editedDevice,
    };
}

export const set_selected_dvid = (newDVID) => {
    return {
        type: ActionTypes.SET_SELECTED_DVID,
        newDVID: newDVID,
    };
}

export const clear_selected_dvid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_DVID,
    };
}

// Exported to vehicles actions script
export const set_device_assigned_status_to_true_by_dvid = (selectedDVID) => {
    return {
        type: ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID,
        selectedDVID: selectedDVID
    };
}

// Exported to vehicles actions script
export const set_device_assigned_status_to_false_by_dvid = (selectedDVID) => {
    return {
        type: ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID,
        selectedDVID: selectedDVID
    };
}
export const set_devices_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_DEVICE_LOADING_STATUS,
        loadingStatus
    }
}

