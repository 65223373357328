import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from 'antd';

export const get_service_vehicle_records = () => (dispatch) => {

    API.getServiceVehicleRecords()
    .then((data) => {
        if (data.status === 200) {
            dispatch(get_service_vehicle_records_success(data.serviceVehicleRecords))
        }
    })
}

const get_service_vehicle_records_success = (serviceVehicleRecords) => {
    return {
        type: ActionTypes.GET_SERVICE_VEHICLE_RECORDS,
        serviceVehicleRecords
    };
}

export const add_service_vehicle_record = (serviceVehicleRecord) => (dispatch) => {

    API
        .addServiceVehicleRecord(serviceVehicleRecord)
        .then(data => {

            if (data.status !== 200) {
                message.error('Failed To Add A Service Vehicle Record: ' + data.message);
            }
            else {
                message.info('Successfully Added A Service Vehicle Record.');
                dispatch(add_service_record_success(data.serviceVehicleRecord));
            }
        })
}

const add_service_record_success = (serviceVehicleRecord) => {
    return {
        type: ActionTypes.ADD_SERVICE_VEHICLE_RECORD,
        serviceVehicleRecord
    }
}