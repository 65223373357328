import React, {useState} from 'react';
import { LoadScript } from '@react-google-maps/api';

import { config } from "../../../config";

const NEWGoogleMapsLoadScript = (COMP, props) => {
    const [ libraries ] = useState(['drawing']);

    return (
        window.google 
        && window.google.maps
        && document.querySelector('body.first-hit-completed') ? // AJAX page loading system is adding this class the first time the app is loaded
            <COMP {...props}/> :
            <LoadScript
                id = "script-loader"
                googleMapsApiKey = {config.googleMapAPI}
                libraries = {libraries}
            >
                <COMP {...props}/>
            </LoadScript>
    )
}

export default NEWGoogleMapsLoadScript;