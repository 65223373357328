import * as API from "../../api/geofences";
import * as ActionTypes from "../action-types/geofences";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
// import {
//     setLoadingSubmit, 
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'
import { get_geofence_templates_success } from './geofenceTemplates';


export const get_geofences = (gtids) => (dispatch) => {
    dispatch(set_geofence_loading_status(LOADING_STATUS.LOADING))
    API.getGeofences(gtids)
        .then(data => {
            
            const {
                geofences,
                geofenceTemplates,
            } = data

            if (data.status !== 200) {
                message.error('Failed To Retrieve User Geofences From Database: ' + data.message);
                dispatch(set_geofence_loading_status(LOADING_STATUS.FAILED))

            }
            else {

                dispatch(get_geofence_templates_success(geofenceTemplates))
                dispatch(get_geofences_success(geofences));
                dispatch(set_geofence_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

export const get_geofences_success = (geofences) => {
    return {
        type: ActionTypes.GET_GEOFENCES,
        geofences: geofences,
    };
}

export const add_geofence = ((uid, newGeofence) => (dispatch) => {
    dispatch(set_geofence_loading_status(LOADING_STATUS.LOADING))

    API.addGeofence(uid, newGeofence)
    .then(data => {
        // console.log("Add Geofence Data: ", data);

        if (data.status !== 200) {
            message.error(data.message);
            dispatch(set_geofence_loading_status(LOADING_STATUS.FAILED))
        }
        else {
            message.success("Successfully Added Geofence");

            dispatch(add_geofence_success(data.geofence));

            dispatch(goBackToPrev());
            dispatch(set_geofence_loading_status(LOADING_STATUS.SUCCESS))
        }
    })
})

const add_geofence_success = (newGeofence) => {
    return {
        type: ActionTypes.ADD_GEOFENCE,
        newGeofence: newGeofence,
    }
}

export const edit_geofence = ((editedGeofence) => (dispatch) => {
    dispatch(set_geofence_loading_status(LOADING_STATUS.LOADING))

    API.editGeofence(editedGeofence)
    .then(data => {
        // console.log("Edit Geofence Data: ", data);

        if (data.status !== 200) {
            message.error(data.message);
            dispatch(set_geofence_loading_status(LOADING_STATUS.FAILED))
        }
        else {
            message.success("Successfully Edited Geofence");

            dispatch(edit_geofence_success(data.geofence));

            dispatch(goBackToPrev());
            dispatch(set_geofence_loading_status(LOADING_STATUS.SUCCESS))
        }
    })
})

const edit_geofence_success = (editedGeofence) => {
    return {
        type: ActionTypes.EDIT_GEOFENCE,
        editedGeofence: editedGeofence,
    }
}

export const set_selected_geoid = (selectedGeoID) => {
    return {
        type: ActionTypes.SET_SELECTED_GEOID,
        selectedGeoID: selectedGeoID,
    }
}

export const clear_selected_geoid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_GEOID,
    }
}
export const set_geofence_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_GEOFENCE_LOADING_STATUS,
        loadingStatus
    }
}

