import * as ActionTypes from "../action-types/deviceGroups";

const defaultState = {
    byDGID: {},
    loadingStatus: undefined,
}

export const deviceGroups = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICE_GROUPS: {
            newState.byDGID = defaultState.byDGID

            action.deviceGroups.forEach((currDG) => newState.byDGID[currDG.dgid] = currDG);

            return newState;
        }

        case ActionTypes.SET_DEVICE_GROUPS_LOADING_STATUS:
            newState.loadingStatus = action.loadingStatus
            return newState

        default: {
            return newState;
        }
    }
}