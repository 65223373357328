import * as ActionTypes from "../action-types/transitLogs";

const defaultState = {
    byTrID: {},
    mapPointsList: [],
    loadingStatus: undefined
}

export const transitLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_TRANSIT_LOG: {
            newState.byTrID = JSON.parse(JSON.stringify(defaultState.byTrID)); // Clear newState byTrID
            
            action.newTransitLogs.forEach((currEvent) => newState.byTrID[currEvent.trid] = currEvent);

            return newState;
        }

        case ActionTypes.SET_MAP_POINT_LIST: {            
            newState.mapPointsList = action.newList;

            return newState;
        }

        case ActionTypes.SET_TRANSIT_LOG_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}