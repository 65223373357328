import React from "react";

import Logo from "./Logo";
import SidebarItems from "./SidebarItems";

import { Layout } from "antd";
import { config } from '../../../config';

import "./index.css";

const { Sider } = Layout;

const Sidebar = props => {
  return (
    <Sider
      collapsed = {props.collapsed}
      style = {{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: props.themeColor
      }}
    >
      <Logo onClick = {props.onClick} selectedKeys = {props.selectedKeys}/>

      <SidebarItems
        items = {props.items}
        theme = {props.theme}
        collapsed = {props.collapsed}
        selectedKeys = {props.selectedKeys.toString()}
        onClick = {props.onClick}
      />
      
      <div 
        style = {{
          color:'white',
          position: 'absolute',
          marginLeft: '15px',
          bottom: '10px'
        }}
      >
        V {config.version}
      </div>
    </Sider>
  );
};

Sidebar.defaultProps = {
  collapsed: "true",
  items: [],
  onClick: () => {}
};

export default Sidebar;
