import * as ActionTypes from "../action-types/events";

const defaultState = {
    byEvID: {},
    loadingStatus: undefined
}

export const events = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_EVENT_LOG: {
            newState.byEvID = JSON.parse(JSON.stringify(defaultState.byEvID)); // Clear newState byEvID
            
            action.newEventLogs.forEach((currEvent) => newState.byEvID[currEvent.evid] = currEvent);

            return newState;
        }

        case ActionTypes.SET_EVENT_LOG_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}