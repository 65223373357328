import React from "react";
import { connect } from "react-redux";

import LoginForm from './LoginForm';

import { message } from "antd";
import { moveToDash } from "../../navigation/navigationService";
import { signInAccount } from "../../services/auth/authService";
// import { user_sign_in_request } from "../../services/redux/actions/user";
import {
    COMPANY,
    IMAGE_RESOURCE, 
} from "../../constants";

import "../../App.css";

class LoginPage extends React.Component {
    loginPressed = (email, password) => {
        signInAccount(email, password, loginResult =>
            this.loginPerformed(loginResult)
        );
    };

    loginPerformed = result => {
        if (result.type === "success") {
            // this.props.dispatch(user_sign_in_request(result.result.user.uid));
            this.props.dispatch(moveToDash());
        } 
        else {
            let errorMessage;

            switch (result.error.code) {
                case "auth/invalid-email":
                    errorMessage = "Please enter a valid email address";
                    break;
                case "auth/user-disabled":
                    errorMessage = "User has been disabled, please contact admin";
                    break;
                case "auth/user-not-found":
                    errorMessage = "User does not exist";
                    break;
                case "auth/wrong-password":
                    errorMessage = "Entered a wrong password, please try again";
                    break;
                default:
                    errorMessage = result.error.code;
                    break;
            }

            message.error(errorMessage);
        }
    };

    render() {
        // console.log("Hi Login Page");

        return (
            <div
                style = {{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",

                    width: "100%",
                    height: "100vh",
                }}
            >
                <div
                    style = {{
                        position: "absolute",

                        backgroundSize: "cover",
                        backgroundImage: `url(${IMAGE_RESOURCE.LOGINBACKGROUND})`,

                        width: "100%",
                        height: "100vh",
                    }}
                />

                <div
                    style = {{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        
                        width: "100%",
                        
                        zIndex: 1,
                    }}
                >
                    <div
                        style = {{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style = {{
                                backgroundColor: "white",
                                padding: "35px 30px 15px 30px",
                                borderRadius: "17px"
                            }}
                        >
                            <div
                                style = {{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src = {IMAGE_RESOURCE.LOGO}
                                    alt = {COMPANY.NAME}
                                    style = {{ height: "80px" }}
                                />
                            </div>

                            <div
                                style = {{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center"
                                }}
                            >
                                <div
                                    style = {{
                                        width: "250px",
                                        paddingTop: "30px"
                                    }}
                                >
                                    <LoginForm
                                        {...this.props}

                                        onLoginPress = {(email, password) =>this.loginPressed(email, password)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(LoginPage);