import * as ActionTypes from "../action-types/geofenceTemplates";

const defaultState = {
    byGTID: {},
    byName: {},
    loadingStatus: undefined,
}

export const geofenceTemplates = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_GEOFENCE_TEMPLATES: {
            newState.byGTID = defaultState.byGTID; // Clear newState byGTID
            
            action.geofenceTemplates.forEach((currGT) => {
                newState.byGTID[currGT.gtid] = currGT
                newState.byName[currGT.templateName] = currGT.gtid
            });

            return newState;
        }

        case ActionTypes.SET_GEOFENCE_TEMPLATES_LOADING_STATUS: 
            newState.loadingStatus = action.loadingStatus
            return newState
        
        default: {
            return newState;
        }
    }
}