import * as API from "../../api/vehicleASLogs";
import * as ActionTypes from "../action-types/vehicleASLogs";

import { message } from 'antd';
// import {
//     setLoadingSubmit,
//     unsetLoadingSubmit,
// } from "./style";
import {
    LOADING_STATUS
} from '../../../constants'

export const get_vehicle_as_log = (vids = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(set_vehicle_as_log_loading_status(LOADING_STATUS.LOADING));

    API.getVehicleASLogs(vids, startTime, endTime)
        .then(data => {
            // console.log("getVehicleASLogs data:", data);

            if (data.status !== 200) {
                message.error('Failed To Get ServiceReminder Log: ' + data.message);
                dispatch(set_vehicle_as_log_loading_status(LOADING_STATUS.FAILED))
            }
            else {
                // if (data.vehicleASLogs.length < 1) {
                //     message.warning("No Logs Found Within This Timeframe.");
                // }
                // else {
                //     message.info('Successfully Retrieved ServiceReminder Log.');
                // }

                dispatch(get_vehicle_as_log_success(data.vehicleASLogs));
                dispatch(set_vehicle_as_log_loading_status(LOADING_STATUS.SUCCESS))
            }
        })
}

const get_vehicle_as_log_success = (vehicleASLogs) => {
    return {
        type: ActionTypes.GET_VEHICLE_AS_LOG,
        vehicleASLogs: vehicleASLogs
    }
}

export const clear_vehicle_as_log = () => {
    return {
        type: ActionTypes.CLEAR_VEHICLE_AS_LOG
    }
}

export const set_vehicle_as_log_loading_status = (loadingStatus) => {
    return {
        type: ActionTypes.SET_VEHICLE_AS_LOG_LOADING_STATUS,
        loadingStatus
    }
}
