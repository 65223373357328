import React, { Component } from "react";
import { connect } from "react-redux";

import PrimaryButton from "./PrimaryButton";

class PrimaryButtonContainer extends Component {
  render() {
    return <PrimaryButton color = {this.props.color} {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  color: state.style.buttonColor
});

export default connect(mapStateToProps)(PrimaryButtonContainer);
