import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import PrimaryButton from "../../../components/PrimaryButton";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    InputNumber,
} from "antd";

// Redux Actions
import { COUNTRY_CODES } from '../../../constants';
import { 
    add_customer, 
    edit_customer,
} from "../../../services/redux/actions/customers";

const FormItem = Form.Item;
const { Option } = Select;

const GENDER = {
    M: 'Male',
    F: 'Female'
}

class CustomerForm extends Component {
    state = {
        
    }

    isPassEmailRegex = email => {
        // const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
        const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email)
    }

    isPassPhoneRegex = phone => {
        const regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
        return regex.test(phone)
    }

    submitForm = () => {
        let {
            cid,
            name,
            gender,
            age,
            contactNumber,
            email,

            isEdit
        } = this.state;

        try {
            if (!name) throw new Error(`Customer Name cannot be blank`)
            if (!gender) throw new Error(`Customer Gender cannot Be Blank`)
            if (!age) throw new Error(`Age Cannot Be Blank`)
            if (!email) throw new Error(`Email Cannot Be Blank`)
            if (!this.isPassEmailRegex(email)) throw new Error(`Invalid email`)
            if (!this.isPassPhoneRegex(COUNTRY_CODES.MALAYSIA + contactNumber)) throw new Error(`Invalid contact number`)

            if (isEdit) {
                const newCustomer = {
                    ...this.props.customers.byCID[cid],
                    name,
                    gender,
                    age,
                    contactNumber: COUNTRY_CODES.MALAYSIA + contactNumber,
                    email,
                };

                // console.log({
                //     newCustomer,
                // })

                this.props.dispatch(edit_customer(
                    newCustomer,
                    undefined,
                    undefined,
                ))

            }
            else {
                const newCustomer = {
                    name,
                    gender,
                    age,
                    contactNumber: COUNTRY_CODES.MALAYSIA + contactNumber,
                    email,
                };

                // console.log({
                //     newCustomer,
                // })

                this.props.dispatch(add_customer(
                    newCustomer,
                    undefined,
                    undefined,
                ));

            }

        }
        catch (err) {
            message.error(err.message || `Error`)
        }
    }

    componentDidMount = () => {
        this.populateForm()
    }

    componentDidUpdate = prevProps => {
        if (
            (JSON.stringify(this.props.customers) !== JSON.stringify(prevProps.customers))
            || (JSON.stringify(this.props.vehicles) !== JSON.stringify(prevProps.vehicles))
        ) {
            this.populateForm()
        }
    }

    populateForm = () => {
        const mode = this.props.location.pathname.split('/').pop()

        // console.log(this.props.location);
        // console.log("Mode:", mode);

        switch (mode) {
            case 'EditCustomer':
                this.setState({ isEdit: true })

                const cid = `cid#${this.props.location.search.replace('?cid=', '')}`;
                const customer = this.props.customers.byCID[cid];

                console.log({ customer });

                customer && this.setState({
                    cid: customer.cid,
                    age: customer.age,
                    contactNumber: customer.contactNumber.substring(3), // Remove first 3 characters to remove country code
                    email: customer.email,
                    gender: customer.gender,
                    name: customer.name,
                })

                break
            case 'AddCustomer': 
            default:
                // this.setState({
                //     contactNumber: COUNTRY_CODES.MALAYSIA
                // })
                break
        }
    }

    getCustomerVehicle = (cid) => {
        if (!cid) return ``;
        const customerVehicle = Object.values(this.props.vehicles.byVID).find(vehicle => vehicle.cid === cid);
        if (!customerVehicle) return `No vehicle assigned`;
        return customerVehicle.chassisNumber;
    }

    render() {
        const {
            cid,
            age,
            contactNumber,
            email,
            gender,
            name,
            isEdit,
        } = this.state;

        return (
            <div className = "page-container">

                <Page title = {isEdit ? 'Edit Customer' : 'Add New Customer'}>
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Customer Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required = {true}
                            >
                                <Input
                                    placeholder = "Enter customer name"
                                    value = {name}
                                    onChange = {e =>
                                        this.setState({ name: e.target.value })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Gender"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required = {true}
                            >
                                <Select
                                    placeholder = "Select customer gender"
                                    value = {gender}
                                    showSearch
                                    optionFilterProp = "children"
                                    filterOption = {(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange = {gender =>
                                        this.setState({ gender })
                                    }
                                >
                                    {
                                        Object.keys(GENDER)
                                            .map(key => {
                                                const gender = GENDER[key]
                                                return (
                                                    <Option
                                                        key = {key}
                                                        value = {gender}
                                                    >
                                                        {gender}
                                                    </Option>
                                                );
                                            })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label = "Age"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required = {true}
                            >
                                <InputNumber
                                    style = {{ width: '100%' }}
                                    placeholder = "Enter customer age"
                                    value = {age}
                                    onChange = {age =>
                                        this.setState({ age })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Contact Number"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required = {true}
                            >

                                <Input.Group compact>
                                    <Input disabled defaultValue = {COUNTRY_CODES.MALAYSIA} style = {{ width: '10%', color: "rgba(0, 0, 0, 0.65)" }}/>

                                    <Input
                                        placeholder = "Enter customer contact number"
                                        value = {contactNumber}
                                        onChange = {e => {
                                            this.setState({ contactNumber: e.target.value })
                                        }}
                                        style = {{ width: '90%' }}
                                    />
                                </Input.Group>
                            </FormItem>

                            <FormItem
                                label = "Email"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required = {true}
                            >
                                <Input
                                    placeholder = "Enter customer email"
                                    value = {email}
                                    onChange = {e =>
                                        this.setState({ email: e.target.value })
                                    }
                                />
                            </FormItem>

                            {
                                isEdit && (
                                    <FormItem
                                        label = "Vehicle"
                                        labelCol = {{ span: 7 }}
                                        wrapperCol = {{ span: 15 }}
                                    >
                                        {this.getCustomerVehicle(cid)}
                                    </FormItem>
                                )
                            }

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    // loading = {this.props.style.isLoadingSubmit}
                                    loading = {this.props.customers.loadingStatus === "LOADING"}
                                    onClick = {this.submitForm}
                                >
                                    {isEdit ? 'Save' : 'Add'}
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    // style: state.style,
    vehicles: state.vehicles,
    customers: state.customers,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(CustomerForm));